import React from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import CopyToClipboard from '@amzn/awsui-components-react/polaris/copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { AttendeeTable } from './attendeeTable';
import { useAttendeeSearchParams } from '../../hooks/attendance/attendeeSearchParams';
import { useUrlPage } from '../../hooks/attendance/urlPage';
import { publishKatalMetric } from '../katalAnalytics';

export function AttendanceReview() {
  const namespaces = ['attendance', 'translation'];
  const { t } = useTranslation(namespaces);

  const { aliases } = useAttendeeSearchParams();
  const { setPage } = useUrlPage(0); // This value is arbitrary, as we are not actually using the page number here, just setting it

  return (
    <SpaceBetween direction="vertical" size="l">
      {/* TODO - Add this back in once we do stored attendance (https://issues.amazon.com/issues/VOLUNTEERCENTER-277) */}
      {/*<SpaceBetween direction="vertical" size="xxs">
        <Header actions={<Button onClick={() => setPage(0)}>{t("attendanceReview.edit")}</Button>}>
          Event Details
        </Header>
        <Container>
          <KeyValuePairs
            columns={3}
            items={inputNames.map(name => ({
                value: getValueFromInput(name),
                label: t("eventDetails." + name)
              }))}/>
        </Container>
      </SpaceBetween> */}
      <SpaceBetween direction="vertical" size="xxs">
        <Header
          actions={
            <Button
              onClick={() => {
                publishKatalMetric('attendance_wizardEditPage', `0`);
                setPage(0);
              }}
            >
              {t('attendanceReview.edit')}
            </Button>
          }
        >
          {t('attendanceReview.attendance')}
        </Header>
        <AttendeeTable isImmutable />
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Header>{t('attendanceReview.export')}</Header>
        <SpaceBetween direction="horizontal" size="xxs">
          <CopyToClipboard
            copyButtonText={t('attendanceReview.copyCSV')}
            copyErrorText={t('attendanceReview.copyCSVFailed')}
            copySuccessText={t('attendanceReview.copyCSVSuccess')}
            data-aci-analytics-name="attendance_copyFullAliasList"
            textToCopy={aliases.join(', ')}
          />
          <CopyToClipboard
            copyButtonText={t('attendanceReview.copyURL')}
            copyErrorText={t('attendanceReview.copyURLFailed')}
            copySuccessText={t('attendanceReview.copyURLSuccess')}
            data-aci-analytics-name="attendance_copyAttendanceAddress"
            textToCopy={window.location.href}
          />
        </SpaceBetween>
      </SpaceBetween>
    </SpaceBetween>
  );
}

import React from 'react';
import { getValueFromSearchParam } from '../utils/url';
import { BulkUpdate } from '../api/schemas/bulkUpdate';
import { getBulkUpdate } from '../api/bulk';
import { SpaceBetween } from '@amzn/awsui-components-react';
import Container from '@amzn/awsui-components-react/polaris/container';
import { externalLinks } from '../data/externalLinks';
import Link from '@amzn/awsui-components-react/polaris/link';
import { BulkUpdateForm } from '../components/bulkUpdateForm';
import { BulkUpdateDisplay } from '../components/bulkUpdateDisplay';
import { useTranslation } from 'react-i18next';
import { useUser } from '../hooks/user';
import { useSearchParams } from 'react-router-dom';

export function BulkUpdator() {
  const [searchParams] = useSearchParams();
  const [update, setUpdate] = React.useState<BulkUpdate | null>(null);
  const [manyCalls, setManyCalls] = React.useState(false);
  const { t } = useTranslation();
  const filename = getValueFromSearchParam(searchParams, 'filename');
  const { userId, permissions } = useUser();
  const hasPermission = permissions.includes('bulkUpdate');

  React.useEffect(() => {
    if (filename === '' || !hasPermission) return;

    let calls = 0;

    // Run the query every 5 seconds in case the user navigated to this page before the upload was complete
    const intervalCall = setInterval(async () => {
      const update = await getBulkUpdate(filename);

      // Keep track of the number of calls so we can alert the user if it is taking longer than expected to load the data
      calls++;
      if (calls > 50) setManyCalls(true);

      // Once we have the data, stop running the query
      if (update && update.completed) {
        clearInterval(intervalCall);
        setUpdate(update);
      }
    }, 5000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [filename, hasPermission]);

  if (!userId || !hasPermission)
    return (
      <Container fitHeight variant="stacked">
        <p>
          {t('bulkUpdate.noPermissionBeforeLink')}
          <Link
            href={externalLinks.bulkUpload.accessRequest}
            external
            variant="primary"
            data-aci-analytics-name="bulkUpdatePermissionRequest"
          >
            {t('bulkUpdate.noPermissionLink')}
          </Link>
          {t('bulkUpdate.noPermissionAfterLink')}
        </p>
      </Container>
    );

  const fileHasBeenSubmitted = filename !== '';

  return (
    <Container fitHeight variant="stacked">
      <h1>{t('bulkUpdate.title')}</h1>
      <SpaceBetween direction="vertical" size="m">
        {fileHasBeenSubmitted ? (
          <BulkUpdateDisplay update={update} filename={filename} manyCalls={manyCalls} />
        ) : (
          <BulkUpdateForm hasPermission={hasPermission} />
        )}
      </SpaceBetween>
    </Container>
  );
}

export function BulkUpdateView() {
  return <BulkUpdator />;
}

import Form from '@amzn/awsui-components-react/polaris/form';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import SegmentedControl from '@amzn/awsui-components-react/polaris/segmented-control';
import {
  getOptionFromMultiValues,
  getOptionFromValue,
  getDateRangeFromStartAndEnd,
} from '../../utils/url';
import { Header, Input, Multiselect, Button } from '@amzn/awsui-components-react';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { AbsoluteDatePicker } from '../absoluteDatePicker';
import { locationOptions } from '../../data/locations';
import { useTranslation } from 'react-i18next';
import { useSharedFilters, optionsToValueArrayString } from '../../hooks/betterEvents/filters';
import Autosuggest from '@amzn/awsui-components-react/polaris/autosuggest';

export function AdvancedSearch() {
  const {
    filterValues,
    updateFilter,
    updateFilters,
    activeFilterCount,
    resetFilters,
    applyFilters,
  } = useSharedFilters();

  const { t } = useTranslation();

  const lengthMin = t('lengthMin', { returnObjects: true }) as SelectProps.Options;
  const lengthMax = t('lengthMax', { returnObjects: true }) as SelectProps.Options;
  const daysOfWeekOptions = t('daysOfWeekOptions', { returnObjects: true }) as SelectProps.Options;
  const startTimeOptions = t('startTimeOptions', { returnObjects: true }) as SelectProps.Options;
  const endTimeOptions = t('endTimeOptions', { returnObjects: true }) as SelectProps.Options;
  const skillOptions = t('skillOptions', { returnObjects: true }) as SelectProps.Options;
  const interestOptions = t('interestOptions', { returnObjects: true }) as SelectProps.Options;

  return (
    <div className="advancedSearch">
      <SpaceBetween direction="vertical" size="s">
        <Header variant="h2">{t('advancedSearch.filterEvents_zero')}</Header>
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            variant="primary"
            onClick={applyFilters}
            data-aci-analytics-name="filter_applyFilters"
          >
            {t('betterAdvancedSearch.apply')}
          </Button>
          <Button
            disabled={activeFilterCount <= 0}
            variant="normal"
            onClick={resetFilters}
            data-aci-analytics-name="filter_resetFilters"
          >
            {t('betterAdvancedSearch.reset')}
          </Button>
        </SpaceBetween>
        <span>{t('betterAdvancedSearch.filtersSelected', { count: activeFilterCount })}</span>

        {/* Enables applying filters when pressing enter or using a screenreader */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            applyFilters();
          }}
        >
          <Form>
            <SpaceBetween direction="vertical" size="s">
              {/* Date Range */}
              <FormField label={t('betterAdvancedSearch.dateRange')}>
                <AbsoluteDatePicker
                  onChange={(value) => {
                    const valueSet = value != null && value.type === 'absolute';
                    const startDate = valueSet ? value.startDate : '';
                    const endDate = valueSet ? value.endDate : '';
                    updateFilters({ start_timestamp: startDate, end_timestamp: endDate });
                  }}
                  value={getDateRangeFromStartAndEnd(
                    filterValues.start_timestamp,
                    filterValues.end_timestamp
                  )}
                />
              </FormField>

              {/* Days of the Week */}
              <FormField label={t('advancedSearch.daysOfWeek')}>
                <Multiselect
                  data-testid="advancedSearch.days_of_week"
                  onChange={({ detail }) =>
                    updateFilter('days_of_week', optionsToValueArrayString(detail.selectedOptions))
                  }
                  selectedOptions={getOptionFromMultiValues(
                    filterValues.days_of_week,
                    daysOfWeekOptions
                  )}
                  options={daysOfWeekOptions}
                  keepOpen={true}
                  placeholder="Choose options"
                />
              </FormField>

              {/* Activity Type */}
              <FormField label={t('advancedSearch.type')}>
                <div className="activityTypeOptions">
                  <SegmentedControl
                    options={[
                      { text: t('advancedSearch.allType'), id: 'all' },
                      { text: t('advancedSearch.physicalType'), id: 'in-person' },
                      { text: t('advancedSearch.virtualType'), id: 'virtual' },
                    ]}
                    onChange={({ detail }) => updateFilter('activity_location', detail.selectedId)}
                    selectedId={filterValues.activity_location}
                    label={t('advancedSearch.type')}
                    font-size={10}
                  />
                </div>
              </FormField>

              {/* Location */}
              <FormField label={t('advancedSearch.location')}>
                <Autosuggest
                  onChange={({ detail }) => updateFilter('volunteer_location', detail.value || '')}
                  value={filterValues.volunteer_location}
                  options={locationOptions}
                  virtualScroll
                />
              </FormField>

              {/* Number of Spots */}
              <FormField label={t('betterAdvancedSearch.openSpots')}>
                <Input
                  onChange={({ detail }) => updateFilter('spots', detail.value)}
                  type="number"
                  value={filterValues.spots}
                  placeholder="Enter number of spots"
                />
              </FormField>

              {/* Activity Length */}
              <FormField label={t('betterAdvancedSearch.activityLength')}>
                <div className="title-subtext">
                  <FormField label={t('betterAdvancedSearch.activityLengthMin')}>
                    <Select
                      onChange={({ detail }) => {
                        if (
                          detail.selectedOption.value &&
                          filterValues.activity_length_max < detail.selectedOption.value
                        ) {
                          updateFilter('activity_length_max', detail.selectedOption.value || '');
                        }
                        updateFilter('activity_length_min', detail.selectedOption.value || '');
                      }}
                      selectedOption={getOptionFromValue(
                        filterValues.activity_length_min,
                        lengthMin
                      )}
                      options={lengthMin}
                      placeholder="Choose options"
                    />
                  </FormField>
                </div>
                <div className="title-subtext">
                  <FormField label={t('betterAdvancedSearch.activityLengthMax')}>
                    <Select
                      onChange={({ detail }) => {
                        if (
                          detail.selectedOption.value &&
                          filterValues.activity_length_min > detail.selectedOption.value
                        ) {
                          updateFilter('activity_length_min', detail.selectedOption.value || '');
                        }
                        updateFilter('activity_length_max', detail.selectedOption.value || '');
                      }}
                      selectedOption={getOptionFromValue(
                        filterValues.activity_length_max,
                        lengthMax
                      )}
                      options={lengthMax}
                      placeholder="Choose options"
                    />
                  </FormField>
                </div>
              </FormField>

              {/* Start Time After */}
              <FormField label={t('betterAdvancedSearch.startsAfter')}>
                <Select
                  onChange={({ detail }) =>
                    updateFilter('start_time', detail.selectedOption.value || '')
                  }
                  selectedOption={getOptionFromValue(filterValues.start_time, startTimeOptions)}
                  options={startTimeOptions}
                  placeholder="Choose options"
                />
              </FormField>

              {/* End Time Before */}
              <FormField label={t('betterAdvancedSearch.endsBefore')}>
                <Select
                  onChange={({ detail }) =>
                    updateFilter('end_time', detail.selectedOption.value || '')
                  }
                  selectedOption={getOptionFromValue(filterValues.end_time, endTimeOptions)}
                  options={endTimeOptions}
                  placeholder="Choose options"
                />
              </FormField>

              {/* Frequency */}
              <FormField label={t('advancedSearch.frequency')}>
                <div className="frequencyOptions">
                  <SegmentedControl
                    options={[
                      { text: t('advancedSearch.frequencyAll'), id: 'all' },
                      { text: t('advancedSearch.frequencyOnce'), id: 'time_bounded' },
                      { text: t('advancedSearch.frequencyRepeating'), id: 'ongoing' },
                    ]}
                    onChange={({ detail }) => updateFilter('frequency', detail.selectedId)}
                    selectedId={filterValues.frequency}
                    label={t('advancedSearch.frequency')}
                  />
                </div>
              </FormField>

              {/* Interests */}
              <FormField label={t('betterAdvancedSearch.interests')}>
                <Select
                  onChange={({ detail }) =>
                    updateFilter('interests', detail.selectedOption.value || '')
                  }
                  selectedOption={getOptionFromValue(filterValues.interests, interestOptions)}
                  options={interestOptions}
                  placeholder="Choose options"
                />
              </FormField>

              {/* Skills */}
              <FormField label={t('betterAdvancedSearch.skills')}>
                <Select
                  onChange={({ detail }) =>
                    updateFilter('skills', detail.selectedOption.value || '')
                  }
                  selectedOption={getOptionFromValue(filterValues.skills, skillOptions)}
                  options={skillOptions}
                  placeholder="Choose options"
                />
              </FormField>

              {/* Search */}
              <FormField label={t('advancedSearch.search')}>
                <Input
                  data-testid="advancedSearch.search"
                  onChange={({ detail }) => updateFilter('query', detail.value)}
                  value={filterValues.query}
                />
              </FormField>

              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="primary"
                  onClick={applyFilters}
                  data-aci-analytics-name="filter_applyFilters"
                >
                  {t('betterAdvancedSearch.apply')}
                </Button>
                <Button
                  disabled={activeFilterCount <= 0}
                  onClick={resetFilters}
                  data-aci-analytics-name="filter_resetFilters"
                >
                  {t('betterAdvancedSearch.reset')}
                </Button>
              </SpaceBetween>
            </SpaceBetween>
          </Form>
        </form>
      </SpaceBetween>
    </div>
  );
}

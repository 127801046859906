// GOLDEN PATH CODE
// This code demonstrates how to save and load app state when
// navigating away from the application (for example in a log in flow)]
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';

export const AUTH_RELOAD_TIMESTAMP_KEY = 'authenticationReloaded';
export const ALIAS_KEY = 'amzn-rv-userId';
export const EMP_KEY = 'amzn-rv-empId';
export const PERMISSION_KEY = 'amzn-rv-permissions';
export const POST_LOGIN_PATH_KEY = 'POST_LOGIN_PATH';
export const LOGIN_QUERY_KEY = ['Amplify', 'currentSession'];
const USER_REFETCH_MILLS = 30 * 60 * 1000;

export interface UserData {
  userId: string;
  employeeId: string;
  permissions: string[];
}

export interface LoginData extends UserData {
  isPending: boolean;
  isError: boolean;
  error: Error | string | null;
}

function useAppState() {
  const location = useLocation();
  const goToPath = useNavigate();

  return {
    // GOLDEN PATH NOTE
    // We place the save and load functions next to each other
    // so it is clear exactly what is being saved, and how that saved information
    // is used to restore the state.  The places that call these functions may be
    // much farther apart in code, so extracting them out next to each other
    // makes it easier for maintainers to compare them
    saveAppState: () => {
      localStorage.setItem(POST_LOGIN_PATH_KEY, location.pathname + location.search);
    },
    restoreAppState: () => {
      const postLoginPath = localStorage.getItem(POST_LOGIN_PATH_KEY);
      if (postLoginPath) {
        goToPath(postLoginPath);
        localStorage.removeItem(POST_LOGIN_PATH_KEY);
      }
    },
  };
}

function useCachedAuth() {
  return {
    setAuthCache: (userData: UserData) => {
      localStorage.setItem(ALIAS_KEY, userData.userId);
      localStorage.setItem(EMP_KEY, userData.employeeId);
    },
    getAuthFromCache: () => {
      return {
        userId: localStorage.getItem(ALIAS_KEY) || '',
        employeeId: localStorage.getItem(EMP_KEY),
        // GOLDEN PATH NOTE
        // We do NOT store the permissions to make it harder for
        // bad actors to phish for identities with the permissions
        // that they want.  This is not a worry, because the site is
        // gated on actually loading the permissions, and the data
        // access requires a fresh user token passed via the API
        permissions: [],
      };
    },
  };
}

export function useLoggedInUser(): LoginData {
  const { saveAppState, restoreAppState } = useAppState();
  const { setAuthCache, getAuthFromCache } = useCachedAuth();

  const userQuery = useQuery({
    queryKey: LOGIN_QUERY_KEY,
    queryFn: async () => {
      try {
        const session = await Auth.currentSession();

        // GOLDEN PATH NOTE
        // Once we have successfully obtained a session, we can restore any
        // state that was saved
        restoreAppState();

        const idToken = session.getIdToken();
        const userId = idToken.payload['identities'][0].userId;
        const permissions = idToken.payload['permissions'];

        const userData = {
          userId,
          employeeId: idToken.payload['custom:employee_id'],
          permissions: permissions ? permissions.split(',') : [],
        };
        setAuthCache(userData);
        return userData;
      } catch {
        // GOLDEN PATH NOTE
        // Auth.federatedSignIn navigates away from the RedVelvet app entirely
        // As such anything that needs to be persisted when the login cycle completes
        // needs to be saved to local storage
        saveAppState();
        await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
      }
    },
    refetchInterval: USER_REFETCH_MILLS,
  });

  return {
    // GOLDEN PATH NOTE
    // By spreading the data or the cached data, we ensure
    // that we can reuse this code in all the components, with
    // a guarantee that we will have an alias string to use in
    // queries
    ...(userQuery.data || getAuthFromCache()),
    ...userQuery,
  };
}

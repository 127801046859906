import React from 'react';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import { EventList } from '../components/eventList';
import { EventList as BetterEventList } from '../components/better/eventList';
import { SideNav, BetterSideNav } from '../components/sideNav';
import { MoreWaysFooter } from '../components/moreWaysFooter';
import { HighlightedSearch } from '../components/highlightedSearch';
import { useFeatures, featureIsEnabled } from '../hooks/features';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export function Home() {
  const features = useFeatures();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  if (featureIsEnabled('BenevityButBetter', features, searchParams)) {
    return (
      <>
        <div className="page-content">
          <div className="page-content-header">
            <Header variant="h1">{t('search.header')}</Header>
          </div>
          <Grid
            gridDefinition={[
              { colspan: { default: 12, xl: 3, l: 4, m: 4, s: 5 } },
              { colspan: { default: 12, xl: 9, l: 8, m: 8, s: 7 } },
            ]}
          >
            <BetterSideNav />
            <BetterEventList />
          </Grid>
        </div>
        <MoreWaysFooter />
      </>
    );
  }

  return (
    <div>
      <HighlightedSearch />
      <Grid
        gridDefinition={[
          { colspan: { default: 12, xl: 3, l: 4, m: 4, s: 5 } },
          { colspan: { default: 12, xl: 9, l: 8, m: 8, s: 7 } },
        ]}
      >
        <SideNav />
        <EventList />
      </Grid>
    </div>
  );
}

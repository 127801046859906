// GOLDEN PATH CODE
// This is a table backed by open-ended data (ie, we do not know exactly how many pages of data there are)
// Based on https://cloudscape.aws.dev/examples/react/server-side-table.html

import React, { useState } from 'react';
import Table from '@amzn/awsui-components-react/polaris/table';
import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import { PropertyFilterProps } from '@amzn/awsui-components-react/polaris/property-filter';
import Header from '@amzn/awsui-components-react/polaris/header';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import { useTranslation } from 'react-i18next';
import { useTableStrings } from '../../hooks/localization/tableStrings';
import { usePaginationStrings } from '../../hooks/localization/paginationStrings';
import { PeoplePropertyFilter } from './peoplePropertyFilter';
import { useTableColumns } from '../../hooks/tableColumns';
import { useAttendeeSearchParams } from '../../hooks/attendance/attendeeSearchParams';
import { usePaginatedRedVelvetQuery } from '../../hooks/paginatedRedVelvetQuery';
import {
  Amazonian,
  columnMap,
  getAliasesFromSelected,
  getAmazoniansFromPeople,
  PEOPLE_PAGE_SIZE,
  convertTokensToPeopleQuery,
} from '../../utils/attendance/peopleSearch';
import { publishKatalMetric } from '../katalAnalytics';

export function SearchTable() {
  const namespaces = ['attendance', 'translation'];
  const { t } = useTranslation(namespaces);
  const tableStrings = useTableStrings('searchTable', (item: Amazonian) => item.name, namespaces);
  const paginationStrings = usePaginationStrings('searchTable', namespaces);

  const [selectedItems, setSelectedItems] = useState<Amazonian[]>([]);
  const [filterObj, setFilterObj] = useState<PropertyFilterProps.Query>({
    tokens: [],
    operation: 'or',
  });
  const columns = useTableColumns(columnMap, 'attendeeTable', namespaces);

  // GOLDEN PATH NOTE
  // We need to save the largest page we have ever visited with this set of queries
  // so that if a user goes from page 5 back to page 1, they can easily hop back to page 5
  const [largestPage, setLargestPage] = useState(1);

  // GOLDEN PATH NOTE
  // The server-side fetching is in charge of tracking/caching/retrieving all the different pagination tokens for the open-ended query
  // and managing the pagination logic.  The component is only in charge of mapping the query result data to the proper format
  const searchQuery = convertTokensToPeopleQuery(filterObj);
  const { pageIndex, setPageIndex, queryResult } = usePaginatedRedVelvetQuery({
    redVelvetQueryKey: ['getPeople', searchQuery],
  });
  const people =
    filterObj.tokens.length > 0 ? getAmazoniansFromPeople(queryResult.data?.people) : [];

  // GOLDEN PATH NOTE
  // Because attendees is a cross-component value, and a value we want to save, we manage it via the
  // search params for easy re-creation of state
  const { addAttendees } = useAttendeeSearchParams();

  // GOLDEN PATH NOTE
  // We determine pagination/number of items found/etc based on if we have a next page or not
  // If there is a next page, we need to indicate to the user that there are an unknown number of results
  // If there is not a next page, we know exactly how many items there are
  const isOpenEnded = !queryResult.isLoading && queryResult.data?.page !== undefined;
  const foundItems = isOpenEnded
    ? PEOPLE_PAGE_SIZE * largestPage + '+'
    : PEOPLE_PAGE_SIZE * (pageIndex - 1) + (people.length || 0);

  return (
    <Table
      header={
        <Header
          actions={
            <Button
              variant="primary"
              disabled={selectedItems.length === 0}
              onClick={() => {
                setSelectedItems([]);
                const aliases = getAliasesFromSelected(selectedItems);
                addAttendees(aliases);
                publishKatalMetric('attendance_addAttendees', aliases.join(','));
              }}
            >
              {t('searchTable.addAttendees')}
            </Button>
          }
          counter={
            selectedItems.length ? `(${selectedItems.length}/${foundItems})` : `(${foundItems})`
          }
        >
          {t('searchTable.title')}
        </Header>
      }
      filter={
        <PeoplePropertyFilter
          query={filterObj}
          onChange={({ detail }) => {
            // GOLDEN PATH NOTE
            // When we change the filter, we need to reset all pagination and selected items back to their inital state
            const metricValue = detail.tokens
              .map((token) => `${token.propertyKey || 'fuzzySearch'}:${token.value}`)
              .join(',');
            publishKatalMetric('attendance_searchPeopleFilter', metricValue);
            setSelectedItems([]);
            setPageIndex(1);
            setLargestPage(1);
            setFilterObj(detail);
          }}
        />
      }
      pagination={
        <Pagination
          ariaLabels={paginationStrings}
          currentPageIndex={pageIndex}
          onChange={({ detail }) => {
            // GOLDEN PATH NOTE
            // Using Math.max allows us to auto-set the largest page when we visit it, and leave it alone
            // if we are re-visiting a previous page
            setLargestPage(Math.max(detail.currentPageIndex, largestPage));
            setPageIndex(detail.currentPageIndex);
          }}
          openEnd={isOpenEnded}
          // GOLDEN PATH NOTE
          // We always list the largest page as the page count, even if we are on the last page and know there is
          // a next page, because the users will use the next arrow to load the next page
          pagesCount={largestPage}
        />
      }
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
      selectionType="multi"
      {...tableStrings}
      loading={queryResult.isLoading}
      empty={
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <b>{queryResult.isError ? t('searchTable.errorOccurred') : t('searchTable.noneFound')}</b>
        </Box>
      }
      {...columns}
      items={people || []}
      trackBy="alias"
      data-testid={'people-search-table'}
    />
  );
}

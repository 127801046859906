// GOLDEN PATH CODE
// This is an unsaved changes modal, that displays when a user attempts to navigate away
// from a page that has unsaved changes.  It should be used in the page layout for any wizard
// Based on https://cloudscape.aws.dev/patterns/general/unsaved-changes/#implementation

import * as React from 'react';
import Modal, { ModalProps } from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { useTranslation } from 'react-i18next';
import { useUnsavedChangesBrowserNavigationPrompt } from '../../hooks/unsavedChanges';

// GOLDEN PATH NOTE
// The visible status of the modal should primarily be controlled by the parent, since that is
// where most of the navigation will be occurring
export interface AttendanceModalProps {
  visible: boolean;
  hasChanges: boolean;
  onConfirm: () => void;
  onDismiss: NonNullable<ModalProps['onDismiss']>; // This makes the optional onDismiss function from the Modal props into a required field
}

export function AttendanceModal(props: AttendanceModalProps & ModalProps) {
  const { t } = useTranslation(['attendance', 'translation']);

  // This is included in the unsaved changes modal component so that all the navigation confirmation happens in the same place
  // TODO - verify that this is actually the golden path, or if there is a better pattern - https://taskei.amazon.dev/tasks/cce-3792
  useUnsavedChangesBrowserNavigationPrompt(props.hasChanges);

  return (
    <Modal
      {...props}
      visible={props.visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            {/* GOLDEN PATH NOTE - The cancel button should have the same functionality as the onDismiss close button */}
            <Button
              variant="link"
              onClick={() =>
                props.onDismiss(new CustomEvent<ModalProps.DismissDetail>('closeButton'))
              }
            >
              {t('attendanceModal.cancel')}
            </Button>
            <Button variant="primary" onClick={props.onConfirm}>
              {t('attendanceModal.leave')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t('attendanceModal.title')}
      closeAriaLabel={t('attendanceModal.cancel')}
    >
      {t('attendanceModal.description')}
    </Modal>
  );
}

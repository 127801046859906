import React from 'react';
import { AdvancedSearch } from './advancedSearch';
import { AdvancedSearch as BetterAdvancedSearch } from './better/advancedSearch';

export function SideNav() {
  return <AdvancedSearch />;
}

export function BetterSideNav() {
  return <BetterAdvancedSearch />;
}

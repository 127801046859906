import { Button, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import { externalLinks } from '../data/externalLinks';

export function MoreWaysContainer() {
  const { t } = useTranslation();

  return (
    <Container>
      <Header>{t('eventList.moreWays.header')}</Header>
      <SpaceBetween direction="horizontal" size="s" alignItems="center">
        <Button
          variant="primary"
          href={externalLinks.moreWays.planEvent}
          target="_blank"
          rel="noreferrer"
          data-aci-analytics-name="planEvent"
        >
          {t('eventList.moreWays.planEvent')}
        </Button>
        <Button
          variant="primary"
          href={externalLinks.moreWays.plugAndPlay}
          target="_blank"
          rel="noreferrer"
          data-aci-analytics-name="plugAndPlay"
        >
          {t('eventList.moreWays.plugAndPlay')}
        </Button>
        <Button
          variant="primary"
          href={externalLinks.moreWays.assistedPlanning}
          target="_blank"
          rel="noreferrer"
          data-aci-analytics-name="assistedPlanning"
        >
          {t('eventList.moreWays.assistedPlanning')}
        </Button>
      </SpaceBetween>
    </Container>
  );
}

import { useSearchParams } from 'react-router-dom';
import { PAGE_KEY } from './urlPage';

const ATTENDEES_KEY = 'attendees';

export interface AttendeeSearchParams {
  aliases: string[];
  addAttendees: (attendees: string[]) => void;
  removeAttendees: (attendees: string[]) => void;
  resetAttendees: () => void;
}

export function useAttendeeSearchParams(): AttendeeSearchParams {
  const [searchParams, setSearchParams] = useSearchParams();

  const aliases = searchParams.get(ATTENDEES_KEY)?.split(',') || [];

  const updateAttendees = (attendees: string[]) => {
    setSearchParams((params) => {
      if (attendees.length === 0) {
        params.delete(ATTENDEES_KEY);
      } else {
        params.set(ATTENDEES_KEY, attendees.join(','));
      }

      return params;
    });
  };

  const addAttendees = (attendees: string[]) => {
    updateAttendees(aliases.concat(attendees));
  };

  const removeAttendees = (attendees: string[]) => {
    updateAttendees(aliases.filter((alias) => !attendees.includes(alias)));
  };

  const resetAttendees = () => {
    setSearchParams((params) => {
      params.delete(ATTENDEES_KEY);
      params.delete(PAGE_KEY);
      return params;
    });
  };

  return {
    aliases,
    addAttendees,
    removeAttendees,
    resetAttendees,
  };
}

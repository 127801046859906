import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Shift, Event, Question } from '@amzn/red-velvet-api';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal, { ModalProps } from '@amzn/awsui-components-react/polaris/modal';
import Select, { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Checkbox from '@amzn/awsui-components-react/polaris/checkbox';
import Form from '@amzn/awsui-components-react/polaris/form';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Input from '@amzn/awsui-components-react/polaris/input';
import { ComplexSignupInputs } from '../../hooks/betterEvents';
import { notNull } from '../../utils/tsUtils';
import { useUser } from '../../hooks/user';
import { useCreateSignup } from '../../hooks/shifts/shiftSignup';

interface ComplexSignupDialogProps {
  event: Event;
  shift: Shift;
  visible: boolean;
  onDismiss: NonNullable<ModalProps['onDismiss']>;
}

export function useComplexEventState(event: Event) {
  const [waiverLinkOpened, setWaiverLinkOpened] = useState(false);
  const defaultAnswers = () => {
    return event.questions
      ? event.questions
          .map((question: Question) => {
            if (question.options) {
              return {
                questionId: question.id,
                optionId: undefined,
              };
            }
            return null;
          })
          .filter(notNull)
      : [];
  };
  const [complexSignupData, setComplexSignupData] = useState<ComplexSignupInputs>(() => ({
    guestCount: event.allowGuests ? 0 : undefined,
    tShirtSizeId: event.hasTShirt ? 1 : undefined,
    waiverAccepted: event.waiver ? false : undefined,
    questionAnswers: defaultAnswers(),
  }));

  return {
    complexSignupData,
    waiverLinkOpened,
    updaters: {
      reset: () => {
        setComplexSignupData({
          guestCount: event.allowGuests ? 0 : undefined,
          tShirtSizeId: event.hasTShirt ? 1 : undefined,
          waiverAccepted: event.waiver ? false : undefined,
          questionAnswers: defaultAnswers(),
        });
        setWaiverLinkOpened(false);
      },
      setWaiverLinkOpened,
      setQuestionAnswers: (questionId?: string, newOptionId?: string) => {
        setComplexSignupData((prev) => ({
          ...prev,
          questionAnswers: prev.questionAnswers?.map((qa) =>
            qa.questionId === questionId
              ? {
                  ...qa,
                  optionId: newOptionId,
                }
              : qa
          ),
        }));
      },
      setTshirtSizeId: (newValue: number) => {
        setComplexSignupData((prev) => ({
          ...prev,
          tShirtSizeId: event.hasTShirt ? newValue : undefined,
        }));
      },
      setGuestCount: (newValue: number) => {
        setComplexSignupData((prev) => ({
          ...prev,
          guestCount: event.allowGuests ? newValue : undefined,
        }));
      },
      setWaiverAccepted: (newValue: boolean) => {
        setComplexSignupData((prev) => ({
          ...prev,
          waiverAccepted: event.waiver ? newValue : undefined,
        }));
      },
    },
  };
}

function validateData(
  event: Event,
  shift: Shift,
  waiverLinkOpened: boolean,
  complexSignupData: ComplexSignupInputs
) {
  const isAllowGuestNullOrValid =
    !event.allowGuests ||
    (shift.maxSignup ? (complexSignupData.guestCount ?? 0) + 1 <= shift.signupsRemaining : true);
  const areQuestionsNullOrValid =
    !event.questions ||
    complexSignupData.questionAnswers?.every((set) => set.optionId !== undefined);
  const isWaiverNullOrValid =
    !(event.waiver && event.waiver.url) ||
    event.waiver.acceptance !== 'required' ||
    (complexSignupData.waiverAccepted && waiverLinkOpened);
  return isAllowGuestNullOrValid && areQuestionsNullOrValid && isWaiverNullOrValid;
}

function RequiredLabel({ label, isRequired }: { label: string; isRequired: boolean }) {
  const { t } = useTranslation();
  return (
    <>
      {t(label)}
      {isRequired && <span style={{ color: 'red' }}> *</span>}
    </>
  );
}

export function ComplexSignupDialog(props: ComplexSignupDialogProps) {
  const { t } = useTranslation();
  const { event, shift, visible, onDismiss } = props;
  const signupMutation = useCreateSignup(event.eventId, shift.shiftId);
  const user = useUser();

  const tshirtSizeOptions: SelectProps.Options = t(
    'eventDetails.shiftDisplay.complexSignup.tshirtSizes',
    {
      returnObjects: true,
    }
  );

  const hasLimitedSpots = shift.signupsRemaining && shift.maxSignup;

  const { complexSignupData, waiverLinkOpened, updaters } = useComplexEventState(event);

  return (
    <Modal
      onDismiss={(event) => {
        onDismiss(event);
        updaters.reset();
      }}
      visible={visible}
      header={event.title}
      closeAriaLabel={t('eventDetails.shiftDisplay.complexSignup.closeButton')}
    >
      <h3>
        <b>{shift.name}</b>
      </h3>
      <p>
        <b>{t('eventDetails.shiftDisplay.date')}:</b> {shift.startDateTime?.toDateString()}
      </p>
      <p>
        <b>{t('eventDetails.shiftDisplay.startTime')}:</b> {shift.startDateTime?.toTimeString()}
      </p>
      <p>
        <b>{t('eventDetails.shiftDisplay.spotsAvailable')}:</b>{' '}
        {hasLimitedSpots ? shift.signupsRemaining : t('eventDetails.shiftDisplay.unlimited')}
      </p>

      {/* Enables submitting data when pressing enter or using a screenreader */}
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form>
          <SpaceBetween size={'m'}>
            {event.allowGuests ? (
              <FormField
                key={`allowGuestsKey.${shift.shiftId}.addGuests`}
                label={
                  <RequiredLabel
                    label="eventDetails.shiftDisplay.complexSignup.addGuest"
                    isRequired={false}
                  />
                }
                warningText={
                  shift.maxSignup &&
                  1 + (complexSignupData.guestCount ?? 0) > shift.signupsRemaining
                    ? t('eventDetails.shiftDisplay.complexSignup.guestsOverLimit', {
                        allowGuests: shift.signupsRemaining - 1,
                      })
                    : null
                }
              >
                <Input
                  // Required for integration test to target css selector instead of text selector.
                  data-testid={'complexSignup.addGuests'}
                  onChange={({ detail }) => {
                    const selectNumGuests = Number.parseInt(detail.value);
                    if (selectNumGuests >= 0) {
                      // unlimited signups
                      if (!shift.maxSignup) {
                        updaters.setGuestCount(selectNumGuests);
                      } else {
                        if (selectNumGuests <= shift.signupsRemaining) {
                          updaters.setGuestCount(selectNumGuests);
                        }
                      }
                    }
                  }}
                  value={(complexSignupData.guestCount ?? 0).toString()}
                  inputMode="numeric"
                  type="number"
                />
              </FormField>
            ) : null}

            {event.hasTShirt ? (
              <FormField
                key={`tShirtKey.${shift.shiftId}.sizeSelection`}
                label={
                  <RequiredLabel
                    label="eventDetails.shiftDisplay.complexSignup.tshirt"
                    isRequired={false}
                  />
                }
              >
                <Select
                  // Adding data-testid to target clickleable button that gets rendered in tests.
                  data-testid={'complexSignup.sizeSelection'}
                  onChange={({ detail }) =>
                    updaters.setTshirtSizeId(
                      detail.selectedOption.value ? Number.parseInt(detail.selectedOption.value) : 1
                    )
                  }
                  selectedOption={
                    tshirtSizeOptions.find(
                      (option) => option.value === complexSignupData.tShirtSizeId?.toString()
                    ) ?? null
                  }
                  options={tshirtSizeOptions}
                  placeholder={t('eventDetails.shiftDisplay.complexSignup.chooseTshirt')}
                />
              </FormField>
            ) : null}

            {event.questions
              ? event.questions.map((question: Question, setIdx: number) => {
                  return (
                    <FormField
                      key={question.id}
                      label={<RequiredLabel label={question.title ?? ''} isRequired={true} />}
                    >
                      <Select
                        // Adding data-testid to target clickleable button that gets rendered in tests.
                        // Since questions are added in a list of options, they need to use the question.id
                        // to get a unique identifier.
                        data-testid={`complexSignup.customQuestion-id-${question.id}`}
                        onChange={({ detail }) => {
                          updaters.setQuestionAnswers(question.id, detail.selectedOption.value);
                        }}
                        selectedOption={
                          question.options?.find(
                            (option) =>
                              complexSignupData.questionAnswers &&
                              option.id === complexSignupData.questionAnswers[setIdx].optionId
                          ) ?? null
                        }
                        options={question.options?.map((option) => ({
                          label: option.label,
                          value: option.id,
                        }))}
                        placeholder={t('eventDetails.shiftDisplay.complexSignup.chooseOptions')}
                      />
                    </FormField>
                  );
                })
              : null}

            {event.waiver && event.waiver.url && event.waiver.acceptance !== 'none' ? (
              <FormField
                key={`waiverKey.${shift.shiftId}.${event.waiver.acceptance}`}
                label={
                  <RequiredLabel
                    label="eventDetails.shiftDisplay.complexSignup.waiver"
                    isRequired={event.waiver.acceptance === 'required'}
                  />
                }
              >
                <SpaceBetween size="s">
                  <FormField key={`waiverKey.${shift.shiftId}.clickViewWaiver`}>
                    <a
                      target="_blank"
                      href={event.waiver.url}
                      onContextMenu={() => updaters.setWaiverLinkOpened(true)}
                      onClick={() => updaters.setWaiverLinkOpened(true)}
                      rel="noreferrer"
                    >
                      {t('eventDetails.shiftDisplay.complexSignup.clickViewWaiver')}
                    </a>
                    <Checkbox
                      checked={complexSignupData.waiverAccepted ?? false}
                      onChange={({ detail }) => updaters.setWaiverAccepted(detail.checked)}
                    >
                      <RequiredLabel
                        label="eventDetails.shiftDisplay.complexSignup.acceptWaiver"
                        isRequired={event.waiver.acceptance === 'required'}
                      />
                    </Checkbox>
                  </FormField>
                </SpaceBetween>
              </FormField>
            ) : null}
            <Button
              fullWidth
              loading={signupMutation.isPending}
              disabled={!validateData(event, shift, waiverLinkOpened, complexSignupData)}
              variant="primary"
              data-aci-analytics-name={`shifts_complex_signup`}
              onClick={() => {
                signupMutation.mutate(
                  {
                    eventId: shift.eventId,
                    shift,
                    request: { ...complexSignupData, shift: shift.shiftId, alias: user.userId },
                  },
                  {
                    onSettled: () => {
                      updaters.reset();
                      // Note: the 'closeButton' input here indicates that the modal was closed by an action within the modal
                      // since the dismiss function only accepts the following reasons: 'closeButton', 'overlay', 'keyboard'
                      // and overlay and keyboard indicate user events that are external to the modal
                      props.onDismiss(new CustomEvent<ModalProps.DismissDetail>('closeButton'));
                    },
                  }
                );
              }}
            >
              {t('eventDetails.shiftDisplay.register')}
            </Button>
          </SpaceBetween>
        </Form>
      </form>
    </Modal>
  );
}

// GOLDEN PATH CODE
// This is an example of a PropertyFilter that does not control it's own contents.
// This is useful for filters that are re-used in multiple places, and whose contents are passed to several different hooks
// Based on https://cloudscape.aws.dev/components/property-filter
import PropertyFilter, {
  PropertyFilterProps,
} from '@amzn/awsui-components-react/polaris/property-filter';
import { usePropertyFilterStrings } from '../../hooks/localization/propertyFilterStrings';
import { useTranslation } from 'react-i18next';

// GOLDEN PATH NOTE
// This allows parent components to control the values displayed in the filter,
// and exposes the filter values to be used in other components or hooks
// It also makes all the PropertyFilterProps optional fields, since required fields
// are all provided in the reuseable definition
export type PeoplePropertyFilterProps = {
  query: PropertyFilterProps.Query;
  onChange: NonNullable<PropertyFilterProps['onChange']>;
} & Partial<PropertyFilterProps>;

const properties = [
  { name: 'person', operators: [':'] },
  { name: 'manager' },
  { name: 'building' },
  { name: 'country' },
  { name: 'level', values: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '10', '11', '12', '99'] },
  { name: 'alias', group: 'mustMatch' },
];

export function PeoplePropertyFilter(props: PeoplePropertyFilterProps) {
  const namespaces = ['attendance', 'translation'];
  const { t } = useTranslation(namespaces);
  const propertyFilterStrings = usePropertyFilterStrings('peoplePropertyFilter', namespaces);

  return (
    <PropertyFilter
      // GOLDEN PATH NOTE
      // Defaults that we expect may need to be overwritten in the future, should come before the props mixin
      // This ensures we only display 3 tokens by default, to keep the UI clean.
      // Users can show/hide any tokens after the first 3
      tokenLimit={3}
      {...props}
      filteringPlaceholder={t('peoplePropertyFilter.filteringPlaceholder')}
      hideOperations
      i18nStrings={propertyFilterStrings}
      // GOLDEN PATH NOTE
      // We do pre-processing of the tokens here, so that any values that the component generates are fully cleaned
      // and can be used as-is in other hooks/components
      onChange={({ detail }) => {
        const flattenedTokens = detail.tokens.flatMap((token) => {
          // If we have previously processed a complex token, or if the user has typed in a simple token, we can just use it as is
          if (!token.value.includes(',') && token.value.trim() !== '') return token;

          // If the user enters a complex token we need to post-process into seperate tokens
          // A complex token can be a search term with commas, which indicates it is actually multiple search terms
          // or it can be a blank token (ie only whitespace), or a combination of the two
          return token.value
            .split(',')
            .filter((tokenValue: string) => tokenValue.trim() !== '')
            .map((tokenValue: string) => ({
              value: tokenValue.trim(),
              propertyKey: token.propertyKey,
              operator: token.operator,
            }));
        });
        props.onChange(
          new CustomEvent('onPeoplePropertyFilterChange', {
            detail: {
              tokens: flattenedTokens,
              operation: 'or',
            },
          })
        );
      }}
      query={props.query}
      customGroupsText={[
        {
          group: t('peoplePropertyFilter.mustMatch'),
          properties: t('peoplePropertyFilter.mustMatch'),
          values: t('peoplePropertyFilter.mustMatch'),
        },
      ]}
      filteringOptions={properties.flatMap((property) =>
        (property.values || []).map((value) => ({ propertyKey: property.name, value }))
      )}
      filteringProperties={properties.map((property) => ({
        key: property.name,
        propertyLabel: t('peoplePropertyFilter.properties.' + property.name),
        groupValuesLabel: t('peoplePropertyFilter.values.' + property.name),
        operators: property.operators,
        defaultOperator: property.operators ? property.operators[0] : undefined,
        group: property.group ? t('peoplePropertyFilter.' + property.group) : undefined,
      }))}
      freeTextFiltering={{
        operators: [':'],
      }}
    />
  );
}

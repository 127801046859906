import { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect/interfaces';
import { SelectProps } from '@amzn/awsui-components-react/polaris/select';
import { DateRangePickerProps } from '@amzn/awsui-components-react';
import { allTimezoneWithOffsetOptions } from '../data/timezoneOptions';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';

//TODO: they don't export this... is there a way to do a typof?
export type SetURLSearchParams = (
  nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions
) => void;

export function urlUpdater(setSearchParams: SetURLSearchParams) {
  return (...params: [key: string, value: string | null][]) => {
    const updader = (prev: URLSearchParams): URLSearchParamsInit => {
      const result: Record<string, string> = {};
      //Why is it value,key instead of key,value like everything else?
      //No idea, but it is.
      prev.forEach((currentValue, currentKey) => {
        result[currentKey] = currentValue;
      });
      params.forEach(([key, value]) => {
        if (value !== null) {
          result[key] = value;
          return;
        }
        if (!(key in result)) {
          return;
        }
        delete result[key];
      });
      return result;
    };
    setSearchParams(updader, { replace: true });
  };
}

export function getValueFromSearchParam(
  searchParams: URLSearchParams,
  key: string,
  defaultValue = ''
) {
  return searchParams.get(key) || defaultValue;
}

type Normalizable = Date | string | number | boolean | object | Normalizable[];
const normalizeValue = (val: Normalizable): string => {
  if (typeof val == 'string') {
    return val;
  }
  if (val instanceof Date) {
    return val.toJSON();
  }
  if (Array.isArray(val)) {
    return val.map((v) => normalizeValue(v)).join(',');
  }
  return JSON.stringify(val);
};

// cleans string input to handle edge cases where users enter query parameters
export function getIntFromUrlParams(
  searchParams: URLSearchParams,
  key: string,
  defaultValue: number
): number {
  const possibleParamNum = searchParams.get(key);
  if (!possibleParamNum) return defaultValue;

  const pageNum = Number(possibleParamNum);
  if (Number.isInteger(pageNum)) return pageNum;

  return defaultValue;
}

/**
 * Converts a params object to a query string, removing any falsy values
 * @param {*} params key/value params to add to the query string
 */
export const toQueryString = (params: { [name: string]: Normalizable | null }) => {
  const qs = Object.keys(params)
    .map((key) => {
      const val = params[key];
      if (key.length === 0 || val === null || val === '') {
        return null;
      }
      const normalized = normalizeValue(val);
      return `${encodeURIComponent(key)}=${encodeURIComponent(normalized)}`;
    })
    .filter((v) => v !== null)
    .join('&');

  return `${qs.length ? '?' : ''}${qs}`;
};

export function getDateRangeFromSearchParams(
  searchParams: URLSearchParams
): DateRangePickerProps.Value | null {
  const startDate = getValueFromSearchParam(searchParams, 'start_timestamp');
  const endDate = getValueFromSearchParam(searchParams, 'end_timestamp');
  return getDateRangeFromStartAndEnd(startDate, endDate);
}

export function getDateRangeFromStartAndEnd(
  startDate: string,
  endDate: string
): DateRangePickerProps.Value | null {
  if (startDate === '' || endDate === '') {
    return null;
  }

  return {
    startDate,
    endDate,
    type: 'absolute',
  };
}

export function getOptionFromValue(
  value: string,
  options: ReadonlyArray<SelectProps.Option>
): SelectProps.Option | null {
  if (value) {
    return options.find((option) => option.value === value) ?? null;
  }
  return null;
}

export function getOptionFromLabel(
  value: string,
  options: ReadonlyArray<SelectProps.Option>
): SelectProps.Option | null {
  if (value !== undefined) {
    return options.find((option) => option.label === value) ?? null;
  }
  return null;
}

export function getOptionFromMultiValues(
  values: string,
  options: ReadonlyArray<MultiselectProps.Option>
): MultiselectProps.Options {
  if (values && values !== '[]') {
    const valueArray = values.slice(1, -1).split(',');
    const result: OptionDefinition[] = [];
    valueArray
      .filter((v) => v !== '')
      .forEach((value) => {
        const found = options.find((option) => option.value === value);
        if (found !== undefined) {
          result.push(found);
        }
      });
    return result;
  }
  return [];
}

export function getTimezoneNameFromUrl(timezoneId?: string) {
  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = allTimezoneWithOffsetOptions.find((tz) =>
    timezoneId ? tz.id === timezoneId : tz.id === defaultTimezone
  );
  return timezone ? timezone.text : '';
}

import React from 'react';
import { FileUpload, SpaceBetween } from '@amzn/awsui-components-react';
import FormField from '@amzn/awsui-components-react/polaris/form-field';
import Link from '@amzn/awsui-components-react/polaris/link';
import { externalLinks } from '../data/externalLinks';
import Button from '@amzn/awsui-components-react/polaris/button';
import { useTranslation } from 'react-i18next';
import { createBulkUpdate } from '../api/bulk';
import { useNavigate } from 'react-router-dom';

export function BulkUpdateForm({ hasPermission }: { hasPermission: boolean }) {
  const [selectedFiles, setSelectedFiles] = React.useState<File[] | null>(null);
  const navigate = useNavigate();
  const [preProcessing, setPreProcessing] = React.useState(false);
  const { t } = useTranslation();

  const validFilesSelected =
    selectedFiles !== null && !selectedFiles.find((file) => !file.name.endsWith('.csv'));

  return (
    <SpaceBetween direction="vertical" size="m">
      <FormField
        label={t('bulkUpdate.bulkUpdateFile')}
        description={
          <>
            {t('bulkUpdate.beforeUploadInstructions')}
            <Link
              href={externalLinks.bulkUpload.bulkUpdateInstructions}
              external
              variant="primary"
              fontSize="body-s"
              data-aci-analytics-name="bulkUpdateInstructions"
            >
              {t('bulkUpdate.uploadInstructions')}
            </Link>
            {t('bulkUpdate.afterUploadInstructions')}
          </>
        }
      >
        <FileUpload
          onChange={({ detail }) => setSelectedFiles(detail.value)}
          value={selectedFiles || []}
          accept=".csv"
          showFileLastModified
          showFileSize
          showFileThumbnail
          i18nStrings={{
            uploadButtonText: (e) => t('bulkUpdate.chooseFile'),
            dropzoneText: (e) => t('bulkUpdate.dropFile'),
            removeFileAriaLabel: (e) => t('bulkUpdate.removeFile'),
            limitShowFewer: t('bulkUpdate.showFewer'),
            limitShowMore: t('bulkUpdate.showMore'),
            errorIconAriaLabel: t('bulkUpdate.error'),
          }}
        />
      </FormField>
      <Button
        variant="primary"
        disabled={!validFilesSelected}
        loading={preProcessing}
        onClick={() => {
          (async () => {
            if (selectedFiles && selectedFiles.length > 0) {
              setPreProcessing(true);
              const text = await selectedFiles[0].text();
              const result = await createBulkUpdate(text);
              const filename = result['fileName'];
              navigate(`/bulk-update?filename=${filename}`);
            }
            setPreProcessing(false);
          })();
        }}
      >
        {preProcessing ? t('bulkUpdate.verifying') : t('bulkUpdate.submit')}
      </Button>
    </SpaceBetween>
  );
}

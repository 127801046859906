import { useTranslation } from 'react-i18next';
import { PaginationProps } from '@amzn/awsui-components-react/polaris/pagination';

export function usePaginationStrings(
  paginationPrefix: string,
  namespaces: string[] = ['translation']
): PaginationProps.Labels {
  const { t } = useTranslation(namespaces);

  return {
    nextPageLabel: t([`${paginationPrefix}.nextPageLabel`, `pagination.nextPageLabel`]),
    pageLabel: (pageNumber: number) =>
      t([`${paginationPrefix}.pageLabel`, `pagination.pageLabel`], { pageNumber }),
    paginationLabel: t([`${paginationPrefix}.paginationLabel`, `pagination.paginationLabel`]),
    previousPageLabel: t([`${paginationPrefix}.previousPageLabel`, `pagination.previousPageLabel`]),
  };
}

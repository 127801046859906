import { Event, Shift, Signup } from '@amzn/red-velvet-api';

import { useSearchParams } from 'react-router-dom';
import { EventSummary } from '../api/schemas/eventSummary';
import { DEFAULT_EVENTS_PAGE, getEvents } from '../api/events';
import { useCallback, useEffect, useState } from 'react';
import { getIntFromUrlParams } from '../utils/url';
import {
  filterEventsByActivityLocation,
  filterEventsWithOnlyPastAndFullShifts,
  filterShifts,
} from '../utils/filterUtils';
import { useUser } from '../hooks/user';
import { getClient } from '../utils/getClient';

export async function loadEvents(
  searchParams: URLSearchParams
): Promise<{ events: EventSummary[]; total: number }> {
  const response = await getEvents(searchParams);
  let events = response.volunteer_opportunities || [];
  if (
    searchParams.get('activity_location') !== null &&
    searchParams.get('activity_location') !== 'all'
  ) {
    events = filterEventsByActivityLocation(events, searchParams);
  }
  if (
    searchParams.get('start_time') ||
    searchParams.get('end_time') ||
    searchParams.get('spots') ||
    searchParams.get('activity_length') ||
    (searchParams.get('days_of_week') && searchParams.get('days_of_week') !== '[]')
  ) {
    events = filterShifts(events, searchParams);
  }
  events = filterEventsWithOnlyPastAndFullShifts(events);
  return {
    events,
    total: response.total,
  };
}

export function useEventsWithSearchParams(): [
  { loading: boolean; events: EventSummary[]; total: number },
  () => void,
] {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState<EventSummary[]>([]);
  const [total, setTotal] = useState(0);
  const [searchParams] = useSearchParams();

  const loadEventsWithSearchParams = useCallback(async () => {
    setLoading(true);
    const currPage = getIntFromUrlParams(searchParams, 'page', DEFAULT_EVENTS_PAGE);
    const newEvents = await loadEvents(searchParams);

    setEvents((oldEvents) => {
      return getEventsWithPagination(
        oldEvents,
        newEvents,
        searchParams.get('pagination'),
        currPage
      );
    });

    setTotal(newEvents.total);
    setLoading(false);
  }, [searchParams, setEvents, setTotal]);

  useEffect(() => {
    loadEventsWithSearchParams();
  }, [loadEventsWithSearchParams]);

  const resetEvents = useCallback(() => setEvents([]), [setEvents]);

  return [{ loading, events, total }, resetEvents];
}

export function useEventWithUser(eventId: string): {
  event: Event | null;
  shifts: Shift[] | null;
  signups: Signup[] | null;
} {
  const { userId } = useUser();
  const [event, setEvent] = useState<Event | null>(null);
  const [shifts, setShifts] = useState<Shift[] | null>(null);
  const [signups, setSignups] = useState<Signup[] | null>(null);

  const loadEvent = useCallback(async () => {
    try {
      const client = getClient(); // TODO - should this be memoized across calls somehow?
      const response = await client.getEventById({ eventId });
      setEvent(response);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, setEvent]);

  const loadShifts = useCallback(async () => {
    try {
      const client = getClient(); // TODO - should this be memoized across calls somehow?
      let page: string | undefined;
      let shifts: Shift[] = [];
      do {
        const response = await client.getShiftsByEvent({ eventId });
        page = response.page;
        shifts = shifts.concat(response.shifts || []);
      } while (page);
      setShifts(shifts);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, setShifts]);

  const loadSignups = useCallback(async () => {
    try {
      const client = getClient(); // TODO - should this be memoized across calls somehow?
      let page: string | undefined;
      let signups: Signup[] = [];
      do {
        const response = await client.getSignupsByQueryParam({ alias: userId, eventId, page });
        page = response.page;
        signups = signups.concat(response.signups || []);
      } while (page);
      setSignups(signups);
    } catch (error) {
      console.log(error);
    }
  }, [eventId, userId, setSignups]);

  useEffect(() => {
    loadEvent();
    loadShifts();
    loadSignups();
  }, [loadEvent, loadShifts, loadSignups]);

  return { event, shifts, signups };
}

export function areEventsDuplicated(
  oldEvents: EventSummary[],
  newEvents: { events: EventSummary[]; total: number }
) {
  return (
    oldEvents &&
    oldEvents.length > 0 &&
    newEvents &&
    newEvents.events &&
    newEvents.events.length > 0 &&
    oldEvents[oldEvents.length - 1].id === newEvents.events[newEvents.events.length - 1].id
  );
}

export function getEventsWithPagination(
  oldEvents: EventSummary[],
  newEvents: { events: EventSummary[]; total: number },
  paginationType: string | null,
  currPage: number
) {
  switch (paginationType) {
    case 'load':
    case 'infinite':
      return appendToOldEventList(oldEvents, newEvents, currPage);
    case 'pages':
      return newEvents.events;
    // load more is currently the default https://issues.amazon.com/issues/cce-2734
    default:
      return appendToOldEventList(oldEvents, newEvents, currPage);
  }
}

function appendToOldEventList(
  oldEvents: EventSummary[],
  newEvents: { events: EventSummary[]; total: number },
  currPage: number
) {
  // Prevent duplicate calls from doubling up the content
  if (areEventsDuplicated(oldEvents, newEvents)) {
    return oldEvents;
  }
  const emptyEvents: EventSummary[] = [];
  return currPage === 1 ? emptyEvents.concat(newEvents.events) : oldEvents.concat(newEvents.events);
}

// GOLDEN PATH CODE
// An example of mutations that update the local data that is displayed on the website
// Based on

// Hooks
import {
  Mutation,
  QueryClient,
  useMutation,
  useMutationState,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { getClient } from '../../utils/getClient';
// Types
import {
  Attendance,
  CreateAttendanceCommandInput,
  CreateAttendanceCommandOutput,
  GetAttendancesByQueryParamCommandOutput,
  GetDetailedSignupsByQueryParamCommandOutput,
} from '@amzn/red-velvet-api';

import { callQueryClientAllPagesRedVelvetQuery } from '../allPagesRedVelvetQuery';

const MUTATION_PREFIX = ['RedVelvetApi', 'timeTracking'];

function getAttendanceForShiftFromDetailedSignups(
  queryClient: QueryClient,
  shiftId: string,
  alias: string
) {
  const detailedSignups = queryClient.getQueriesData<GetDetailedSignupsByQueryParamCommandOutput>({
    queryKey: ['RedVelvetApi', 'getDetailedSignupsByQueryParam'],
  });
  const signup = detailedSignups
    .flatMap(([key, response]) => response?.signups || [])
    .find(
      (detailedSignup) =>
        detailedSignup.signup.shiftId === shiftId && detailedSignup.attendance?.alias === alias
    );
  if (signup?.attendance) {
    return signup.attendance;
  }

  return undefined;
}

async function getAttendanceForShift(
  queryClient: QueryClient,
  eventId: string,
  shiftId: string,
  userAlias: string
) {
  const attendances = await callQueryClientAllPagesRedVelvetQuery(queryClient, {
    redVelvetQueryKey: ['getAttendancesByQueryParam', { eventId, userAlias }],
    converter: (output: GetAttendancesByQueryParamCommandOutput) => ({
      items: output.attendances || [],
      next: output.page,
    }),
  });
  return attendances.find((attendance) => attendance.shiftId === shiftId);
}

export function generateAttendanceKey(eventId: string, shiftId: string, alias: string) {
  return MUTATION_PREFIX.concat(['attendance', eventId, shiftId, alias]);
}

// GOLDEN PATH NOTE
// This function serves as a way to get the specific attendance for a shift by user id
// It should be used as the source of truth for any attendance, as it is immediately updated by the
// mutation instead of requiring a refetch from the API
export function useAttendance(eventId: string, shiftId: string, alias: string) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: generateAttendanceKey(eventId, shiftId, alias),
    queryFn: async () =>
      getAttendanceForShiftFromDetailedSignups(queryClient, shiftId, alias) ||
      (await getAttendanceForShift(queryClient, eventId, shiftId, alias)),
  });
}

export function useTrackTime(eventId: string, shiftId: string) {
  const client = getClient();
  const queryClient = useQueryClient();

  return useMutation<CreateAttendanceCommandOutput, Error, CreateAttendanceCommandInput>({
    mutationKey: MUTATION_PREFIX.concat([shiftId, 'createAttendance']),
    mutationFn: async (attendanceInput) => {
      const attendance = queryClient.getQueryData<Attendance | undefined>(
        generateAttendanceKey(eventId, shiftId, attendanceInput.alias)
      );

      if (attendance) {
        return await client.updateAttendance({ ...attendance, ...attendanceInput });
      }
      return await client.createAttendance({ ...attendanceInput, shiftId });
    },
    // GOLDEN PATH NOTE
    // On a successful mutation, the data in the query client is replaced with the new values immediately
    // This triggers a UI rerender that does not require an extra loading spinner, since the new data is already
    // avalible (ie we can infer it from the user's input)
    onSuccess: (data, attendanceInput) => {
      const oldAttenance = queryClient.getQueryData<Attendance | undefined>(
        generateAttendanceKey(eventId, shiftId, attendanceInput.alias)
      );
      queryClient.setQueryData(generateAttendanceKey(eventId, shiftId, attendanceInput.alias), {
        ...oldAttenance,
        ...attendanceInput,
        attendanceId: data.attendanceId,
      });
    },
  });
}

function attendanceMutationData(mutation: Mutation) {
  return {
    status: mutation.state.status,
    // GOLDEN PATH NOTE
    // This cast is safe because we are filtering on the mutation key
    // However react-query has no way to know the input shape
    variables: mutation.state.variables as CreateAttendanceCommandInput,
    error: mutation.state.error,
    timestamp: mutation.state.submittedAt,
    data: mutation.state.data as CreateAttendanceCommandOutput | null,
  };
}

function generateAttendanceAccordingToInputAndOutput(
  existedAttendance?: Attendance,
  input?: CreateAttendanceCommandInput,
  output?: CreateAttendanceCommandOutput,
  submittedTime?: number
): Attendance | undefined {
  if (!input || !output || !submittedTime) {
    return existedAttendance;
  }
  const timestamp = new Date(submittedTime);

  // for attendance update case
  if (existedAttendance) {
    return {
      ...existedAttendance,
      minutes: input.minutes,
      attendanceStatus: input.attendanceStatus,
      updatedTime: timestamp,
    };
  }
  // for attendance create case
  return {
    ...input,
    attendanceId: output.attendanceId,
    reportedTime: timestamp,
    updatedTime: timestamp,
  };
}

export function useLatestAttendanceState(
  shiftId: string,
  attendance?: Attendance
): Attendance | undefined {
  const data = useMutationState({
    filters: {
      mutationKey: MUTATION_PREFIX.concat([shiftId, 'createAttendance']),
    },
    select: attendanceMutationData,
  });
  let lastIndex = data.length - 1;
  while (lastIndex >= 0) {
    const lastUpdate = data[lastIndex];
    if (lastUpdate.status === 'success') {
      return generateAttendanceAccordingToInputAndOutput(
        attendance,
        lastUpdate.variables,
        lastUpdate.data as CreateAttendanceCommandOutput,
        lastUpdate.timestamp
      );
    }
    lastIndex--;
  }
  return attendance;
}

import { AlertProps } from '@amzn/awsui-components-react';
import { Shift, Signup, Event } from '@amzn/red-velvet-api';
import _ from 'lodash';

/**
 * Model and Reducer
 */
export interface AlertInfo {
  type: AlertProps.Type;
  message?: string;
}
export interface FullEvent {
  event?: Event;
  shifts: Shift[];
  signups: Signup[];
  alert?: AlertInfo;
}

export const SET_ALERT = 'SET_ALERT';
export const SET_SIGNUP = 'SET_SIGNUP';
export const RELOAD_MODEL = 'RELOAD_MODEL';
export const APPEND_MODEL = 'APPEND_MODEL';

export type SetAlertActionType = {
  type: typeof SET_ALERT;
  eventId: string;
  alert?: AlertInfo;
};

export type SignupcAtionType = {
  type: typeof SET_SIGNUP;
  eventId: string;
  shiftId: string;
  userId: string;
  signup?: Signup;
};

export type ReloadModelType = {
  type: typeof RELOAD_MODEL;
  model: ReducedEvents;
};

export type AppendModelType = {
  type: typeof APPEND_MODEL;
  model: ReducedEvents;
};

export type EventType = SetAlertActionType | ReloadModelType | SignupcAtionType | AppendModelType;
export type EventsModel = Record<string, FullEvent>;
export type ReducedEvents = {
  ids: string[];
  events: EventsModel;
};

export function eventsReducer(model: Readonly<ReducedEvents>, event: EventType): ReducedEvents {
  if (event.type === SET_ALERT) {
    const targetEvent = model.events[event.eventId];
    const copy = _.cloneDeep(targetEvent);
    copy.alert = event.alert;
    const result = { events: { ...model.events }, ids: model.ids };
    result.events[event.eventId] = copy;
    return result;
  }
  if (event.type === SET_SIGNUP) {
    const targetEvent = model.events[event.eventId];
    const copy = _.cloneDeep(targetEvent);
    const shift = targetEvent.shifts.find((shift) => shift.shiftId === event.shiftId);
    targetEvent.signups.filter(
      (signup) => !(signup.shiftId === event.shiftId && signup.userId === event.userId)
    );
    const increment = event.signup === undefined ? -1 : 1;
    if (shift && shift.maxSignup && shift.signupsRemaining) {
      const replacement = {
        ...shift,
        maxSignup: shift.maxSignup + increment,
        signupsRemaining: shift.signupsRemaining - increment,
      };
      copy.shifts = copy.shifts.map((x) => (x.shiftId === replacement.shiftId ? replacement : x));
    }
    if (event.signup !== undefined) {
      copy.signups = [...copy.signups, event.signup];
    } else {
      copy.signups = copy.signups.filter(
        (s) =>
          !(s.eventId === event.eventId && s.shiftId === event.shiftId && s.userId === event.userId)
      );
    }
    const result = { events: { ...model.events }, ids: model.ids };
    result.events[event.eventId] = copy;
    return result;
  }
  if (event.type === RELOAD_MODEL) {
    return event.model;
  }
  if (event.type === APPEND_MODEL) {
    const allEventIds = model.ids.concat(event.model.ids.filter((id) => !(id in model.events)));
    const allEvents = {
      ...event.model.events,
      ...model.events,
    };
    const newModel: Record<string, FullEvent> = {};
    allEventIds.forEach((id) => {
      newModel[id] = allEvents[id];
    });
    return {
      events: newModel,
      ids: allEventIds,
    };
  }
  return model;
}

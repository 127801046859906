import { getJson, postJson } from './request';

export const getBulkUpdate = (fileName: string) => {
  return getJson(`/bulk/update/${fileName}`);
};

export const createBulkUpdate = (fileContents: string) => {
  return postJson(`/bulk/update`, {
    fileContents: fileContents,
  });
};

import { useSearchParams } from 'react-router-dom';
import { getValueFromSearchParam } from '../../utils/url';
import { useState } from 'react';
import { publishKatalMetric } from '../../components/katalAnalytics';
import { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect/interfaces';
import { getClosestCity, getUserLocation } from '../../utils/geolocationHelper';

const latlongRegex =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

const multiselectFilters = ['days_of_week'] as const;
const defaultableFilters = ['activity_location', 'frequency'] as const;
const selectFilters = [
  'activity_length_min',
  'activity_length_max',
  'start_time',
  'end_time',
  'interests',
  'skills',
  'start_timestamp',
  'end_timestamp',
] as const;
const textFilters = ['spots', 'query'] as const;
const autocompleteFilters = ['volunteer_location'] as const;

const filterNames = [
  ...multiselectFilters,
  ...defaultableFilters,
  ...selectFilters,
  ...textFilters,
  ...autocompleteFilters,
] as const;
export type FilterName = (typeof filterNames)[number];

function getActiveFilterCount(searchParams: URLSearchParams) {
  let numFilters = 0;

  for (const filter in filterNames) {
    if (getValueFromSearchParam(searchParams, filterNames[filter]) !== '') {
      numFilters++;
    }
  }
  return numFilters;
}

export function optionsToValueArrayString(options: readonly MultiselectProps.Option[]) {
  return `[${options.map((selectedOption) => selectedOption.value).join(',')}]`;
}

export function updateParams(params: URLSearchParams, filterValues: { [s in FilterName]: string }) {
  let activeFilters = 0;
  filterNames.forEach((filter) => {
    if (
      filterValues[filter] == null ||
      filterValues[filter] === '' ||
      filterValues[filter] === '[]' ||
      filterValues[filter] === 'all' ||
      (filter === 'volunteer_location' && !filterValues[filter].match(latlongRegex))
    ) {
      params.delete(filter);
    } else {
      params.set(filter, filterValues[filter]);
      if (filter !== 'end_timestamp') activeFilters++;
      publishKatalMetric('filter_' + filter, filterValues[filter]);
    }
  });

  params.delete('page'); // Reset to page 1 when filters change
  return {
    activeFilters,
    urlParams: params,
  };
}

export function useSharedFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { filterValues, resetValues, updateFilter, updateFilters } = useUrlFilters(searchParams);

  const [activeFilterCount, setActiveFilterCount] = useState(getActiveFilterCount(searchParams));

  const applyFilters = () => {
    setSearchParams((params: URLSearchParams) => {
      const { activeFilters, urlParams } = updateParams(params, filterValues);
      setActiveFilterCount(activeFilters);
      return urlParams;
    });
  };

  function resetFilters() {
    setSearchParams((params: URLSearchParams) => {
      filterNames.forEach((filterName) => params.delete(filterName));
      params.delete('page'); // Reset to page 1 when filters change
      setActiveFilterCount(0);
      resetValues();
      return params;
    });
  }

  return {
    filterValues,
    updateFilter,
    updateFilters,
    activeFilterCount,
    resetFilters,
    applyFilters,
  };
}

export function useUrlFilters(searchParams: URLSearchParams) {
  const autocompleteDefaults: { [s: string]: string } = {};
  getUserLocation((curPos: GeolocationPosition) => {
    autocompleteDefaults['volunteer_location'] = getClosestCity(curPos);
    return autocompleteDefaults['volunteer_location'];
  });

  const defaultState: { [s in FilterName]: string } = {
    query: '',
    activity_location: 'all',
    volunteer_location: '',
    frequency: 'all',
    interests: '',
    skills: '',
    start_time: '',
    end_time: '',
    days_of_week: '[]',
    spots: '',
    activity_length_min: '',
    activity_length_max: '',
    start_timestamp: '',
    end_timestamp: '',
  };

  const initalState: { [s in FilterName]: string } = {
    query: getValueFromSearchParam(searchParams, 'query'),
    activity_location: getValueFromSearchParam(searchParams, 'activity_location', 'all'),
    volunteer_location: getValueFromSearchParam(
      searchParams,
      'volunteer_location',
      autocompleteDefaults['volunteer_location']
    ),
    frequency: getValueFromSearchParam(searchParams, 'frequency', 'all'),
    interests: getValueFromSearchParam(searchParams, 'interests'),
    skills: getValueFromSearchParam(searchParams, 'skills'),
    start_time: getValueFromSearchParam(searchParams, 'start_time'),
    end_time: getValueFromSearchParam(searchParams, 'end_time'),
    days_of_week: getValueFromSearchParam(searchParams, 'days_of_week', '[]'),
    spots: getValueFromSearchParam(searchParams, 'spots'),
    activity_length_min: getValueFromSearchParam(searchParams, 'activity_length_min'),
    activity_length_max: getValueFromSearchParam(searchParams, 'activity_length_max'),
    start_timestamp: getValueFromSearchParam(searchParams, 'start_timestamp'),
    end_timestamp: getValueFromSearchParam(searchParams, 'end_timestamp'),
  };

  const [filterValues, setFilterValues] = useState(initalState);

  const updateFilter = (name: FilterName, value: string) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };
  const updateFilters = (newValues: { [s: string]: string }) => {
    setFilterValues({
      ...filterValues,
      ...newValues,
    });
  };

  const resetValues = () => {
    setFilterValues(defaultState);
  };

  return {
    filterValues,
    updateFilter,
    updateFilters,
    resetValues,
  };
}

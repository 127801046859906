// GOLDEN PATH CODE
// This is an Accessible Loading wrapper based on the recommendations in
// https://quip-amazon.com/yadCAoxGCGVD/Accessibility-and-Client-Side-Dynamic-Caching-The-best-of-both-worlds
// It should be used as a wrapper around areas where data is loaded

import React, { Suspense, ReactNode } from 'react';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';

export type AccessibleSuspenseLoadingProps = {
  loadingText: string;
  children?: ReactNode;
};

// GOLDEN PATH NOTE
// Suspense creates an implicit contract with it's children; it only works if the children use
// suspense data providers.  AS such, we name the parent component suspense to make this clear
export function AccessibleSuspenseLoading({
  loadingText,
  children,
}: AccessibleSuspenseLoadingProps) {
  return (
    <div aria-live="polite">
      <Suspense
        fallback={
          <div>
            <Spinner /> {loadingText}
          </div>
        }
      >
        {children}
      </Suspense>
    </div>
  );
}

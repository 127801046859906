import React from 'react';
import { Outlet } from 'react-router-dom';
import { TopNav } from './components/topNav';
import KatalMetrics from './components/katalAnalytics';
import { useTranslation } from 'react-i18next';
import { BottomNav } from './components/bottomNav';
import { ResetQueriesOnPageChange } from './components/queryResetter';
import { Container, Header, Spinner, Button } from '@amzn/awsui-components-react';
import { Auth } from 'aws-amplify';

import './App.scss';
import { GateOnFeatureLoad, GateOnUserLoad } from './components/context';

export function LoadSsoButton() {
  const { t } = useTranslation();
  return (
    <Container data-testid="loading-spinner" className="fa-3x">
      <Header>{t('auth.title')}</Header>
      <Button
        variant="primary"
        onClick={() => Auth.federatedSignIn({ customProvider: 'AmazonFederate' })}
      >
        {t('auth.login')}
      </Button>
    </Container>
  );
}

function UnauthorizedUser() {
  const { t } = useTranslation();

  return (
    <Container data-testid="unauthorized">
      <Header>{t('auth.failed')}</Header>
      <p>{t('auth.failedInfo')}</p>
    </Container>
  );
}

function Loading() {
  return (
    <Container data-testid="loading-spinner" className="fa-3x">
      <Spinner />
    </Container>
  );
}

function App() {
  return (
    <GateOnUserLoad
      loading={() => <Loading />}
      error={() => <LoadSsoButton />}
      unauthorized={() => <UnauthorizedUser />}
      authorized={() => (
        <GateOnFeatureLoad
          loading={() => <Loading />}
          render={() => (
            <div className="App" data-testid="app-container">
              <div className="header">
                <KatalMetrics />
                <TopNav />
              </div>
              <main>
                <ResetQueriesOnPageChange />
                <Outlet />
              </main>
              <BottomNav />
            </div>
          )}
        />
      )}
    />
  );
}

export default App;

import { Shift } from '../api/schemas/shift';
import { EventSummary } from '../api/schemas/eventSummary';
import { ActivityDuration } from '../data/activityDurationOptions';
import { Signup as BetterSignup, Shift as BetterShift } from '@amzn/red-velvet-api';
import { featureIsEnabled, FeatureState } from '../hooks/features';
import { SIGNUP_DEADLINE_FEATURE_FLAG } from './constants';

interface TimeSlot {
  start: {
    hour: number;
    minute: number;
  };
  end: {
    hour: number;
    minute: number;
  };
}

interface ShiftFilterParams {
  time_slot: TimeSlot;
  spots: number;
  activity_length_min: string;
  activity_length_max: string;
  days_of_week: string[];
  timezone: string;
}

/**
 *
 * @param shift Shim to avoid having to have two implementations of the filter
 * @returns
 */
function asOldShift(shift: BetterShift): Shift {
  return {
    shift_name: shift.name,
    allow_guests: false,
    accept_waitlist: false,
    signups_max: shift.maxSignup ? shift.maxSignup : null,
    signups_remaining: shift.maxSignup ? shift.signupsRemaining : null,
    start_timestamp: shift.startDateTime ? shift.startDateTime.toISOString() : null,
    end_timestamp: shift.endDateTime ? shift.endDateTime.toISOString() : null,
  };
}

export function filterBetterShifts(
  shifts: BetterShift[],
  signups: BetterSignup[],
  searchParams: URLSearchParams,
  features: FeatureState[] | null
) {
  const shiftFilterParams = getShiftFilterParams(searchParams);
  const signedUpShifts: BetterShift[] = [];
  const unsignedUpShifts: BetterShift[] = [];

  shifts.forEach((shift: BetterShift) => {
    const isSignedUp = signups.find((s) => s.shiftId === shift.shiftId);
    const isShiftSignupsOpen = featureIsEnabled(
      SIGNUP_DEADLINE_FEATURE_FLAG,
      features,
      searchParams
    )
      ? shift.signupsOpen === true
      : true;

    if ((isValidShift(asOldShift(shift), shiftFilterParams) && isShiftSignupsOpen) || isSignedUp) {
      if (isSignedUp) {
        signedUpShifts.push(shift);
      } else {
        unsignedUpShifts.push(shift);
      }
    }
  });
  return signedUpShifts.concat(unsignedUpShifts);
}

export function getShiftFilterParams(searchParams: URLSearchParams): ShiftFilterParams {
  const startString = searchParams.get('start_time') || '00:00';
  const endString = searchParams.get('end_time') || '23:59';

  const daysOfWeek = searchParams.get('days_of_week');
  const daysOfWeekArray =
    daysOfWeek && daysOfWeek.length > 2 ? daysOfWeek.slice(1, -1).split(',') : [];
  return {
    time_slot: {
      start: {
        hour: parseInt(startString.split(':')[0]),
        minute: parseInt(startString.split(':')[1] || '0'),
      },
      end: {
        hour: parseInt(endString.split(':')[0]),
        minute: parseInt(endString.split(':')[1] || '0'),
      },
    },
    spots: parseInt(searchParams.get('spots') || '1'),
    activity_length_min: searchParams.get('activity_length_min') || '',
    activity_length_max: searchParams.get('activity_length_max') || '',
    days_of_week: daysOfWeekArray,
    timezone: searchParams.get('timezone') || Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
}

function getTimeInSelectedTimezone(timestamp: string, selectedTimezone: string) {
  const dateTime = new Date(timestamp);
  return new Date(dateTime.toLocaleString('en-US', { timeZone: selectedTimezone }));
}

export function isValidShift(shift: Shift, filterParams: ShiftFilterParams) {
  //https://sim.amazon.com/issues/cce-3510
  //"Waitlist" is a special shift that should only be displayed in some circumstances
  //We will filter all Waitlist shifts as a mitigation until we support Waitlists correctly.
  if (shift.shift_name === 'Waitlist') return false;
  //https://sim.amazon.com/issues/cce-3499
  if (shift.signups_remaining !== null && shift.signups_remaining < filterParams.spots)
    return false;
  if (shift.start_timestamp === null || shift.end_timestamp === null) return true;

  const shiftStart = getTimeInSelectedTimezone(shift.start_timestamp || '', filterParams.timezone);
  const shiftEnd = getTimeInSelectedTimezone(shift.end_timestamp || '', filterParams.timezone);

  return (
    isValidShiftDuration(
      shiftStart,
      shiftEnd,
      filterParams.activity_length_min,
      filterParams.activity_length_max
    ) &&
    (filterParams.days_of_week.length <= 0 ||
      filterParams.days_of_week.includes(shiftStart.getDay().toString())) &&
    shiftStart.getHours() >= filterParams.time_slot.start.hour &&
    shiftEnd.getHours() <= filterParams.time_slot.end.hour &&
    shiftStart.getMinutes() >= filterParams.time_slot.start.minute &&
    shiftEnd.getMinutes() <= filterParams.time_slot.end.minute &&
    isShiftAvailableForSignUp(shift)
  );
}

function hasAvailableShiftInRange(event: EventSummary, filterParams: ShiftFilterParams) {
  const validShifts = event.shifts.filter((shift: Shift) => isValidShift(shift, filterParams));
  return validShifts.length > 0;
}

function isValidShiftDuration(
  startTimestamp: Date,
  endTimestamp: Date,
  activityLengthMin: string,
  activityLengthMax: string
) {
  if (activityLengthMin !== '' && activityLengthMax !== '') {
    const duration: ActivityDuration = {
      max_duration: parseInt(activityLengthMax),
      min_duration: parseInt(activityLengthMin),
      unit: 'Hr',
    };

    // Currently we only use 'Hr' for duration, added this check here so it's clear we can expand on this if we have more units in the future
    if (duration && duration.unit === 'Hr' && (duration.min_duration || duration.max_duration)) {
      const totalLengthInSec = (endTimestamp.getTime() - startTimestamp.getTime()) / 1000;
      const totalLengthInMin = totalLengthInSec / 60;
      const totalLengthInHrs = Math.abs(Math.round(totalLengthInMin / 60));
      return (
        duration.min_duration <= totalLengthInHrs &&
        (duration.max_duration === undefined || totalLengthInHrs <= duration.max_duration)
      );
    }
  }
  return true;
}

function isShiftAvailableForSignUp(shift: Shift) {
  return (
    (shift.signups_remaining === null || shift.signups_remaining > 0) &&
    (shift.end_timestamp === null || new Date(shift.end_timestamp) >= new Date())
  );
}
function isAnyShiftAvailable(shifts: Shift[]) {
  shifts = shifts.filter((shift: Shift) => isShiftAvailableForSignUp(shift));
  return shifts.length > 0;
}

export function filterEventsWithOnlyPastAndFullShifts(events: EventSummary[]) {
  return events.filter((event: EventSummary) => isAnyShiftAvailable(event.shifts));
}

export function filterShiftsPerEvent(event: EventSummary, searchParams: URLSearchParams) {
  const shiftFilterParams = getShiftFilterParams(searchParams);
  return event.shifts.filter((shift: Shift) => isValidShift(shift, shiftFilterParams));
}

export function filterShifts(events: EventSummary[], searchParams: URLSearchParams) {
  const shiftFilterParams = getShiftFilterParams(searchParams);
  return events.filter((event: EventSummary) => hasAvailableShiftInRange(event, shiftFilterParams));
}

export function filterEventsByActivityLocation(
  events: EventSummary[],
  searchParams: URLSearchParams
) {
  return searchParams.get('activity_location') === 'virtual'
    ? events.filter((event: EventSummary) =>
        Object.values(event.address).every((value) => (value === null ? true : false))
      )
    : events.filter((event: EventSummary) =>
        Object.values(event.address).some((value) => (value !== null ? true : false))
      );
}

import React, { ReactNode } from 'react';
import App from '../App';
import { Route, Routes } from 'react-router-dom';
import { Home } from '../routes/home';
import { CreateEvent } from '../routes/createEvent';
import { ViewEvent } from '../routes/viewEvent';
import { BulkUpdateView } from '../routes/bulkUpdate';
import { AttendancePage } from '../components/attendance/attendancePage';
import { ActivityPage } from '../components/myActivity/activityPage';

//auth and features, but want to mock them.
export function AppRoutes({ additionalRoutes }: { additionalRoutes?: ReactNode }) {
  return (
    <Routes>
      {additionalRoutes}
      <Route path="/" element={<App />}>
        <Route path="" element={<Home />} />
        <Route path="view-event" element={<ViewEvent />} />
        <Route path="create-event" element={<CreateEvent />} />
        <Route path="bulk-update" element={<BulkUpdateView />} />
        <Route path="track-attendance" element={<AttendancePage />} />
        <Route path="my-activity" element={<ActivityPage />} />
      </Route>
    </Routes>
  );
}

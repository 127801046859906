import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { FEATURE_QUERY_KEY } from '../hooks/features';
import { LOGIN_QUERY_KEY } from '../hooks/login/login';

// This code resets the queries to their initial unloaded state when the page
// path changes (ie when moving from search page to event page)
export function ResetQueriesOnPageChange() {
  const location = useLocation();
  const queryClient = useQueryClient();
  // Using the prevLocation in state ensures that we only reset queries when the page
  // changes, and not on a re-render
  const [prevLocation, setPrevLocation] = useState(location.pathname);

  // Even though this resets more often than needed, we need it to run on inital variables
  // being set, or else the cached data in the query client ends up being displayed
  // on the initial page load and then refreshed in the background.
  useEffect(() => {
    if (prevLocation !== location.pathname) {
      // We are using resetQueries instead of invalidate queries for accessibility.
      // resetQueries does a hard flush of the cache, and returns all the queries to
      // an unloaded state, so they have to be refetched and will show a loading state
      // while fetching
      // However, there are a couple of queries that should be retained even
      // when the page is changed
      queryClient.resetQueries({
        predicate: (query) =>
          // Do not reset the login query on page change
          JSON.stringify(query.queryKey) !== JSON.stringify(LOGIN_QUERY_KEY) &&
          // Do not reset the features query on page change
          JSON.stringify(query.queryKey) !== JSON.stringify(FEATURE_QUERY_KEY),
      });
      setPrevLocation(location.pathname);
    }
  }, [location.pathname, prevLocation, setPrevLocation, queryClient]);

  return <></>;
}

import { useSearchParams } from 'react-router-dom';
import { getValueFromSearchParam } from '../../utils/url';
import { useState } from 'react';
import { publishKatalMetric } from '../../components/katalAnalytics';
import { MultiselectProps } from '@amzn/awsui-components-react/polaris/multiselect/interfaces';
import { useTranslation } from 'react-i18next';

export const textInputs = [
  'title',
  'building',
  'impact_number',
  'community',
  'contact_name',
  'contact_email',
  'engagement_recap',
  'voc',
] as const;
export const selectInputs = [
  'activity_date',
  'location',
  'start_time',
  'end_time',
  'volunteer_coordinator',
  'focus_area',
  'impact_metric',
] as const;
export const multiselectInputs = ['erg_sponsors', 'team_sponsors'] as const;

export const inputNames = [...selectInputs, ...multiselectInputs, ...textInputs] as const;
export type InputName = (typeof inputNames)[number];

export function optionsToValueArrayString(options: readonly MultiselectProps.Option[]) {
  return `[${options.map((selectedOption) => selectedOption.value).join(',')}]`;
}

export function updateParams(params: URLSearchParams, inputValues: { [s in InputName]: string }) {
  inputNames.forEach((input) => {
    if (
      inputValues[input] == null ||
      inputValues[input] === '' ||
      inputValues[input] === '[]' ||
      inputValues[input] === 'all'
    ) {
      params.delete(input);
    } else {
      params.set(input, inputValues[input]);
      publishKatalMetric('attendance_input_' + input, inputValues[input]);
    }
  });

  params.delete('page'); // Reset to page 1 when inputs change
  return params;
}

export function useSharedInputs() {
  const namespaces = ['attendance', 'translation'];
  const { t } = useTranslation(namespaces);

  const [searchParams, setSearchParams] = useSearchParams();

  const defaultState = Object.fromEntries(
    // Get each saved parameter from the url, since they are all string we do not need to do any
    // special conversion
    inputNames.map((name) => [name, ''])
    // We have to cast it to the proper type, since Object.entries does not surface this properly
    // Since we iterated over the inputNames, we are garunteed to have the correct type
  ) as { [s in InputName]: string };

  const initalState = Object.fromEntries(
    // Get each saved parameter from the url, since they are all string we do not need to do any
    // special conversion
    inputNames.map((name) => [name, getValueFromSearchParam(searchParams, name)])
    // We have to cast it to the proper type, since Object.entries does not surface this properly
    // Since we iterated over the inputNames, we are garunteed to have the correct type
  ) as { [s in InputName]: string };

  const [inputValues, setInputValues] = useState(initalState);

  const updateEventInput = (name: InputName, value: string) => {
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };
  const updateEventInputs = (newValues: { [s: string]: string }) => {
    setInputValues({
      ...inputValues,
      ...newValues,
    });
  };

  const resetValues = () => {
    setInputValues(defaultState);
  };

  const applyEventInputs = () => {
    setSearchParams((params: URLSearchParams) => {
      const urlParams = updateParams(params, inputValues);
      return urlParams;
    });
  };

  function resetEventInputs() {
    setSearchParams((params: URLSearchParams) => {
      inputNames.forEach((inputName) => params.delete(inputName));
      resetValues();
      return params;
    });
  }

  function getValueFromInput(key: InputName) {
    if ((multiselectInputs as readonly string[]).includes(key)) {
      return inputValues[key]
        ? inputValues[key]
            .split(',')
            .map((value) => t('eventDetails.' + key + 'Options.' + value))
            .join(', ')
        : '';
    }

    if ((selectInputs as readonly string[]).includes(key)) {
      return inputValues[key] ? t('eventDetails.' + key + 'Options.' + inputValues[key]) : '';
    }

    return inputValues[key];
  }

  return {
    inputValues,
    updateEventInput,
    updateEventInputs,
    resetEventInputs,
    applyEventInputs,
    getValueFromInput,
  };
}

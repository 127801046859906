import { QuestionAnswer, Shift, Signup } from '@amzn/red-velvet-api';
import { getClient } from '../../utils/getClient';

export async function signupUsertoShift(
  user: string,
  shift: Shift,
  featureFlags: string[] = [],
  guestCount?: number,
  tShirtSizeId?: number,
  questionAnswers?: QuestionAnswer[],
  waiverAccepted?: boolean
) {
  const client = getClient(featureFlags);
  const createSignupParams = {
    shift: shift.shiftId,
    alias: user,
    guestCount,
    questionAnswers,
    waiverAccepted,
    tShirtSizeId,
  };

  const result = await client.createSignup(createSignupParams);

  return {
    signupId: result.signupId,
  };
}

export async function cancelSignup(signup: Signup) {
  await getClient().deleteSignup({ signupId: signup.signupId });
  return;
}

export class RequestError extends Error {
  public statusCode: string;
  public url: string;
  public location: string;
  public method: string;
  public timestamp: Date;

  constructor(statusCode: string, url: string, method: string) {
    super(`Invalid response code: ${statusCode}`);
    this.name = 'RequestError';
    this.statusCode = statusCode;
    this.url = url;
    this.location = window.location.href;
    this.method = method;
    this.timestamp = new Date();
  }

  toString() {
    return (
      `${this.stack}\n` +
      `Failing request: ${this.method} ${this.url}\n` +
      `Current page: ${this.location}\n` +
      `Timestamp: ${this.timestamp.toJSON()}`
    );
  }
}

export class NotFoundError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'NotFoundError';
  }
}

export class PermissionsError extends Error {
  constructor() {
    super('403');
    this.name = 'PermissionsError';
  }
}

export class AbortError extends Error {
  constructor() {
    super('Promise aborted.');
    this.name = 'AbortError';
  }
}

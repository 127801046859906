import { getClient } from '../utils/getClient';
import { useQuery } from '@tanstack/react-query';

export const FEATURE_QUERY_KEY = ['RedVelvetApi', 'getFeatures'];

/**
 * get features from the server or session storage cache. Value will be null until a valid
 * server response has been returned.
 *
 * The user must be logged in for features to work so if you aren't logged in you should not bother
 * Loading these yet.
 *
 * In order for this to work somewhere in the application above you must have defined <ProvideFeatureContext  .../>
 */
export interface FeatureState {
  feature: string;
  value: boolean;
}

export function useFeatures(): FeatureState[] | null {
  const featureQuery = useQuery({
    queryKey: FEATURE_QUERY_KEY,
    queryFn: async () => (await getClient().getFeatures({}, {})).data,
  });

  return featureQuery.data || [];
}

export function featureIsEnabled(
  featureName: string,
  features: FeatureState[] | null,
  searchParams: URLSearchParams
) {
  const paramsFeatures = searchParams.get('features');
  if (searchParams.get('features') === 'default') return false;
  //return a coherant type of boolean.
  const lowerCaseFeatureName = featureName.toLowerCase();
  return (features &&
    features.some(
      (feature) => feature.feature.toLowerCase() === lowerCaseFeatureName && feature.value
    )) ||
    (paramsFeatures && paramsFeatures.toLowerCase().includes(lowerCaseFeatureName))
    ? true
    : false;
}

import { useSearchParams } from 'react-router-dom';

export const PAGE_KEY = 'page';

export interface AttendeePage {
  page: number;
  setPage: (page: number) => void;
}

export function useUrlPage(startPage: number): AttendeePage {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get(PAGE_KEY) || `${startPage}`); // Cloudscape pages are 1-indexed

  const setPage = (newPage: number) => {
    setSearchParams((params) => {
      if (newPage === startPage) {
        params.delete(PAGE_KEY);
      } else {
        params.set(PAGE_KEY, `${newPage}`);
      }

      return params;
    });
  };

  return {
    page,
    setPage,
  };
}

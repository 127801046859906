// Location labels borrowed from GatherWebsite as placeholders
// Values are the corresponding latitude and longitude for the area, as that is what the Benevity API expects
export const locationOptions = [
  {
    label: 'Aachen, Germany',
    lat: 50.776351,
    lon: 6.083862,
    value: '50.776351,6.083862',
  },
  {
    label: 'Abbeville, Louisiana, United States',
    lat: 29.9746502,
    lon: -92.1342921,
    value: '29.9746502,-92.1342921',
  },
  {
    label: 'Aberdeen, Maryland, United States',
    lat: 39.5120347,
    lon: -76.1643289,
    value: '39.5120347,-76.1643289',
  },
  {
    label: 'Abha, Saudi Arabia',
    lat: 18.2164282,
    lon: 42.5043596,
    value: '18.2164282,42.5043596',
  },
  {
    label: 'Abilene, Texas, United States',
    lat: 32.44645,
    lon: -99.7475905,
    value: '32.44645,-99.7475905',
  },
  {
    label: 'Abu Dhabi, United Arab Emirates',
    lat: 24.4538352,
    lon: 54.3774014,
    value: '24.4538352,54.3774014',
  },
  {
    label: 'Acheson, Canada',
    lat: 53.5459141,
    lon: -113.7715761,
    value: '53.5459141,-113.7715761',
  },
  {
    label: 'Achim, Germany',
    lat: 53.0096048,
    lon: 9.039649,
    value: '53.0096048,9.039649',
  },
  {
    label: 'Adelaide, Australia',
    lat: -34.9281805,
    lon: 138.5999312,
    value: '-34.9281805,138.5999312',
  },
  {
    label: 'Ageo, Japan',
    lat: 35.9774082,
    lon: 139.5930504,
    value: '35.9774082,139.5930504',
  },
  {
    label: 'Agognate, Italy',
    lat: 45.4695219,
    lon: 8.5860605,
    value: '45.4695219,8.5860605',
  },
  {
    label: 'Agra, Uttar Pradesh, India',
    lat: 27.1752554,
    lon: 78.0098161,
    value: '27.1752554,78.0098161',
  },
  {
    label: 'Agrigento, Italy',
    lat: 37.3122991,
    lon: 13.57465,
    value: '37.3122991,13.57465',
  },
  {
    label: 'Aguascalientes, Mexico',
    lat: 21.880487,
    lon: -102.2967195,
    value: '21.880487,-102.2967195',
  },
  {
    label: 'Ahmedabad, Gujarat, India',
    lat: 23.0216238,
    lon: 72.5797068,
    value: '23.0216238,72.5797068',
  },
  {
    label: 'Ahmednagar, Maharashtra, India',
    lat: 19.092952,
    lon: 74.7493451,
    value: '19.092952,74.7493451',
  },
  {
    label: 'Aichi, Japan',
    lat: 34.9991645,
    lon: 137.254574,
    value: '34.9991645,137.254574',
  },
  {
    label: 'Aikawacho, Japan',
    lat: 34.6575601,
    lon: 137.307322,
    value: '34.6575601,137.307322',
  },
  {
    label: 'Airdrie, Canada',
    lat: 51.28597,
    lon: -114.01062,
    value: '51.28597,-114.01062',
  },
  {
    label: 'Airway Heights, Washington, United States',
    lat: 47.644611,
    lon: -117.593272,
    value: '47.644611,-117.593272',
  },
  {
    label: 'Ajax, Canada',
    lat: 43.8505287,
    lon: -79.0208814,
    value: '43.8505287,-79.0208814',
  },
  {
    label: 'Ajman, United Arab Emirates',
    lat: 25.3936557,
    lon: 55.4451432,
    value: '25.3936557,55.4451432',
  },
  {
    label: 'Akron, Ohio, United States',
    lat: 41.083064,
    lon: -81.518485,
    value: '41.083064,-81.518485',
  },
  {
    label: 'Alberta, Canada',
    lat: 55.001251,
    lon: -115.002136,
    value: '55.001251,-115.002136',
  },
  {
    label: 'Albuquerque, New Mexico, United States',
    lat: 35.0841034,
    lon: -106.650985,
    value: '35.0841034,-106.650985',
  },
  {
    label: 'Alcalá de Henares, Spain',
    lat: 40.4818396,
    lon: -3.3644973,
    value: '40.4818396,-3.3644973',
  },
  {
    label: 'Alcover, Spain',
    lat: 41.2629186,
    lon: 1.1702772,
    value: '41.2629186,1.1702772',
  },
  {
    label: 'Alessandria, Italy',
    lat: 44.9130438,
    lon: 8.6154872,
    value: '44.9130438,8.6154872',
  },
  {
    label: 'Alexandria, Egypt',
    lat: 31.1991806,
    lon: 29.8951716,
    value: '31.1991806,29.8951716',
  },
  {
    label: 'Alexandria, Virginia, United States',
    lat: 38.8051095,
    lon: -77.0470229,
    value: '38.8051095,-77.0470229',
  },
  {
    label: 'Alicante, Spain',
    lat: 38.3436365,
    lon: -0.4881708,
    value: '38.3436365,-0.4881708',
  },
  {
    label: 'Allahabad, Uttar Pradesh, India',
    lat: 25.448429,
    lon: 81.832198,
    value: '25.448429,81.832198',
  },
  {
    label: 'Almeria, Spain',
    lat: 36.8414197,
    lon: -2.4628135,
    value: '36.8414197,-2.4628135',
  },
  {
    label: 'Alpharetta, Georgia, United States',
    lat: 34.0709576,
    lon: -84.2747329,
    value: '34.0709576,-84.2747329',
  },
  {
    label: 'Altoona, Pennsylvania, United States',
    lat: 40.518681,
    lon: -78.394736,
    value: '40.518681,-78.394736',
  },
  {
    label: 'Alwar, Rajasthan, India',
    lat: 27.5645949,
    lon: 76.6132248,
    value: '27.5645949,76.6132248',
  },
  {
    label: 'Amagasaki, Japan',
    lat: 34.7288995,
    lon: 135.412989,
    value: '34.7288995,135.412989',
  },
  {
    label: 'Amarillo, Texas, United States',
    lat: 35.2072185,
    lon: -101.833824,
    value: '35.2072185,-101.833824',
  },
  {
    label: 'Ambala, Haryana, India',
    lat: 30.3843674,
    lon: 76.770421,
    value: '30.3843674,76.770421',
  },
  {
    label: 'American Canyon, California, United States',
    lat: 38.1785248,
    lon: -122.255183,
    value: '38.1785248,-122.255183',
  },
  {
    label: 'American Fork, Utah, United States',
    lat: 40.3768064,
    lon: -111.7959146,
    value: '40.3768064,-111.7959146',
  },
  {
    label: 'Amman, Jordan',
    lat: 31.9515694,
    lon: 35.9239625,
    value: '31.9515694,35.9239625',
  },
  {
    label: 'Amritsar, Punjab, India',
    lat: 31.6343083,
    lon: 74.8736788,
    value: '31.6343083,74.8736788',
  },
  {
    label: 'Amsterdam, Netherlands',
    lat: 52.3730796,
    lon: 4.8924534,
    value: '52.3730796,4.8924534',
  },
  {
    label: 'Amsterdam, New York, United States',
    lat: 42.943367,
    lon: -74.1850436,
    value: '42.943367,-74.1850436',
  },
  // {
  //   "label": "AmwajIsl, France",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Anaheim, California, United States',
    lat: 33.8347516,
    lon: -117.911732,
    value: '33.8347516,-117.911732',
  },
  {
    label: 'Anand, Gujarat, India',
    lat: 22.5586555,
    lon: 72.9627227,
    value: '22.5586555,72.9627227',
  },
  {
    label: 'Anantpur, Andhra Pradesh, India',
    lat: 25.1126399,
    lon: 82.5391297,
    value: '25.1126399,82.5391297',
  },
  {
    label: 'Anchorage, Alaska, United States',
    lat: 61.2163129,
    lon: -149.894852,
    value: '61.2163129,-149.894852',
  },
  {
    label: 'Ancona, Italy',
    lat: 43.6170137,
    lon: 13.5170982,
    value: '43.6170137,13.5170982',
  },
  {
    label: 'Ancuri, Brazil',
    lat: -3.8642041,
    lon: -38.5060694,
    value: '-3.8642041,-38.5060694',
  },
  {
    label: 'Ankara, Turkey',
    lat: 39.9207759,
    lon: 32.8540497,
    value: '39.9207759,32.8540497',
  },
  {
    label: 'Ankeny, Iowa, United States',
    lat: 41.7320796,
    lon: -93.605092,
    value: '41.7320796,-93.605092',
  },
  {
    label: 'Ann Arbor, Michigan, United States',
    lat: 42.2813722,
    lon: -83.7484616,
    value: '42.2813722,-83.7484616',
  },
  {
    label: 'Annapolis Junction, Maryland, United States',
    lat: 39.11899645,
    lon: -76.79634175,
    value: '39.11899645,-76.7963417455609',
  },
  {
    label: 'Ansfelden, Austria',
    lat: 48.2090744,
    lon: 14.2892608,
    value: '48.2090744,14.2892608',
  },
  {
    label: 'Antioch, Tennessee, United States',
    lat: 35.8572885,
    lon: -85.7169264,
    value: '35.8572885,-85.7169264',
  },
  {
    label: 'Antwerp, Belgium',
    lat: 51.2211097,
    lon: 4.3997081,
    value: '51.2211097,4.3997081',
  },
  {
    label: 'Aomori, Japan',
    lat: 40.886943,
    lon: 140.590121,
    value: '40.886943,140.590121',
  },
  {
    label: 'Aparecida de Goiânia, Brazil',
    lat: -16.8226769,
    lon: -49.2452546,
    value: '-16.8226769,-49.2452546',
  },
  {
    label: 'Apodaca, Mexico',
    lat: 25.7816542,
    lon: -100.1887501,
    value: '25.7816542,-100.1887501',
  },
  {
    label: 'Apopka, Florida, United States',
    lat: 28.678013,
    lon: -81.511398,
    value: '28.678013,-81.511398',
  },
  {
    label: 'Appling, Georgia, United States',
    lat: 33.5459683,
    lon: -82.3159572,
    value: '33.5459683,-82.3159572',
  },
  {
    label: 'Ardea, Italy',
    lat: 41.608712,
    lon: 12.519382,
    value: '41.608712,12.519382',
  },
  {
    label: 'Arlington Heights, Illinois, United States',
    lat: 42.0811563,
    lon: -87.9802164,
    value: '42.0811563,-87.9802164',
  },
  {
    label: 'Arlington, Texas, United States',
    lat: 32.7355816,
    lon: -97.1071186,
    value: '32.7355816,-97.1071186',
  },
  {
    label: 'Arlington, Virginia, United States',
    lat: 38.8903961,
    lon: -77.0841585,
    value: '38.8903961,-77.0841585',
  },
  {
    label: 'Arlington, Washington, United States',
    lat: 48.1810957,
    lon: -122.1389548,
    value: '48.1810957,-122.1389548',
  },
  {
    label: 'Arundel, Australia',
    lat: -27.940383,
    lon: 153.36432313433852,
    value: '-27.9403829999999,153.36432313433852',
  },
  {
    label: 'Arzano, Italy',
    lat: 40.9160204,
    lon: 14.2680178,
    value: '40.9160204,14.2680178',
  },
  {
    label: 'As Sulay, Saudi Arabia',
    lat: 24.6791963,
    lon: 46.8376646,
    value: '24.6791963,46.8376646',
  },
  {
    label: 'Asansol, West Bengal, India',
    lat: 24.2297419,
    lon: 87.2747575,
    value: '24.2297419,87.2747575',
  },
  {
    label: 'Ashburn, Virginia, United States',
    lat: 39.0437192,
    lon: -77.4874899,
    value: '39.0437192,-77.4874899',
  },
  {
    label: 'Ashland, Missouri, United States',
    lat: 38.7744802,
    lon: -92.2571304,
    value: '38.7744802,-92.2571304',
  },
  {
    label: 'Ashland, Virginia, United States',
    lat: 37.7594012,
    lon: -77.4806603,
    value: '37.7594012,-77.4806603',
  },
  {
    label: 'Ashoknagar, Jharkhand, India',
    lat: 24.576189,
    lon: 77.7302895,
    value: '24.576189,77.7302895',
  },
  {
    label: 'Assuit, Egypt',
    lat: 27.2680047,
    lon: 31.2814196,
    value: '27.2680047,31.2814196',
  },
  {
    label: 'Asti, Italy',
    lat: 44.900542,
    lon: 8.2068876,
    value: '44.900542,8.2068876',
  },
  {
    label: 'Athens, Greece',
    lat: 37.9755648,
    lon: 23.7348324,
    value: '37.9755648,23.7348324',
  },
  {
    label: 'Atlanta, Georgia, United States',
    lat: 33.7489924,
    lon: -84.3902644,
    value: '33.7489924,-84.3902644',
  },
  {
    label: 'Atsugi, Japan',
    lat: 35.464797,
    lon: 139.323536,
    value: '35.464797,139.323536',
  },
  {
    label: 'Attibele, Karnataka, India',
    lat: 12.7782588,
    lon: 77.7712834,
    value: '12.7782588,77.7712834',
  },
  {
    label: 'Auburndale, Florida, United States',
    lat: 28.0653422,
    lon: -81.7888642,
    value: '28.0653422,-81.7888642',
  },
  {
    label: 'Auckland, New Zealand',
    lat: -36.852095,
    lon: 174.7631803,
    value: '-36.852095,174.7631803',
  },
  {
    label: 'Augny, France',
    lat: 49.0593685,
    lon: 6.1219209,
    value: '49.0593685,6.1219209',
  },
  {
    label: 'Aurangabad, Bihar, India',
    lat: 24.7536696,
    lon: 84.3746725,
    value: '24.7536696,84.3746725',
  },
  {
    label: 'Aurangabad, Maharashtra, India',
    lat: 19.877263,
    lon: 75.3390241,
    value: '19.877263,75.3390241',
  },
  {
    label: 'Aurora, Colorado, United States',
    lat: 39.7405111,
    lon: -104.830994,
    value: '39.7405111,-104.830994',
  },
  {
    label: 'Aurora, Illinois, United States',
    lat: 41.7571701,
    lon: -88.3147539,
    value: '41.7571701,-88.3147539',
  },
  {
    label: 'Austell, Georgia, United States',
    lat: 33.8126059,
    lon: -84.6343783,
    value: '33.8126059,-84.6343783',
  },
  {
    label: 'Austin, Texas, United States',
    lat: 30.2711286,
    lon: -97.7436995,
    value: '30.2711286,-97.7436995',
  },
  {
    label: 'Avenel, New Jersey, United States',
    lat: 40.5765027,
    lon: -74.2749836,
    value: '40.5765027,-74.2749836',
  },
  {
    label: 'Avion, France',
    lat: 50.4126128,
    lon: 2.8343891,
    value: '50.4126128,2.8343891',
  },
  {
    label: 'Avondale, Arizona, United States',
    lat: 33.4355977,
    lon: -112.349602,
    value: '33.4355977,-112.349602',
  },
  {
    label: 'Azamgarh, Uttar Pradesh, India',
    lat: 26.0654351,
    lon: 83.184439,
    value: '26.0654351,83.184439',
  },
  {
    label: 'Bad Hersfeld, Germany',
    lat: 50.8604177,
    lon: 9.6767709,
    value: '50.8604177,9.6767709',
  },
  {
    label: 'Bad Oldesloe, Germany',
    lat: 53.8096562,
    lon: 10.3733692,
    value: '53.8096562,10.3733692',
  },
  {
    label: 'Badajoz, Spain',
    lat: 38.878187,
    lon: -6.9701115,
    value: '38.878187,-6.9701115',
  },
  {
    label: 'Bagru, Rajasthan, India',
    lat: 26.8087953,
    lon: 75.5423119,
    value: '26.8087953,75.5423119',
  },
  {
    label: 'Bahadur Garh, Haryana, India',
    lat: 29.45536,
    lon: 77.02747,
    value: '29.45536,77.02747',
  },
  {
    label: 'Bahrain, Bahrain',
    lat: 26.1551249,
    lon: 50.5344606,
    value: '26.1551249,50.5344606',
  },
  {
    label: 'Bakersfield, California, United States',
    lat: 35.3738712,
    lon: -119.019463,
    value: '35.3738712,-119.019463',
  },
  {
    label: 'Balch Springs, Texas, United States',
    lat: 32.7287413,
    lon: -96.6227714,
    value: '32.7287413,-96.6227714',
  },
  {
    label: 'Baltimore, Maryland, United States',
    lat: 39.2908816,
    lon: -76.610759,
    value: '39.2908816,-76.610759',
  },
  {
    label: 'Balzac, Canada',
    lat: 51.2156111,
    lon: -114.0045579,
    value: '51.2156111,-114.0045579',
  },
  {
    label: 'Banbury, United Kingdom',
    lat: 52.0601807,
    lon: -1.3402795,
    value: '52.0601807,-1.3402795',
  },
  {
    label: 'Bangalore, Karnataka, India',
    lat: 12.9767936,
    lon: 77.590082,
    value: '12.9767936,77.590082',
  },
  {
    label: 'Bangkok, Thailand',
    lat: 13.7524938,
    lon: 100.4935089,
    value: '13.7524938,100.4935089',
  },
  {
    label: 'Barbera del Valles, Spain',
    lat: 41.5172534,
    lon: 2.1265942,
    value: '41.5172534,2.1265942',
  },
  {
    label: 'Barcelona, Spain',
    lat: 41.3828939,
    lon: 2.1774322,
    value: '41.3828939,2.1774322',
  },
  {
    label: 'Bardhaman, West Bengal, India',
    lat: 23.2495714,
    lon: 87.8681751,
    value: '23.2495714,87.8681751',
  },
  {
    label: 'Bardon Hill, United Kingdom',
    lat: 52.7139197,
    lon: -1.3196174,
    value: '52.7139197,-1.3196174',
  },
  {
    label: 'Bareilly, Uttar Pradesh, India',
    lat: 28.355099,
    lon: 79.417851,
    value: '28.355099,79.417851',
  },
  {
    label: 'Barrhaven, Canada',
    lat: 45.280172,
    lon: -75.759707,
    value: '45.280172,-75.759707',
  },
  {
    label: 'Barrie, Canada',
    lat: 44.3893208,
    lon: -79.6901302,
    value: '44.3893208,-79.6901302',
  },
  {
    label: 'BARUERI, Brazil',
    lat: -23.5112184,
    lon: -46.8764612,
    value: '-23.5112184,-46.8764612',
  },
  {
    label: 'Basildon, United Kingdom',
    lat: 51.5754602,
    lon: 0.4757363,
    value: '51.5754602,0.4757363',
  },
  {
    label: 'Bassens, France',
    lat: 44.9015003,
    lon: -0.5163222,
    value: '44.9015003,-0.5163222',
  },
  {
    label: 'Bath, New York, United States',
    lat: 42.3370164,
    lon: -77.3177577,
    value: '42.3370164,-77.3177577',
  },
  {
    label: 'Baton Rouge, Louisiana, United States',
    lat: 30.4494155,
    lon: -91.1869659,
    value: '30.4494155,-91.1869659',
  },
  {
    label: 'Bayonne, New Jersey, United States',
    lat: 40.6687141,
    lon: -74.1143091,
    value: '40.6687141,-74.1143091',
  },
  {
    label: 'Bayonne, New York, United States',
    lat: 42.7855546,
    lon: -101.9776601,
    value: '42.7855546,-101.9776601',
  },
  {
    label: 'Bayreuth, Germany',
    lat: 49.9427202,
    lon: 11.5763079,
    value: '49.9427202,11.5763079',
  },
  {
    label: 'Beaumont, California, United States',
    lat: 33.9299925,
    lon: -116.9763092,
    value: '33.9299925,-116.9763092',
  },
  {
    label: 'Bedford Heights, Ohio, United States',
    lat: 41.4143826,
    lon: -81.5061146,
    value: '41.4143826,-81.5061146',
  },
  {
    label: 'Bedford, New Hampshire, United States',
    lat: 42.9464737,
    lon: -71.5159015,
    value: '42.9464737,-71.5159015',
  },
  {
    label: 'Bedford, Ohio, United States',
    lat: 41.39311,
    lon: -81.536509,
    value: '41.39311,-81.536509',
  },
  {
    label: 'Beijing, China',
    lat: 39.9057136,
    lon: 116.3912972,
    value: '39.9057136,116.3912972',
  },
  {
    label: 'Belgaum, Karnataka, India',
    lat: 15.8572666,
    lon: 74.5069343,
    value: '15.8572666,74.5069343',
  },
  {
    label: 'Bellary, Karnataka, India',
    lat: 15.1433952,
    lon: 76.9193876,
    value: '15.1433952,76.9193876',
  },
  {
    label: 'Belleville, Canada',
    lat: 44.1633264,
    lon: -77.3852556,
    value: '44.1633264,-77.3852556',
  },
  {
    label: 'Bellevue, Washington, United States',
    lat: 47.6144219,
    lon: -122.192337,
    value: '47.6144219,-122.192337',
  },
  {
    label: 'Bellingham, Massachusetts, United States',
    lat: 42.0869493,
    lon: -71.4746436,
    value: '42.0869493,-71.4746436',
  },
  {
    label: 'Bellingham, Washington, United States',
    lat: 48.7544012,
    lon: -122.478836,
    value: '48.7544012,-122.478836',
  },
  {
    label: 'Bellmawr, New Jersey, United States',
    lat: 39.8676134,
    lon: -75.0946183,
    value: '39.8676134,-75.0946183',
  },
  {
    label: 'Belo Horizonte, Brazil',
    lat: -19.9227318,
    lon: -43.9450948,
    value: '-19.9227318,-43.9450948',
  },
  {
    label: 'Beloit, Wisconsin, United States',
    lat: 42.5083272,
    lon: -89.031784,
    value: '42.5083272,-89.031784',
  },
  {
    label: 'Beltsville, Maryland, United States',
    lat: 39.0299081,
    lon: -76.9149547,
    value: '39.0299081,-76.9149547',
  },
  {
    label: 'Benevento, Italy',
    lat: 41.1295587,
    lon: 14.7824639,
    value: '41.1295587,14.7824639',
  },
  {
    label: 'Bengaluru, Karnataka, India',
    lat: 12.9767936,
    lon: 77.590082,
    value: '12.9767936,77.590082',
  },
  {
    label: 'Benha, Egypt',
    lat: 30.4624974,
    lon: 31.184063,
    value: '30.4624974,31.184063',
  },
  {
    label: 'Bensalem, Pennsylvania, United States',
    lat: 40.1045549,
    lon: -74.951279,
    value: '40.1045549,-74.951279',
  },
  {
    label: 'Berekley, Missouri, United States',
    lat: 39.006811,
    lon: -92.362444,
    value: '39.006811,-92.362444',
  },
  {
    label: 'Berkeley, California, United States',
    lat: 37.8708393,
    lon: -122.272863,
    value: '37.8708393,-122.272863',
  },
  {
    label: 'Berkeley, Missouri, United States',
    lat: 38.7544952,
    lon: -90.3312256,
    value: '38.7544952,-90.3312256',
  },
  {
    label: 'Berlin, Germany',
    lat: 52.5170365,
    lon: 13.3888599,
    value: '52.5170365,13.3888599',
  },
  {
    label: 'Bernissart, Belgium',
    lat: 50.4785984,
    lon: 3.691426003709652,
    value: '50.4785984,3.691426003709652',
  },
  {
    label: 'Bessemer, Alabama, United States',
    lat: 33.4017766,
    lon: -86.954437,
    value: '33.4017766,-86.954437',
  },
  {
    label: 'Bethel, Pennsylvania, United States',
    lat: 40.4743954,
    lon: -76.2917678,
    value: '40.4743954,-76.2917678',
  },
  {
    label: 'Bethlehem, Pennsylvania, United States',
    lat: 40.6178915,
    lon: -75.3786521,
    value: '40.6178915,-75.3786521',
  },
  {
    label: 'Bethpage, New York, United States',
    lat: 40.749517,
    lon: -73.485536,
    value: '40.749517,-73.485536',
  },
  {
    label: 'Betim, Brazil',
    lat: -19.968056,
    lon: -44.198333,
    value: '-19.968056,-44.198333',
  },
  {
    label: 'Beverly Hills, California, United States',
    lat: 34.0696501,
    lon: -118.3963062,
    value: '34.0696501,-118.3963062',
  },
  {
    label: 'Bhatinda, Punjab, India',
    lat: 30.25,
    lon: 75,
    value: '30.25,75',
  },
  {
    label: 'Bhavnagar, Gujarat, India',
    lat: 21.7718836,
    lon: 72.1416449,
    value: '21.7718836,72.1416449',
  },
  {
    label: 'Bhilai, Connecticut, India',
    lat: 21.2120677,
    lon: 81.3732849,
    value: '21.2120677,81.3732849',
  },
  {
    label: 'Bhilwara, Rajasthan, India',
    lat: 25.348,
    lon: 74.636,
    value: '25.348,74.636',
  },
  {
    label: 'Bhiwandi, Maharashtra, India',
    lat: 19.3025562,
    lon: 73.0588072,
    value: '19.3025562,73.0588072',
  },
  {
    label: 'Bhopal, Madhya Pradesh, India',
    lat: 23.2584857,
    lon: 77.401989,
    value: '23.2584857,77.401989',
  },
  {
    label: 'Bhubaneswar, OR, India',
    lat: 20.2602964,
    lon: 85.8394521,
    value: '20.2602964,85.8394521',
  },
  {
    label: 'Bielany Wroclawskie, Poland',
    lat: 51.0392894,
    lon: 16.9686781,
    value: '51.0392894,16.9686781',
  },
  {
    label: 'Bielefeld, Germany',
    lat: 52.0191005,
    lon: 8.531007,
    value: '52.0191005,8.531007',
  },
  {
    label: 'Bijnor, Uttar Pradesh, India',
    lat: 29.3695221,
    lon: 78.1371435,
    value: '29.3695221,78.1371435',
  },
  {
    label: 'Bikaner, Rajasthan, India',
    lat: 28.0159286,
    lon: 73.3171367,
    value: '28.0159286,73.3171367',
  },
  {
    label: 'Bilaspur, Connecticut, India',
    lat: 22.0785627,
    lon: 82.1476475,
    value: '22.0785627,82.1476475',
  },
  {
    label: 'Bilbao, Spain',
    lat: 43.2630018,
    lon: -2.9350039,
    value: '43.2630018,-2.9350039',
  },
  {
    label: 'Birbhum, West Bengal, India',
    lat: 24,
    lon: 87.583333,
    value: '24,87.583333',
  },
  {
    label: 'Birmingham, Alabama, United States',
    lat: 33.5206824,
    lon: -86.8024326,
    value: '33.5206824,-86.8024326',
  },
  {
    label: 'Birmingham, Michigan, United States',
    lat: 42.5467012,
    lon: -83.2113192,
    value: '42.5467012,-83.2113192',
  },
  {
    label: 'Birmingham, United Kingdom',
    lat: 52.4796992,
    lon: -1.9026911,
    value: '52.4796992,-1.9026911',
  },
  {
    label: 'Bitonto (BA), Italy',
    lat: 41.1084971,
    lon: 16.691548,
    value: '41.1084971,16.691548',
  },
  {
    label: 'Blauvelt, New York, United States',
    lat: 41.0634299,
    lon: -73.9576378,
    value: '41.0634299,-73.9576378',
  },
  {
    label: 'Bloomingdale, Illinois, United States',
    lat: 41.9575285,
    lon: -88.0809036,
    value: '41.9575285,-88.0809036',
  },
  {
    label: 'Bloomington, California, United States',
    lat: 34.07029,
    lon: -117.395879,
    value: '34.07029,-117.395879',
  },
  {
    label: 'Blue Springs, Missouri, United States',
    lat: 39.017316,
    lon: -94.282265,
    value: '39.017316,-94.282265',
  },
  {
    label: 'Blythewood, South Carolina, United States',
    lat: 34.2187005,
    lon: -80.98456918,
    value: '34.2187005,-80.9845691801123',
  },
  {
    label: 'Boardman, Oregon, United States',
    lat: 45.839855,
    lon: -119.700583,
    value: '45.839855,-119.700583',
  },
  {
    label: 'Boca Raton, Florida, United States',
    lat: 26.3586885,
    lon: -80.0830984,
    value: '26.3586885,-80.0830984',
  },
  {
    label: 'Bochum, Germany',
    lat: 51.4818111,
    lon: 7.2196635,
    value: '51.4818111,7.2196635',
  },
  {
    label: 'Bodelshausen, Germany',
    lat: 48.3939085,
    lon: 8.9722782,
    value: '48.3939085,8.9722782',
  },
  {
    label: 'Bogota, Colombia',
    lat: 4.6529539,
    lon: -74.0835643,
    value: '4.6529539,-74.0835643',
  },
  {
    label: 'Boise, Idaho, United States',
    lat: 43.6166163,
    lon: -116.200886,
    value: '43.6166163,-116.200886',
  },
  {
    label: 'Bologna, Italy',
    lat: 44.4938203,
    lon: 11.3426327,
    value: '44.4938203,11.3426327',
  },
  {
    label: 'Bolton, Canada',
    lat: 43.879548,
    lon: -79.73826,
    value: '43.879548,-79.73826',
  },
  {
    label: 'Bolton, United Kingdom',
    lat: 53.5782863,
    lon: -2.4300367,
    value: '53.5782863,-2.4300367',
  },
  {
    label: 'Bolzano, Italy',
    lat: 45.9709647,
    lon: 13.3877614,
    value: '45.9709647,13.3877614',
  },
  {
    label: 'Bondurant, Iowa, United States',
    lat: 41.7005441,
    lon: -93.462157,
    value: '41.7005441,-93.462157',
  },
  {
    label: 'Bonneuil sur Marne, France',
    lat: 48.7737116,
    lon: 2.4869009,
    value: '48.7737116,2.4869009',
  },
  {
    label: 'Bordeaux, France',
    lat: 44.841225,
    lon: -0.5800364,
    value: '44.841225,-0.5800364',
  },
  {
    label: 'Bordentown, New Jersey, United States',
    lat: 40.1462685,
    lon: -74.7114508,
    value: '40.1462685,-74.7114508',
  },
  {
    label: 'Borgstedt, Germany',
    lat: 54.3333048,
    lon: 9.7100898,
    value: '54.3333048,9.7100898',
  },
  {
    label: 'Boston, Massachusetts, United States',
    lat: 42.3554334,
    lon: -71.060511,
    value: '42.3554334,-71.060511',
  },
  {
    label: 'Boulder, Colorado, United States',
    lat: 40.0149856,
    lon: -105.270545,
    value: '40.0149856,-105.270545',
  },
  {
    label: 'Bournemouth, United Kingdom',
    lat: 50.7201514,
    lon: -1.8799118,
    value: '50.7201514,-1.8799118',
  },
  {
    label: 'Boves, France',
    lat: 49.8463524,
    lon: 2.3932912,
    value: '49.8463524,2.3932912',
  },
  {
    label: 'Bowburn, United Kingdom',
    lat: 54.7395825,
    lon: -1.5242761,
    value: '54.7395825,-1.5242761',
  },
  {
    label: 'Brampton, Canada',
    lat: 43.685832,
    lon: -79.7599366,
    value: '43.685832,-79.7599366',
  },
  {
    label: 'Braselton, Georgia, United States',
    lat: 34.1092735,
    lon: -83.7626729,
    value: '34.1092735,-83.7626729',
  },
  {
    label: 'Brasilia, Brazil',
    lat: -15.7934036,
    lon: -47.8823172,
    value: '-15.7934036,-47.8823172',
  },
  {
    label: 'Bratislava, Slovakia',
    lat: 48.1516988,
    lon: 17.1093063,
    value: '48.1516988,17.1093063',
  },
  {
    label: 'Brebieres, France',
    lat: 50.3372093,
    lon: 3.0224298,
    value: '50.3372093,3.0224298',
  },
  {
    label: 'Breingsville, Pennsylvania, United States',
    lat: 40.860198,
    lon: -77.838617,
    value: '40.860198,-77.838617',
  },
  {
    label: 'Breinigsville, Pennsylvania, United States',
    lat: 40.538201,
    lon: -75.632753,
    value: '40.538201,-75.632753',
  },
  {
    label: 'Bremen, Germany',
    lat: 53.0758196,
    lon: 8.8071646,
    value: '53.0758196,8.8071646',
  },
  {
    label: 'Bremerhaven, Germany',
    lat: 53.5505392,
    lon: 8.5851945,
    value: '53.5505392,8.5851945',
  },
  {
    label: 'Bremerton, Washington, United States',
    lat: 47.5653663,
    lon: -122.6246836,
    value: '47.5653663,-122.6246836',
  },
  {
    label: 'Bretigny sur Orge, France',
    lat: 48.6099887,
    lon: 2.3075288,
    value: '48.6099887,2.3075288',
  },
  {
    label: 'Bridgeview, Illinois, United States',
    lat: 41.7500323,
    lon: -87.8042216,
    value: '41.7500323,-87.8042216',
  },
  {
    label: 'Bridgewater, Massachusetts, United States',
    lat: 41.9903787,
    lon: -70.9750426,
    value: '41.9903787,-70.9750426',
  },
  {
    label: 'Briec, France',
    lat: 48.1019452,
    lon: -3.999718,
    value: '48.1019452,-3.999718',
  },
  {
    label: 'Brieselang, Germany',
    lat: 52.588357,
    lon: 12.9958846,
    value: '52.588357,12.9958846',
  },
  {
    label: 'Brisbane, Australia',
    lat: -27.4689682,
    lon: 153.0234991,
    value: '-27.4689682,153.0234991',
  },
  {
    label: 'Brisbane, California, United States',
    lat: 37.687165,
    lon: -122.402794,
    value: '37.687165,-122.402794',
  },
  {
    label: 'Bristol, Connecticut, United States',
    lat: 41.6735209,
    lon: -72.9464859,
    value: '41.6735209,-72.9464859',
  },
  {
    label: 'Bristol, Indiana, United States',
    lat: 41.7214415,
    lon: -85.8175361,
    value: '41.7214415,-85.8175361',
  },
  {
    label: 'Bristol, United Kingdom',
    lat: 51.4538022,
    lon: -2.5972985,
    value: '51.4538022,-2.5972985',
  },
  {
    label: 'Bristol, Virginia, United States',
    lat: 36.5959685,
    lon: -82.1885009,
    value: '36.5959685,-82.1885009',
  },
  {
    label: 'Bronx, New York, United States',
    lat: 40.8466508,
    lon: -73.8785937,
    value: '40.8466508,-73.8785937',
  },
  {
    label: 'Brooklyn Park, Minnesota, United States',
    lat: 45.1004807,
    lon: -93.3443585,
    value: '45.1004807,-93.3443585',
  },
  {
    label: 'Brooklyn, New York, United States',
    lat: 40.6526006,
    lon: -73.9497211,
    value: '40.6526006,-73.9497211',
  },
  {
    label: 'Brooklyn, Ohio, United States',
    lat: 41.439754,
    lon: -81.7405559,
    value: '41.439754,-81.7405559',
  },
  {
    label: 'Brookshire, Texas, United States',
    lat: 29.7860643,
    lon: -95.9510661,
    value: '29.7860643,-95.9510661',
  },
  {
    label: 'Broomall, Pennsylvania, United States',
    lat: 39.9815001,
    lon: -75.3565797,
    value: '39.9815001,-75.3565797',
  },
  {
    label: 'Broomfield, Colorado, United States',
    lat: 39.9403995,
    lon: -105.05208,
    value: '39.9403995,-105.05208',
  },
  {
    label: 'Brownstown, Michigan, United States',
    lat: 42.1529228,
    lon: -83.2753247,
    value: '42.1529228,-83.2753247',
  },
  {
    label: 'Brussels, Belgium',
    lat: 50.8465573,
    lon: 4.351697,
    value: '50.8465573,4.351697',
  },
  {
    label: 'Bucharest, Romania',
    lat: 44.4361414,
    lon: 26.1027202,
    value: '44.4361414,26.1027202',
  },
  {
    label: 'Buda, Texas, United States',
    lat: 30.082475,
    lon: -97.8417144,
    value: '30.082475,-97.8417144',
  },
  {
    label: 'Budd Lake, New Jersey, United States',
    lat: 40.8712101,
    lon: -74.7340523,
    value: '40.8712101,-74.7340523',
  },
  {
    label: 'Buehl, Germany',
    lat: 48.6945066,
    lon: 8.134423,
    value: '48.6945066,8.134423',
  },
  {
    label: 'Buena Park, California, United States',
    lat: 33.870413,
    lon: -117.9962165,
    value: '33.870413,-117.9962165',
  },
  {
    label: 'Buenos Aires, Argentina',
    lat: -34.6037181,
    lon: -58.38153,
    value: '-34.6037181,-58.38153',
  },
  {
    label: 'Buerstadt, Germany',
    lat: 49.6531823,
    lon: 8.4747076,
    value: '49.6531823,8.4747076',
  },
  {
    label: 'Buford, Georgia, United States',
    lat: 34.1206564,
    lon: -84.0043513,
    value: '34.1206564,-84.0043513',
  },
  // {
  //   "label": "Bulandshaher, Uttar Pradesh, India",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Burago di Molgora, Italy',
    lat: 45.5966599,
    lon: 9.3777061,
    value: '45.5966599,9.3777061',
  },
  {
    label: 'Burbank, California, United States',
    lat: 34.1812089,
    lon: -118.307201,
    value: '34.1812089,-118.307201',
  },
  {
    label: 'Burlington, New Jersey, United States',
    lat: 40.0712218,
    lon: -74.8648859,
    value: '40.0712218,-74.8648859',
  },
  {
    label: 'Burnaby, Canada',
    lat: 49.2433804,
    lon: -122.972545,
    value: '49.2433804,-122.972545',
  },
  {
    label: 'Burton-on-Trent, United Kingdom',
    lat: 52.802742,
    lon: -1.629917,
    value: '52.802742,-1.629917',
  },
  {
    label: 'Bury St. Edmunds, United Kingdom',
    lat: 52.2460367,
    lon: 0.7125173,
    value: '52.2460367,0.7125173',
  },
  {
    label: 'Bussy-Lettree, France',
    lat: 48.80464,
    lon: 4.25947,
    value: '48.80464,4.25947',
  },
  {
    label: 'Byhalia, Mississippi, United States',
    lat: 34.8723179,
    lon: -89.6906396,
    value: '34.8723179,-89.6906396',
  },
  {
    label: 'CA, California, United States',
    lat: 37.7834355,
    lon: -122.4924406,
    value: '37.7834355,-122.4924406',
  },
  {
    label: 'Cagliari, Italy',
    lat: 39.2171994,
    lon: 9.113311,
    value: '39.2171994,9.113311',
  },
  {
    label: 'Cairo, Egypt',
    lat: 30.0443879,
    lon: 31.2357257,
    value: '30.0443879,31.2357257',
  },
  {
    label: 'Cajamar, Brazil',
    lat: -23.3561111,
    lon: -46.8769444,
    value: '-23.3561111,-46.8769444',
  },
  {
    label: 'Calais, France',
    lat: 50.9524769,
    lon: 1.8538446,
    value: '50.9524769,1.8538446',
  },
  {
    label: 'Calderara di Reno, Italy',
    lat: 44.5630515,
    lon: 11.271693,
    value: '44.5630515,11.271693',
  },
  {
    label: 'Caledonia, Michigan, United States',
    lat: 42.7891193,
    lon: -85.5163391,
    value: '42.7891193,-85.5163391',
  },
  {
    label: 'Calenzano, Italy',
    lat: 43.8652493,
    lon: 11.1673685,
    value: '43.8652493,11.1673685',
  },
  {
    label: 'Calgary, Canada',
    lat: 51.0456064,
    lon: -114.057541,
    value: '51.0456064,-114.057541',
  },
  {
    label: 'Calicut, Kerala, India',
    lat: 11.2450558,
    lon: 75.7754716,
    value: '11.2450558,75.7754716',
  },
  {
    label: 'Camarillo, California, United States',
    lat: 34.2176371,
    lon: -119.0383541,
    value: '34.2176371,-119.0383541',
  },
  {
    label: 'Cambridge, Canada',
    lat: 43.3600536,
    lon: -80.3123023,
    value: '43.3600536,-80.3123023',
  },
  {
    label: 'Cambridge, Massachusetts, United States',
    lat: 42.3655767,
    lon: -71.1040018,
    value: '42.3655767,-71.1040018',
  },
  {
    label: 'Cambridge, United Kingdom',
    lat: 52.2055314,
    lon: 0.1186637,
    value: '52.2055314,0.1186637',
  },
  {
    label: 'Camerano, Italy',
    lat: 43.5312679,
    lon: 13.5515741,
    value: '43.5312679,13.5515741',
  },
  {
    label: 'Campbellsville, Kentucky, United States',
    lat: 37.3433974,
    lon: -85.3419069,
    value: '37.3433974,-85.3419069',
  },
  {
    label: 'Canberra, Australia',
    lat: -35.2975906,
    lon: 149.1012676,
    value: '-35.2975906,149.1012676',
  },
  {
    label: 'Cancún, Mexico',
    lat: 21.1618955,
    lon: -86.8514988,
    value: '21.1618955,-86.8514988',
  },
  {
    label: 'Canton, Massachusetts, United States',
    lat: 42.1584324,
    lon: -71.1447732,
    value: '42.1584324,-71.1447732',
  },
  {
    label: 'Canton, Michigan, United States',
    lat: 42.3076547,
    lon: -83.4868961,
    value: '42.3076547,-83.4868961',
  },
  {
    label: 'Canton, Mississippi, United States',
    lat: 32.612602,
    lon: -90.0367512,
    value: '32.612602,-90.0367512',
  },
  {
    label: 'Canton, Ohio, United States',
    lat: 40.7985464,
    lon: -81.3749508,
    value: '40.7985464,-81.3749508',
  },
  {
    label: 'Cape Town, South Africa',
    lat: -33.928992,
    lon: 18.417396,
    value: '-33.928992,18.417396',
  },
  {
    label: 'Cardiff, United Kingdom',
    lat: 51.4816546,
    lon: -3.1791934,
    value: '51.4816546,-3.1791934',
  },
  {
    label: 'Carencro, Louisiana, United States',
    lat: 30.317144,
    lon: -92.0490096,
    value: '30.317144,-92.0490096',
  },
  {
    label: 'Carini, Italy',
    lat: 38.131172,
    lon: 13.1818847,
    value: '38.131172,13.1818847',
  },
  {
    label: 'Carle Place, New York, United States',
    lat: 40.7526008,
    lon: -73.6104058,
    value: '40.7526008,-73.6104058',
  },
  {
    label: 'Carlisle, Pennsylvania, United States',
    lat: 40.201499,
    lon: -77.1890783,
    value: '40.201499,-77.1890783',
  },
  {
    label: 'Carlisle, United Kingdom',
    lat: 54.8948478,
    lon: -2.9362311,
    value: '54.8948478,-2.9362311',
  },
  {
    label: 'Carlstadt, New Jersey, United States',
    lat: 40.8403778,
    lon: -74.0906974,
    value: '40.8403778,-74.0906974',
  },
  {
    label: 'Carrollton, Texas, United States',
    lat: 32.9537349,
    lon: -96.8902816,
    value: '32.9537349,-96.8902816',
  },
  {
    label: 'Carros, France',
    lat: 43.7846,
    lon: 7.18785,
    value: '43.7846,7.18785',
  },
  {
    label: 'Carteret, New Jersey, United States',
    lat: 40.5773266,
    lon: -74.2282005,
    value: '40.5773266,-74.2282005',
  },
  {
    label: 'Cary, North Carolina, United States',
    lat: 35.7882893,
    lon: -78.7812081,
    value: '35.7882893,-78.7812081',
  },
  {
    label: 'Casale Corte Cerro, Italy',
    lat: 45.9158429,
    lon: 8.4136779,
    value: '45.9158429,8.4136779',
  },
  {
    label: 'Casei Gerola, Italy',
    lat: 45.0061458,
    lon: 8.9269892,
    value: '45.0061458,8.9269892',
  },
  {
    label: 'Casirate dAdda, Italy',
    lat: 41.817315,
    lon: 12.625839,
    value: '41.817315,12.625839',
  },
  {
    label: 'Castegnato, Italy',
    lat: 45.5607142,
    lon: 10.1153088,
    value: '45.5607142,10.1153088',
  },
  {
    label: 'Castel San Giovanni, Italy',
    lat: 45.0590671,
    lon: 9.4341783,
    value: '45.0590671,9.4341783',
  },
  {
    label: 'Castellbisbal, Spain',
    lat: 41.4765136,
    lon: 1.9832978,
    value: '41.4765136,1.9832978',
  },
  {
    label: 'Castellon, Spain',
    lat: 42.7116655,
    lon: -1.1084148,
    value: '42.7116655,-1.1084148',
  },
  {
    label: 'Castleton, New York, United States',
    lat: 42.5308134,
    lon: -73.7553018,
    value: '42.5308134,-73.7553018',
  },
  {
    label: 'Catania, Italy',
    lat: 37.5023612,
    lon: 15.0873718,
    value: '37.5023612,15.0873718',
  },
  {
    label: 'Cathedral City, California, United States',
    lat: 33.7810031,
    lon: -116.464076,
    value: '33.7810031,-116.464076',
  },
  {
    label: 'Causeway Bay, Hong Kong',
    lat: 22.38333,
    lon: 114.18333,
    value: '22.38333,114.18333',
  },
  {
    label: 'Cavan, Australia',
    lat: -35.0130556,
    lon: 148.8269444,
    value: '-35.0130556,148.8269444',
  },
  {
    label: 'Cdmx, Mexico',
    lat: 19.4189277,
    lon: -99.0790216,
    value: '19.4189277,-99.0790216',
  },
  {
    label: 'Cebu, Philippines',
    lat: 16.7552303,
    lon: 121.5950704,
    value: '16.7552303,121.5950704',
  },
  {
    label: 'Centerville, Minnesota, United States',
    lat: 45.1630223,
    lon: -93.0557757,
    value: '45.1630223,-93.0557757',
  },
  {
    label: 'Cerritos, California, United States',
    lat: 33.8644291,
    lon: -118.0539323,
    value: '33.8644291,-118.0539323',
  },
  {
    label: 'Cesena, Italy',
    lat: 44.136352,
    lon: 12.2422442,
    value: '44.136352,12.2422442',
  },
  {
    label: 'Champaign, Illinois, United States',
    lat: 40.1164841,
    lon: -88.2430932,
    value: '40.1164841,-88.2430932',
  },
  {
    label: 'Chandigarh, Chandigarh, India',
    lat: 30.7334421,
    lon: 76.7797143,
    value: '30.7334421,76.7797143',
  },
  {
    label: 'Chandigarh, Punjab, India',
    lat: 27.6117269,
    lon: 76.837424,
    value: '27.6117269,76.837424',
  },
  {
    label: 'Chandler, Arizona, United States',
    lat: 33.3062031,
    lon: -111.841185,
    value: '33.3062031,-111.841185',
  },
  {
    label: 'Channahon, Illinois, United States',
    lat: 41.4294753,
    lon: -88.2286735,
    value: '41.4294753,-88.2286735',
  },
  {
    label: 'Chantilly, Virginia, United States',
    lat: 38.8941543,
    lon: -77.4311513,
    value: '38.8941543,-77.4311513',
  },
  {
    label: 'Charleroi, Belgium',
    lat: 50.4116233,
    lon: 4.444528,
    value: '50.4116233,4.444528',
  },
  {
    label: 'Charleston, South Carolina, United States',
    lat: 32.7884363,
    lon: -79.9399309,
    value: '32.7884363,-79.9399309',
  },
  {
    label: 'Charleston, Tennessee, United States',
    lat: 35.2858415,
    lon: -84.7575018,
    value: '35.2858415,-84.7575018',
  },
  {
    label: 'Charlotte, North Carolina, United States',
    lat: 35.2272086,
    lon: -80.8430827,
    value: '35.2272086,-80.8430827',
  },
  {
    label: 'Chatsworth, California, United States',
    lat: 34.2595715,
    lon: -118.6023247,
    value: '34.2595715,-118.6023247',
  },
  {
    label: 'Chattanooga, Tennessee, United States',
    lat: 35.0457219,
    lon: -85.3094883,
    value: '35.0457219,-85.3094883',
  },
  {
    label: 'Chengdu, China',
    lat: 30.6598628,
    lon: 104.0633717,
    value: '30.6598628,104.0633717',
  },
  {
    label: 'Chennai, TN, India',
    lat: 13.0836939,
    lon: 80.270186,
    value: '13.0836939,80.270186',
  },
  {
    label: 'Chesapeake, Virginia, United States',
    lat: 36.7183708,
    lon: -76.2466798,
    value: '36.7183708,-76.2466798',
  },
  {
    label: 'Chester, Virginia, United States',
    lat: 40.6131203,
    lon: -80.5628478,
    value: '40.6131203,-80.5628478',
  },
  {
    label: 'Chevy Chase, Maryland, United States',
    lat: 38.9942121,
    lon: -77.0771572,
    value: '38.9942121,-77.0771572',
  },
  {
    label: 'Chhapra, Bihar, India',
    lat: 25.7784105,
    lon: 84.7514507,
    value: '25.7784105,84.7514507',
  },
  {
    label: 'Chhindwara, Madhya Pradesh, India',
    lat: 22.0578244,
    lon: 78.9386203,
    value: '22.0578244,78.9386203',
  },
  {
    label: 'Chiba, Japan',
    lat: 35.549399,
    lon: 140.2647303,
    value: '35.549399,140.2647303',
  },
  {
    label: 'Chicago, Illinois, United States',
    lat: 41.8755616,
    lon: -87.6244212,
    value: '41.8755616,-87.6244212',
  },
  {
    label: 'Chico, California, United States',
    lat: 39.7284945,
    lon: -121.8374777,
    value: '39.7284945,-121.8374777',
  },
  {
    label: 'Chigasaki, Japan',
    lat: 35.329479,
    lon: 139.405371,
    value: '35.329479,139.405371',
  },
  {
    label: 'Chihuahua, Mexico',
    lat: 25.5434769,
    lon: -99.9552241,
    value: '25.5434769,-99.9552241',
  },
  {
    label: 'Chino, California, United States',
    lat: 34.0133561,
    lon: -117.690075,
    value: '34.0133561,-117.690075',
  },
  {
    label: 'Chittor, Andhra Pradesh, India',
    lat: 13.2178147,
    lon: 79.0929361,
    value: '13.2178147,79.0929361',
  },
  {
    label: 'Chula Vista, California, United States',
    lat: 32.6400541,
    lon: -117.084195,
    value: '32.6400541,-117.084195',
  },
  {
    label: 'Cicero, Illinois, United States',
    lat: 41.8455398,
    lon: -87.7540199,
    value: '41.8455398,-87.7540199',
  },
  {
    label: 'Cincinnati, Kentucky, United States',
    lat: 38.4008813,
    lon: -88.511995,
    value: '38.4008813,-88.511995',
  },
  {
    label: 'Cincinnati, Ohio, United States',
    lat: 39.1014537,
    lon: -84.5124602,
    value: '39.1014537,-84.5124602',
  },
  {
    label: 'Cisterna di Latina, Italy',
    lat: 41.590639,
    lon: 12.829048,
    value: '41.590639,12.829048',
  },
  {
    label: 'City of Industry, California, United States',
    lat: 34.0182252,
    lon: -117.9593061,
    value: '34.0182252,-117.9593061',
  },
  {
    label: 'Ciudad de México, Mexico',
    lat: 19.3997047,
    lon: -99.1248161,
    value: '19.3997047,-99.1248161',
  },
  {
    label: 'Cividate Al Piano, Italy',
    lat: 45.554879,
    lon: 9.83096,
    value: '45.554879,9.83096',
  },
  {
    label: 'Clear Brook, Virginia, United States',
    lat: 39.2564912,
    lon: -78.096111,
    value: '39.2564912,-78.096111',
  },
  {
    label: 'Clearwater, Florida, United States',
    lat: 27.9658533,
    lon: -82.8001026,
    value: '27.9658533,-82.8001026',
  },
  {
    label: 'Cleveland, Ohio, United States',
    lat: 41.4996574,
    lon: -81.6936772,
    value: '41.4996574,-81.6936772',
  },
  {
    label: 'Clichy, France',
    lat: 48.9026,
    lon: 2.30551,
    value: '48.9026,2.30551',
  },
  {
    label: 'Cloppenburg, Germany',
    lat: 52.8461339,
    lon: 8.0438783,
    value: '52.8461339,8.0438783',
  },
  {
    label: 'Coalville, United Kingdom',
    lat: 52.7257981,
    lon: -1.3741375,
    value: '52.7257981,-1.3741375',
  },
  {
    label: 'Cochin, Kerala, India',
    lat: 9.980064500000001,
    lon: 76.23383649,
    value: '9.980064500000001,76.2338364919834',
  },
  {
    label: 'Cohoes, New York, United States',
    lat: 42.7742446,
    lon: -73.7001187,
    value: '42.7742446,-73.7001187',
  },
  {
    label: 'Coimbatore, TN, India',
    lat: 11.0018115,
    lon: 76.9628425,
    value: '11.0018115,76.9628425',
  },
  {
    label: 'Colfax, North Carolina, United States',
    lat: 36.1126365,
    lon: -80.0144842,
    value: '36.1126365,-80.0144842',
  },
  {
    label: 'Colleferro, Italy',
    lat: 41.727033,
    lon: 13.0041576,
    value: '41.727033,13.0041576',
  },
  // {
  //   "label": "Cologne_Bochum, Germany",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Cologne, Germany',
    lat: 50.938361,
    lon: 6.959974,
    value: '50.938361,6.959974',
  },
  {
    label: 'Colonie, New York, United States',
    lat: 42.7178558,
    lon: -73.8334554,
    value: '42.7178558,-73.8334554',
  },
  {
    label: 'Colorado Springs, Colorado, United States',
    lat: 38.8339578,
    lon: -104.825348,
    value: '38.8339578,-104.825348',
  },
  {
    label: 'Columbia, Maryland, United States',
    lat: 39.2156213,
    lon: -76.8582049,
    value: '39.2156213,-76.8582049',
  },
  {
    label: 'Columbus, Ohio, United States',
    lat: 39.9622601,
    lon: -83.0007065,
    value: '39.9622601,-83.0007065',
  },
  {
    label: 'Combs-la-Ville, France',
    lat: 48.6646994,
    lon: 2.564877,
    value: '48.6646994,2.564877',
  },
  {
    label: 'Commerce City, Colorado, United States',
    lat: 39.8399565,
    lon: -104.9216416,
    value: '39.8399565,-104.921641585114',
  },
  {
    label: 'Commerce, California, United States',
    lat: 34.003218,
    lon: -118.1541961,
    value: '34.003218,-118.1541961',
  },
  {
    label: 'Concord, California, United States',
    lat: 37.9768525,
    lon: -122.0335624,
    value: '37.9768525,-122.0335624',
  },
  {
    label: 'Concord, North Carolina, United States',
    lat: 35.4094178,
    lon: -80.5800049,
    value: '35.4094178,-80.5800049',
  },
  {
    label: 'Conklin, New York, United States',
    lat: 42.0342437,
    lon: -75.803801,
    value: '42.0342437,-75.803801',
  },
  {
    label: 'Conshohocken, Pennsylvania, United States',
    lat: 40.0792766,
    lon: -75.3015714,
    value: '40.0792766,-75.3015714',
  },
  {
    label: 'Copenhagen, Denmark',
    lat: 55.6867243,
    lon: 12.5700724,
    value: '55.6867243,12.5700724',
  },
  {
    label: 'Coppell, Texas, United States',
    lat: 32.9552598,
    lon: -97.0155703,
    value: '32.9552598,-97.0155703',
  },
  {
    label: 'Coraopolis, Pennsylvania, United States',
    lat: 40.5184013,
    lon: -80.1667247,
    value: '40.5184013,-80.1667247',
  },
  {
    label: 'Cork, Ireland',
    lat: 51.897077,
    lon: -8.4654674,
    value: '51.897077,-8.4654674',
  },
  {
    label: 'Corona, California, United States',
    lat: 33.8752945,
    lon: -117.566444,
    value: '33.8752945,-117.566444',
  },
  {
    label: 'Corona, New York, United States',
    lat: 40.7469593,
    lon: -73.8601456,
    value: '40.7469593,-73.8601456',
  },
  {
    label: 'Corpus Christi, Texas, United States',
    lat: 27.7635302,
    lon: -97.4033191,
    value: '27.7635302,-97.4033191',
  },
  {
    label: 'Coslada, Spain',
    lat: 40.423802,
    lon: -3.5552882,
    value: '40.423802,-3.5552882',
  },
  {
    label: 'Coteau-du-Lac, Canada',
    lat: 45.2963957,
    lon: -74.1782005,
    value: '45.2963957,-74.1782005',
  },
  {
    label: 'Country Club Hills, Illinois, United States',
    lat: 41.5680898,
    lon: -87.7203257,
    value: '41.5680898,-87.7203257',
  },
  {
    label: 'Courbevoie, France',
    lat: 48.8953328,
    lon: 2.2561602,
    value: '48.8953328,2.2561602',
  },
  {
    label: 'Coventry, United Kingdom',
    lat: 52.4081812,
    lon: -1.510477,
    value: '52.4081812,-1.510477',
  },
  {
    label: 'Cranbury, New Jersey, United States',
    lat: 40.3120265,
    lon: -74.5158287,
    value: '40.3120265,-74.5158287',
  },
  {
    label: 'Crawley, United Kingdom',
    lat: 51.1103444,
    lon: -0.1801094,
    value: '51.1103444,-0.1801094',
  },
  {
    label: 'Crest Hill, Illinois, United States',
    lat: 41.554753,
    lon: -88.0986709,
    value: '41.554753,-88.0986709',
  },
  {
    label: 'Cromwell, Connecticut, United States',
    lat: 41.5949942,
    lon: -72.6455665,
    value: '41.5949942,-72.6455665',
  },
  {
    label: 'Crown Point, Indiana, United States',
    lat: 41.4169806,
    lon: -87.3653136,
    value: '41.4169806,-87.3653136',
  },
  {
    label: 'Croydon, United Kingdom',
    lat: 51.3713049,
    lon: -0.101957,
    value: '51.3713049,-0.101957',
  },
  {
    label: 'Cuauhtémoc, Mexico',
    lat: 19.4416128,
    lon: -99.1518637,
    value: '19.4416128,-99.1518637',
  },
  {
    label: 'Cuautitlan Izcalli, Mexico',
    lat: 19.6531356,
    lon: -99.2145002,
    value: '19.6531356,-99.2145002',
  },
  {
    label: 'Cuddapah, Andhra Pradesh, India',
    lat: 14.4956602,
    lon: 78.8349278,
    value: '14.4956602,78.8349278',
  },
  {
    label: 'Culiacán, Mexico',
    lat: 24.7978965,
    lon: -107.393395,
    value: '24.7978965,-107.393395',
  },
  {
    label: 'Culpeper, Virginia, United States',
    lat: 38.4733823,
    lon: -77.9961275,
    value: '38.4733823,-77.9961275',
  },
  {
    label: 'Culver City, California, United States',
    lat: 34.0211224,
    lon: -118.396466,
    value: '34.0211224,-118.396466',
  },
  {
    label: 'Cupertino, California, United States',
    lat: 37.3228934,
    lon: -122.0322895,
    value: '37.3228934,-122.0322895',
  },
  {
    label: 'Curitiba, Brazil',
    lat: -25.4295963,
    lon: -49.2712724,
    value: '-25.4295963,-49.2712724',
  },
  {
    label: 'Cuttack, OR, India',
    lat: 20.4686,
    lon: 85.8792,
    value: '20.4686,85.8792',
  },
  {
    label: 'Cypress, California, United States',
    lat: 33.8248235,
    lon: -118.0399368,
    value: '33.8248235,-118.0399368',
  },
  {
    label: 'Daito, Japan',
    lat: 34.710679,
    lon: 135.635478,
    value: '34.710679,135.635478',
  },
  {
    label: 'Daleville, Alabama, United States',
    lat: 31.3101719,
    lon: -85.7129908,
    value: '31.3101719,-85.7129908',
  },
  {
    label: 'Dalian, China',
    lat: 38.9181714,
    lon: 121.6282945,
    value: '38.9181714,121.6282945',
  },
  {
    label: 'Dallas, Texas, United States',
    lat: 32.7762719,
    lon: -96.7968559,
    value: '32.7762719,-96.7968559',
  },
  {
    label: 'Damanhour, Egypt',
    lat: 31.0374982,
    lon: 30.4710625,
    value: '31.0374982,30.4710625',
  },
  {
    label: 'Dammam, Saudi Arabia',
    lat: 26.4367824,
    lon: 50.1039991,
    value: '26.4367824,50.1039991',
  },
  {
    label: 'Danbury, Connecticut, United States',
    lat: 41.394817,
    lon: -73.4540111,
    value: '41.394817,-73.4540111',
  },
  {
    label: 'Dandenong South, Australia',
    lat: -38.0279936,
    lon: 145.2209236,
    value: '-38.0279936,145.2209236',
  },
  {
    label: 'Darbhanga, Bihar, India',
    lat: 26.156999,
    lon: 85.8995065,
    value: '26.156999,85.8995065',
  },
  {
    label: 'Dartford, United Kingdom',
    lat: 51.4443059,
    lon: 0.21807,
    value: '51.4443059,0.21807',
  },
  {
    label: 'Dartmouth, Canada',
    lat: 44.6799707,
    lon: -63.5720232,
    value: '44.6799707,-63.5720232',
  },
  {
    label: 'Davenport, Florida, United States',
    lat: 28.1614046,
    lon: -81.6017417,
    value: '28.1614046,-81.6017417',
  },
  {
    label: 'Davenport, Iowa, United States',
    lat: 41.5235808,
    lon: -90.5770967,
    value: '41.5235808,-90.5770967',
  },
  {
    label: 'Daventry, United Kingdom',
    lat: 52.2578681,
    lon: -1.1626569,
    value: '52.2578681,-1.1626569',
  },
  {
    label: 'Daytona Beach, Florida, United States',
    lat: 29.2108147,
    lon: -81.0228331,
    value: '29.2108147,-81.0228331',
  },
  {
    label: 'Dedham, Massachusetts, United States',
    lat: 42.2489143,
    lon: -71.1755732,
    value: '42.2489143,-71.1755732',
  },
  {
    label: 'Deerfield, Florida, United States',
    lat: 42.5442595,
    lon: -72.6044771,
    value: '42.5442595,-72.6044771',
  },
  {
    label: 'Deeside, United Kingdom',
    lat: 57.0729339,
    lon: -2.7891826,
    value: '57.0729339,-2.7891826',
  },
  {
    label: 'Dehradun, UT, India',
    lat: 30.3255646,
    lon: 78.0436813,
    value: '30.3255646,78.0436813',
  },
  {
    label: 'Delhi, Delhi, India',
    lat: 28.6138954,
    lon: 77.2090057,
    value: '28.6138954,77.2090057',
  },
  {
    label: 'Delta, Canada',
    lat: 49.0846258,
    lon: -123.057938,
    value: '49.0846258,-123.057938',
  },
  {
    label: 'Deltona, Florida, United States',
    lat: 28.904843,
    lon: -81.2273119,
    value: '28.904843,-81.2273119',
  },
  {
    label: 'Den Haag, Netherlands',
    lat: 52.0799838,
    lon: 4.3113461,
    value: '52.0799838,4.3113461',
  },
  {
    label: 'Denver, Colorado, United States',
    lat: 39.7392364,
    lon: -104.984862,
    value: '39.7392364,-104.984862',
  },
  {
    label: 'Deoria, Uttar Pradesh, India',
    lat: 26.4998975,
    lon: 83.7829787,
    value: '26.4998975,83.7829787',
  },
  {
    label: 'Detroit, Michigan, United States',
    lat: 42.3315509,
    lon: -83.0466403,
    value: '42.3315509,-83.0466403',
  },
  {
    label: 'DFW Airport, Texas, United States',
    lat: 32.89651945,
    lon: -97.04652205,
    value: '32.89651945,-97.0465220537124',
  },
  {
    label: 'Dharamshala, Himachal Pradesh, India',
    lat: 32.2143039,
    lon: 76.3196717,
    value: '32.2143039,76.3196717',
  },
  {
    label: 'Dibrugarh, Assam, India',
    lat: 27.4844597,
    lon: 94.9019447,
    value: '27.4844597,94.9019447',
  },
  {
    label: 'Dindigul, TN, India',
    lat: 10.365646,
    lon: 77.9693256,
    value: '10.365646,77.9693256',
  },
  {
    label: 'Diss. Unnao, Uttar Pradesh, India',
    lat: 26.532613,
    lon: 80.481301,
    value: '26.532613,80.481301',
  },
  {
    label: 'Dobroviz, Czech Republic',
    lat: 50.1130584,
    lon: 14.2177331,
    value: '50.1130584,14.2177331',
  },
  {
    label: 'Dombivili, Maharashtra, India',
    lat: 19.405779,
    lon: 75.70204,
    value: '19.405779,75.70204',
  },
  {
    label: 'Doncaster, United Kingdom',
    lat: 53.5227681,
    lon: -1.1335312,
    value: '53.5227681,-1.1335312',
  },
  {
    label: 'Doraville, Georgia, United States',
    lat: 33.8981579,
    lon: -84.2832564,
    value: '33.8981579,-84.2832564',
  },
  {
    label: 'Dortmund, Germany',
    lat: 51.5142273,
    lon: 7.4652789,
    value: '51.5142273,7.4652789',
  },
  {
    label: 'Dos Hermanas, Spain',
    lat: 37.283689,
    lon: -5.9226718,
    value: '37.283689,-5.9226718',
  },
  {
    label: 'Downers Grove, Illinois, United States',
    lat: 41.7936822,
    lon: -88.0102281,
    value: '41.7936822,-88.0102281',
  },
  {
    label: 'Dresden, Germany',
    lat: 51.0493286,
    lon: 13.7381437,
    value: '51.0493286,13.7381437',
  },
  {
    label: 'Dubai, United Arab Emirates',
    lat: 25.2653471,
    lon: 55.2924914,
    value: '25.2653471,55.2924914',
  },
  {
    label: 'Dublin, California, United States',
    lat: 37.7021521,
    lon: -121.9357918,
    value: '37.7021521,-121.9357918',
  },
  {
    label: 'Dublin, Ireland',
    lat: 53.3493795,
    lon: -6.2605593,
    value: '53.3493795,-6.2605593',
  },
  {
    label: 'Dublin, Ohio, United States',
    lat: 40.0992294,
    lon: -83.1140771,
    value: '40.0992294,-83.1140771',
  },
  {
    label: 'Dubuque, Iowa, United States',
    lat: 42.5006243,
    lon: -90.6647985,
    value: '42.5006243,-90.6647985',
  },
  {
    label: 'Duisburg, Germany',
    lat: 51.434999,
    lon: 6.759562,
    value: '51.434999,6.759562',
  },
  {
    label: 'Duluth, Georgia, United States',
    lat: 34.0028569,
    lon: -84.1441046,
    value: '34.0028569,-84.1441046',
  },
  // {
  //   "label": "Dumait, Egypt",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Dummerstorf, Germany',
    lat: 54.0131361,
    lon: 12.2281926,
    value: '54.0131361,12.2281926',
  },
  {
    label: 'Dundee, United Kingdom',
    lat: 56.4605938,
    lon: -2.97019,
    value: '56.4605938,-2.97019',
  },
  {
    label: 'Dunfermline, United Kingdom',
    lat: 56.0713724,
    lon: -3.4616183,
    value: '56.0713724,-3.4616183',
  },
  {
    label: 'Dunstable, United Kingdom',
    lat: 51.8861316,
    lon: -0.5209341,
    value: '51.8861316,-0.5209341',
  },
  {
    label: 'DuPont, Washington, United States',
    lat: 47.0990689,
    lon: -122.637546,
    value: '47.0990689,-122.637546',
  },
  {
    label: 'Durgapur, West Bengal, India',
    lat: 23.5350475,
    lon: 87.3380425,
    value: '23.5350475,87.3380425',
  },
  {
    label: 'Durham, North Carolina, United States',
    lat: 35.996653,
    lon: -78.9018053,
    value: '35.996653,-78.9018053',
  },
  {
    label: 'Dusseldorf, Germany',
    lat: 51.2254018,
    lon: 6.7763137,
    value: '51.2254018,6.7763137',
  },
  {
    label: 'Eagan, Minnesota, United States',
    lat: 44.818173,
    lon: -93.1659179,
    value: '44.818173,-93.1659179',
  },
  {
    label: 'East Palo Alto, California, United States',
    lat: 37.4688273,
    lon: -122.141075,
    value: '37.4688273,-122.141075',
  },
  {
    label: 'East Point, Georgia, United States',
    lat: 33.6795531,
    lon: -84.4393724,
    value: '33.6795531,-84.4393724',
  },
  {
    label: 'East Syracuse, New York, United States',
    lat: 43.0653446,
    lon: -76.0785332,
    value: '43.0653446,-76.0785332',
  },
  {
    label: 'East Taunton, Massachusetts, United States',
    lat: 41.8834347,
    lon: -71.0286543,
    value: '41.8834347,-71.0286543',
  },
  {
    label: 'Eastern Creek, Australia',
    lat: -33.808261,
    lon: 150.860384,
    value: '-33.808261,150.860384',
  },
  {
    label: 'Easton, Pennsylvania, United States',
    lat: 40.6916081,
    lon: -75.2099866,
    value: '40.6916081,-75.2099866',
  },
  {
    label: 'Eastvale, California, United States',
    lat: 33.9747067,
    lon: -117.566541,
    value: '33.9747067,-117.566541',
  },
  {
    label: 'Eau Claire, Wisconsin, United States',
    lat: 44.811349,
    lon: -91.4984941,
    value: '44.811349,-91.4984941',
  },
  {
    label: 'Ebina, Japan',
    lat: 35.419984,
    lon: 139.389999,
    value: '35.419984,139.389999',
  },
  {
    label: 'Edgewood, Maryland, United States',
    lat: 39.4187194,
    lon: -76.2944016,
    value: '39.4187194,-76.2944016',
  },
  {
    label: 'Edinburg, Virginia, United States',
    lat: 38.8209466,
    lon: -78.5658453,
    value: '38.8209466,-78.5658453',
  },
  {
    label: 'Edinburgh, United Kingdom',
    lat: 55.9533456,
    lon: -3.1883749,
    value: '55.9533456,-3.1883749',
  },
  {
    label: 'Edison, New Jersey, United States',
    lat: 40.518157,
    lon: -74.4113926,
    value: '40.518157,-74.4113926',
  },
  {
    label: 'Edmonton, Canada',
    lat: 53.5462055,
    lon: -113.491241,
    value: '53.5462055,-113.491241',
  },
  {
    label: 'Edwardsville, Illinois, United States',
    lat: 38.8114364,
    lon: -89.953157,
    value: '38.8114364,-89.953157',
  },
  {
    label: 'Eggolsheim, Germany',
    lat: 49.7721617,
    lon: 11.0535303,
    value: '49.7721617,11.0535303',
  },
  {
    label: 'Ehime, Japan',
    lat: 33.6013646,
    lon: 132.8185275,
    value: '33.6013646,132.8185275',
  },
  {
    label: 'El Paso, Texas, United States',
    lat: 31.7550511,
    lon: -106.488234,
    value: '31.7550511,-106.488234',
  },
  {
    label: 'El Salto, Mexico',
    lat: 19.8161544,
    lon: -99.8005343,
    value: '19.8161544,-99.8005343',
  },
  {
    label: 'El Segundo, California, United States',
    lat: 33.917028,
    lon: -118.4156337,
    value: '33.917028,-118.4156337',
  },
  {
    label: 'Elgin, Illinois, United States',
    lat: 42.03726,
    lon: -88.2810994,
    value: '42.03726,-88.2810994',
  },
  {
    label: 'Elizabeth, New Jersey, United States',
    lat: 40.6639916,
    lon: -74.2107006,
    value: '40.6639916,-74.2107006',
  },
  {
    label: 'Elizabethtown, Pennsylvania, United States',
    lat: 40.153364,
    lon: -76.6042521,
    value: '40.153364,-76.6042521',
  },
  {
    label: 'Elk Grove, California, United States',
    lat: 38.4087993,
    lon: -121.3716178,
    value: '38.4087993,-121.3716178',
  },
  {
    label: 'Elkridge, Maryland, United States',
    lat: 39.2128338,
    lon: -76.7137155,
    value: '39.2128338,-76.7137155',
  },
  {
    label: 'Elmsford, New York, United States',
    lat: 41.0550969,
    lon: -73.8201338,
    value: '41.0550969,-73.8201338',
  },
  {
    label: 'Elmwood, Louisiana, United States',
    lat: 29.9565932,
    lon: -90.1897975,
    value: '29.9565932,-90.1897975',
  },
  {
    label: 'Emden, Germany',
    lat: 53.3670541,
    lon: 7.2058304,
    value: '53.3670541,7.2058304',
  },
  {
    label: 'Emilia, Italy',
    lat: 44.591859,
    lon: 11.052755,
    value: '44.591859,11.052755',
  },
  {
    label: 'Emiliano Zapata, Mexico',
    lat: 20.3812948,
    lon: -97.1834439,
    value: '20.3812948,-97.1834439',
  },
  {
    label: 'Emsbueren, Germany',
    lat: 52.3927616,
    lon: 7.2959699,
    value: '52.3927616,7.2959699',
  },
  {
    label: 'Encino, California, United States',
    lat: 34.1591324,
    lon: -118.5016166,
    value: '34.1591324,-118.5016166',
  },
  {
    label: 'Englewood, Colorado, United States',
    lat: 39.6482059,
    lon: -104.9879641,
    value: '39.6482059,-104.9879641',
  },
  {
    label: 'Englewood, Ohio, United States',
    lat: 39.8775553,
    lon: -84.3021673,
    value: '39.8775553,-84.3021673',
  },
  {
    label: 'Enid, Oklahoma, United States',
    lat: 36.3967623,
    lon: -97.8791341,
    value: '36.3967623,-97.8791341',
  },
  {
    label: 'Erding, Germany',
    lat: 48.3064441,
    lon: 11.9076579,
    value: '48.3064441,11.9076579',
  },
  {
    label: 'Erfurt, Germany',
    lat: 50.9777974,
    lon: 11.0287364,
    value: '50.9777974,11.0287364',
  },
  {
    label: 'Erie, Pennsylvania, United States',
    lat: 42.1294712,
    lon: -80.0852695,
    value: '42.1294712,-80.0852695',
  },
  {
    label: 'Erlanger, Kentucky, United States',
    lat: 39.0167275,
    lon: -84.6007773,
    value: '39.0167275,-84.6007773',
  },
  {
    label: 'Ernakulam, Kerala, India',
    lat: 9.98408,
    lon: 76.2741457,
    value: '9.98408,76.2741457',
  },
  {
    label: 'Erode, TN, India',
    lat: 11.3306483,
    lon: 77.7276519,
    value: '11.3306483,77.7276519',
  },
  {
    label: 'Eschweiler, Germany',
    lat: 50.8175029,
    lon: 6.2630894,
    value: '50.8175029,6.2630894',
  },
  {
    label: 'Eskilstuna, Sweden',
    lat: 59.3717379,
    lon: 16.5051474,
    value: '59.3717379,16.5051474',
  },
  {
    label: 'Espanola, New Mexico, United States',
    lat: 35.9910962,
    lon: -106.080835,
    value: '35.9910962,-106.080835',
  },
  {
    label: 'Essen, Germany',
    lat: 51.4582235,
    lon: 7.0158171,
    value: '51.4582235,7.0158171',
  },
  {
    label: 'Etawah, Uttar Pradesh, India',
    lat: 26.7780674,
    lon: 79.0220015,
    value: '26.7780674,79.0220015',
  },
  {
    label: 'Etna, Ohio, United States',
    lat: 39.957562,
    lon: -82.6819548,
    value: '39.957562,-82.6819548',
  },
  {
    label: 'Etobicoke, Canada',
    lat: 43.6435559,
    lon: -79.5656326,
    value: '43.6435559,-79.5656326',
  },
  {
    label: 'Euclid, Ohio, United States',
    lat: 41.5931049,
    lon: -81.5267873,
    value: '41.5931049,-81.5267873',
  },
  {
    label: 'Euskirchen, Germany',
    lat: 50.66129,
    lon: 6.7871681,
    value: '50.66129,6.7871681',
  },
  {
    label: 'Evansville, Indiana, United States',
    lat: 37.970495,
    lon: -87.5715641,
    value: '37.970495,-87.5715641',
  },
  {
    label: 'Everett, Massachusetts, United States',
    lat: 42.40843,
    lon: -71.0536625,
    value: '42.40843,-71.0536625',
  },
  {
    label: 'Everett, Washington, United States',
    lat: 47.9793437,
    lon: -122.2127011,
    value: '47.9793437,-122.2127011',
  },
  {
    label: 'Exeter, United Kingdom',
    lat: 50.7255794,
    lon: -3.5269497,
    value: '50.7255794,-3.5269497',
  },
  {
    label: 'Fairburn, Georgia, United States',
    lat: 33.5670562,
    lon: -84.5810418,
    value: '33.5670562,-84.5810418',
  },
  {
    label: 'Fairfax, Virginia, United States',
    lat: 38.8462236,
    lon: -77.3063733,
    value: '38.8462236,-77.3063733',
  },
  {
    label: 'Fairless Hills, Pennsylvania, United States',
    lat: 40.1795537,
    lon: -74.8551644,
    value: '40.1795537,-74.8551644',
  },
  {
    label: 'Fairview, Oregon, United States',
    lat: 43.2168273,
    lon: -124.0737058,
    value: '43.2168273,-124.0737058',
  },
  {
    label: 'Fairview, Texas, United States',
    lat: 33.1578325,
    lon: -96.6318301,
    value: '33.1578325,-96.6318301',
  },
  {
    label: 'Faizabad, Uttar Pradesh, India',
    lat: 26.7789282,
    lon: 82.142694,
    value: '26.7789282,82.142694',
  },
  {
    label: 'Fall River, Massachusetts, United States',
    lat: 41.7010642,
    lon: -71.1546367,
    value: '41.7010642,-71.1546367',
  },
  {
    label: 'Fargo, North Dakota, United States',
    lat: 46.877229,
    lon: -96.789821,
    value: '46.877229,-96.789821',
  },
  {
    label: 'Faridabad, Haryana, India',
    lat: 28.4031478,
    lon: 77.3105561,
    value: '28.4031478,77.3105561',
  },
  {
    label: 'Farrukhabad, Uttar Pradesh, India',
    lat: 27.3895336,
    lon: 79.5790994,
    value: '27.3895336,79.5790994',
  },
  {
    label: 'Fatehpur, Uttar Pradesh, India',
    lat: 25.9291159,
    lon: 80.8105997,
    value: '25.9291159,80.8105997',
  },
  {
    label: 'Fayetteville, North Carolina, United States',
    lat: 35.0525759,
    lon: -78.878292,
    value: '35.0525759,-78.878292',
  },
  {
    label: 'Federal Way, Washington, United States',
    lat: 47.313494,
    lon: -122.33931,
    value: '47.313494,-122.33931',
  },
  {
    label: 'Fenton, Missouri, United States',
    lat: 38.5131989,
    lon: -90.4400578,
    value: '38.5131989,-90.4400578',
  },
  {
    label: 'Fife, Washington, United States',
    lat: 47.2359524,
    lon: -122.3626381,
    value: '47.2359524,-122.3626381',
  },
  {
    label: 'Findlay, Ohio, United States',
    lat: 41.0413873,
    lon: -83.6503982,
    value: '41.0413873,-83.6503982',
  },
  {
    label: 'Fishersville, Virginia, United States',
    lat: 38.1009639,
    lon: -78.9697305,
    value: '38.1009639,-78.9697305',
  },
  {
    label: 'FISHKILL, New York, United States',
    lat: 41.5355745,
    lon: -73.898702,
    value: '41.5355745,-73.898702',
  },
  {
    label: 'Fiume Veneto, Italy',
    lat: 45.9279427,
    lon: 12.7321755,
    value: '45.9279427,12.7321755',
  },
  {
    label: 'Florence, Kentucky, United States',
    lat: 38.9989499,
    lon: -84.6266111,
    value: '38.9989499,-84.6266111',
  },
  {
    label: 'Florence, New Jersey, United States',
    lat: 39.7342825,
    lon: -74.9182198,
    value: '39.7342825,-74.9182198',
  },
  {
    label: 'Fontaine, France',
    lat: 45.1950183,
    lon: 5.6869624,
    value: '45.1950183,5.6869624',
  },
  {
    label: 'Fontana, California, United States',
    lat: 34.0922947,
    lon: -117.43433,
    value: '34.0922947,-117.43433',
  },
  {
    label: 'Forest Park, Georgia, United States',
    lat: 33.6220542,
    lon: -84.3690918,
    value: '33.6220542,-84.3690918',
  },
  {
    label: 'Forney, Texas, United States',
    lat: 32.747893,
    lon: -96.4719289,
    value: '32.747893,-96.4719289',
  },
  {
    label: 'Fort Lauderdale, Florida, United States',
    lat: 26.1223084,
    lon: -80.1433786,
    value: '26.1223084,-80.1433786',
  },
  {
    label: 'Fort Meyers, Florida, United States',
    lat: 26.64030795,
    lon: -81.86419739,
    value: '26.64030795,-81.8641973878553',
  },
  {
    label: 'Fort Myers, Florida, United States',
    lat: 26.640628,
    lon: -81.8723084,
    value: '26.640628,-81.8723084',
  },
  {
    label: 'Fort Pierce, Florida, United States',
    lat: 27.4467056,
    lon: -80.3256056,
    value: '27.4467056,-80.3256056',
  },
  {
    label: 'Fort Wayne, Indiana, United States',
    lat: 41.0799898,
    lon: -85.1386015,
    value: '41.0799898,-85.1386015',
  },
  {
    label: 'Fort Worth, Texas, United States',
    lat: 32.753177,
    lon: -97.3327459,
    value: '32.753177,-97.3327459',
  },
  {
    label: 'Frailey Township, Pennsylvania, United States',
    lat: 40.6427311,
    lon: -76.40217489,
    value: '40.6427311,-76.4021748937438',
  },
  {
    label: 'Francolino di Carpiano, Italy',
    lat: 45.3466929,
    lon: 9.2816453,
    value: '45.3466929,9.2816453',
  },
  {
    label: 'Franconia, Virginia, United States',
    lat: 38.7819762,
    lon: -77.1461045,
    value: '38.7819762,-77.1461045',
  },
  {
    label: 'Frankenthal, Germany',
    lat: 51.1309869,
    lon: 14.1060795,
    value: '51.1309869,14.1060795',
  },
  {
    label: 'Frankfort, New York, United States',
    lat: 43.038959,
    lon: -75.070436,
    value: '43.038959,-75.070436',
  },
  {
    label: 'Frankfurt, Germany',
    lat: 50.1106444,
    lon: 8.6820917,
    value: '50.1106444,8.6820917',
  },
  {
    label: 'Franklin, Indiana, United States',
    lat: 39.4806056,
    lon: -86.0549863,
    value: '39.4806056,-86.0549863',
  },
  {
    label: 'Franklin, North Carolina, United States',
    lat: 35.1823171,
    lon: -83.3815429,
    value: '35.1823171,-83.3815429',
  },
  {
    label: 'Franklin, Tennessee, United States',
    lat: 35.925206,
    lon: -86.8689419,
    value: '35.925206,-86.8689419',
  },
  {
    label: 'Fredericksburg, Virginia, United States',
    lat: 38.3031837,
    lon: -77.4605399,
    value: '38.3031837,-77.4605399',
  },
  {
    label: 'Freiburg, Germany',
    lat: 47.9960901,
    lon: 7.8494005,
    value: '47.9960901,7.8494005',
  },
  {
    label: 'Fremont, California, United States',
    lat: 37.5482697,
    lon: -121.988571,
    value: '37.5482697,-121.988571',
  },
  {
    label: 'Fresno, California, United States',
    lat: 36.7394421,
    lon: -119.78483,
    value: '36.7394421,-119.78483',
  },
  {
    label: 'Fresno, Texas, United States',
    lat: 34.776168,
    lon: -100.1526119,
    value: '34.776168,-100.1526119',
  },
  {
    label: 'Friedrichsdorf, Germany',
    lat: 50.2556672,
    lon: 8.6511395,
    value: '50.2556672,8.6511395',
  },
  {
    label: 'Frisco, Texas, United States',
    lat: 33.1505998,
    lon: -96.8238183,
    value: '33.1505998,-96.8238183',
  },
  {
    label: 'Frontignan, France',
    lat: 43.4487615,
    lon: 3.7530636,
    value: '43.4487615,3.7530636',
  },
  {
    label: 'Fuchu, Japan',
    lat: 35.6611119,
    lon: 139.4734451,
    value: '35.6611119,139.4734451',
  },
  // {
  //   "label": "Fujidera, Japan",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Fukuoka, Japan',
    lat: 33.6251241,
    lon: 130.6180016,
    value: '33.6251241,130.6180016',
  },
  {
    label: 'Fukuya, Japan',
    lat: 35.1165014,
    lon: 136.8017131,
    value: '35.1165014,136.8017131',
  },
  {
    label: 'Fullerton, California, United States',
    lat: 33.8708215,
    lon: -117.929416,
    value: '33.8708215,-117.929416',
  },
  {
    label: 'Gainesville, Florida, United States',
    lat: 29.6519684,
    lon: -82.3249846,
    value: '29.6519684,-82.3249846',
  },
  {
    label: 'Gainesville, Virginia, United States',
    lat: 38.795479,
    lon: -77.6125977,
    value: '38.795479,-77.6125977',
  },
  {
    label: 'Gandhinagar, Gujarat, India',
    lat: 23.2232877,
    lon: 72.6492267,
    value: '23.2232877,72.6492267',
  },
  {
    label: 'Gangtok, Sikkim, India',
    lat: 27.329046,
    lon: 88.6122673,
    value: '27.329046,88.6122673',
  },
  {
    label: 'Garbsen, Germany',
    lat: 52.4276606,
    lon: 9.6005102,
    value: '52.4276606,9.6005102',
  },
  {
    label: 'Garching bei Munchen, Germany',
    lat: 48.2513878,
    lon: 11.6509662,
    value: '48.2513878,11.6509662',
  },
  {
    label: 'Gardena, California, United States',
    lat: 33.8815702,
    lon: -118.2918113,
    value: '33.8815702,-118.2918113',
  },
  {
    label: 'Gardner, Kansas, United States',
    lat: 38.8109254,
    lon: -94.9272958,
    value: '38.8109254,-94.9272958',
  },
  {
    label: 'Garland, Texas, United States',
    lat: 32.912624,
    lon: -96.6388833,
    value: '32.912624,-96.6388833',
  },
  {
    label: 'Garner, North Carolina, United States',
    lat: 35.7112642,
    lon: -78.6141709,
    value: '35.7112642,-78.6141709',
  },
  {
    label: 'Gary, Indiana, United States',
    lat: 41.6020962,
    lon: -87.3370646,
    value: '41.6020962,-87.3370646',
  },
  {
    label: 'Gateshead, United Kingdom',
    lat: 54.9625789,
    lon: -1.6019294,
    value: '54.9625789,-1.6019294',
  },
  {
    label: 'Gattendorf, Germany',
    lat: 50.32609085,
    lon: 12.00043163026302,
    value: '50.32609085,12.00043163026302',
  },
  {
    label: 'Gauchy, France',
    lat: 49.825,
    lon: 3.2705556,
    value: '49.825,3.2705556',
  },
  {
    label: 'Gautam Buddha Nagar, Uttar Pradesh, India',
    lat: 28.393777399999998,
    lon: 77.57310625770162,
    value: '28.393777399999998,77.57310625770162',
  },
  // {
  //   "label": "Gauwhati, Assam, India",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Gaziabad, Uttar Pradesh, India',
    lat: 28.6711527,
    lon: 77.4120356,
    value: '28.6711527,77.4120356',
  },
  {
    label: 'Gdansk, Poland',
    lat: 54.3482907,
    lon: 18.6540233,
    value: '54.3482907,18.6540233',
  },
  {
    label: 'Gebze, Turkey',
    lat: 40.8006696,
    lon: 29.431767,
    value: '40.8006696,29.431767',
  },
  {
    label: 'Geneva, Switzerland',
    lat: 46.2017559,
    lon: 6.1466014,
    value: '46.2017559,6.1466014',
  },
  {
    label: 'Genova, Italy',
    lat: 44.40726,
    lon: 8.9338624,
    value: '44.40726,8.9338624',
  },
  {
    label: 'Georgetown, Indiana, United States',
    lat: 38.6483908,
    lon: -85.9983086,
    value: '38.6483908,-85.9983086',
  },
  {
    label: 'Gera, Germany',
    lat: 50.8772301,
    lon: 12.0796208,
    value: '50.8772301,12.0796208',
  },
  {
    label: 'Gernsheim, Germany',
    lat: 49.7466,
    lon: 8.5112,
    value: '49.7466,8.5112',
  },
  {
    label: 'Gersthofen, Germany',
    lat: 48.4248151,
    lon: 10.8778564,
    value: '48.4248151,10.8778564',
  },
  {
    label: 'Getafe, Spain',
    lat: 40.3081807,
    lon: -3.7302679,
    value: '40.3081807,-3.7302679',
  },
  {
    label: 'Ghaziabad, Uttar Pradesh, India',
    lat: 28.6711527,
    lon: 77.4120356,
    value: '28.6711527,77.4120356',
  },
  {
    label: 'Ghitorni, Delhi, India',
    lat: 28.493751,
    lon: 77.1491866,
    value: '28.493751,77.1491866',
  },
  {
    label: 'Giengen an der Brenz, Germany',
    lat: 48.624521,
    lon: 10.243032,
    value: '48.624521,10.243032',
  },
  {
    label: 'Gilbert, Arizona, United States',
    lat: 33.3527632,
    lon: -111.789037,
    value: '33.3527632,-111.789037',
  },
  {
    label: 'Girona, Spain',
    lat: 41.9793006,
    lon: 2.8199439,
    value: '41.9793006,2.8199439',
  },
  {
    label: 'Giza, Egypt',
    lat: 29.9870753,
    lon: 31.2118063,
    value: '29.9870753,31.2118063',
  },
  {
    label: 'Glasgow, United Kingdom',
    lat: 55.861155,
    lon: -4.2501687,
    value: '55.861155,-4.2501687',
  },
  {
    label: 'Glastonbury, Connecticut, United States',
    lat: 41.7123218,
    lon: -72.608146,
    value: '41.7123218,-72.608146',
  },
  {
    label: 'Glen Burnie, Maryland, United States',
    lat: 39.1648,
    lon: -76.6249437,
    value: '39.1648,-76.6249437',
  },
  {
    label: 'Glendale, Arizona, United States',
    lat: 33.5386858,
    lon: -112.185994,
    value: '33.5386858,-112.185994',
  },
  {
    label: 'Glendale, California, United States',
    lat: 34.1469416,
    lon: -118.2478471,
    value: '34.1469416,-118.2478471',
  },
  {
    label: 'Glendale, Colorado, United States',
    lat: 40.0819301,
    lon: -105.366382,
    value: '40.0819301,-105.366382',
  },
  {
    label: 'Gliwice, Poland',
    lat: 50.294113,
    lon: 18.6657306,
    value: '50.294113,18.6657306',
  },
  {
    label: 'Goa, GA, India',
    lat: 15.5023329,
    lon: 73.9117432,
    value: '15.5023329,73.9117432',
  },
  {
    label: 'Goa, Karnataka, India',
    lat: 32.4485066,
    lon: 76.5391409,
    value: '32.4485066,76.5391409',
  },
  {
    label: 'Godavari, Andhra Pradesh, India',
    lat: 25.2983482,
    lon: 76.3501418,
    value: '25.2983482,76.3501418',
  },
  {
    label: 'Goodyear, Arizona, United States',
    lat: 33.4073522,
    lon: -112.39348,
    value: '33.4073522,-112.39348',
  },
  {
    label: 'Gorakhpur, Uttar Pradesh, India',
    lat: 26.7600217,
    lon: 83.3668129,
    value: '26.7600217,83.3668129',
  },
  {
    label: 'Gorzyczki, Poland',
    lat: 49.9479424,
    lon: 18.4054172,
    value: '49.9479424,18.4054172',
  },
  {
    label: 'Gosford, Australia',
    lat: -33.4250175,
    lon: 151.3417318,
    value: '-33.4250175,151.3417318',
  },
  {
    label: 'Gouldsboro, Pennsylvania, United States',
    lat: 41.2445293,
    lon: -75.4560183,
    value: '41.2445293,-75.4560183',
  },
  // {
  //   "label": "Goyang City, Korea, Republic of",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Graben, Germany',
    lat: 48.1890811,
    lon: 10.8220308,
    value: '48.1890811,10.8220308',
  },
  {
    label: 'Grand Haven, Michigan, United States',
    lat: 43.0630734,
    lon: -86.2283864,
    value: '43.0630734,-86.2283864',
  },
  {
    label: 'Grand Prairie, Texas, United States',
    lat: 32.7459645,
    lon: -96.9977846,
    value: '32.7459645,-96.9977846',
  },
  {
    label: 'Grand Rapids, Michigan, United States',
    lat: 42.9632425,
    lon: -85.6678639,
    value: '42.9632425,-85.6678639',
  },
  {
    label: 'Granite City, Illinois, United States',
    lat: 38.7014389,
    lon: -90.1487199,
    value: '38.7014389,-90.1487199',
  },
  {
    label: 'Granville, New York, United States',
    lat: 43.408041,
    lon: -73.259583,
    value: '43.408041,-73.259583',
  },
  {
    label: 'Graz, Austria',
    lat: 47.0708678,
    lon: 15.4382786,
    value: '47.0708678,15.4382786',
  },
  {
    label: 'Greece, New York, United States',
    lat: 43.2097838,
    lon: -77.6930602,
    value: '43.2097838,-77.6930602',
  },
  {
    label: 'Greencastle, Pennsylvania, United States',
    lat: 39.790371,
    lon: -77.7277714,
    value: '39.790371,-77.7277714',
  },
  {
    label: 'Greenfield, Indiana, United States',
    lat: 39.7855096,
    lon: -85.7681979,
    value: '39.7855096,-85.7681979',
  },
  {
    label: 'Greenville, South Carolina, United States',
    lat: 34.851354,
    lon: -82.3984882,
    value: '34.851354,-82.3984882',
  },
  {
    label: 'Greenville, Wisconsin, United States',
    lat: 44.3007609,
    lon: -88.5369791,
    value: '44.3007609,-88.5369791',
  },
  {
    label: 'Greenwood, Indiana, United States',
    lat: 39.6136987,
    lon: -86.1095429,
    value: '39.6136987,-86.1095429',
  },
  {
    label: 'Grimes, Iowa, United States',
    lat: 41.6883214,
    lon: -93.7910575,
    value: '41.6883214,-93.7910575',
  },
  {
    label: 'Grolsheim, Germany',
    lat: 49.9092138,
    lon: 7.9150594,
    value: '49.9092138,7.9150594',
  },
  {
    label: 'Grossebersdorf, Austria',
    lat: 48.3613007,
    lon: 16.4659971,
    value: '48.3613007,16.4659971',
  },
  {
    label: 'Grossenkneten, Germany',
    lat: 52.9423834,
    lon: 8.2509759,
    value: '52.9423834,8.2509759',
  },
  {
    label: 'Groveland, Florida, United States',
    lat: 28.5602149,
    lon: -81.8561226,
    value: '28.5602149,-81.8561226',
  },
  {
    label: 'Grugliasco, Italy',
    lat: 45.068046,
    lon: 7.57762,
    value: '45.068046,7.57762',
  },
  {
    label: 'Guadalajara, Mexico',
    lat: 20.6720375,
    lon: -103.338396,
    value: '20.6720375,-103.338396',
  },
  {
    label: 'Guadalupe, Mexico',
    lat: 25.6751114,
    lon: -100.2151753,
    value: '25.6751114,-100.2151753',
  },
  {
    label: 'Guangzhou, China',
    lat: 23.1301964,
    lon: 113.2592945,
    value: '23.1301964,113.2592945',
  },
  {
    label: 'Gulbarga, Karnataka, India',
    lat: 17.3310003,
    lon: 76.8334228,
    value: '17.3310003,76.8334228',
  },
  {
    label: 'Guntur, Andhra Pradesh, India',
    lat: 16.2915189,
    lon: 80.4541588,
    value: '16.2915189,80.4541588',
  },
  {
    label: 'Gurgaon, Delhi, India',
    lat: 28.5095344,
    lon: 77.3129831,
    value: '28.5095344,77.3129831',
  },
  {
    label: 'Gurgaon, Haryana, India',
    lat: 28.42826235,
    lon: 77.00270014657752,
    value: '28.42826235,77.00270014657752',
  },
  {
    label: 'Gurugram, Delhi, India',
    lat: 28.5487786,
    lon: 77.1207949,
    value: '28.5487786,77.1207949',
  },
  {
    label: 'Gurugram, Haryana, India',
    lat: 28.4646148,
    lon: 77.0299194,
    value: '28.4646148,77.0299194',
  },
  {
    label: 'Guwahati, Assam, India',
    lat: 26.1805978,
    lon: 91.753943,
    value: '26.1805978,91.753943',
  },
  {
    label: 'Gwalior, Madhya Pradesh, India',
    lat: 26.2037247,
    lon: 78.1573628,
    value: '26.2037247,78.1573628',
  },
  {
    label: 'Hagerstown, Maryland, United States',
    lat: 39.6430455,
    lon: -77.7191081,
    value: '39.6430455,-77.7191081',
  },
  {
    label: 'Haifa, Israel',
    lat: 32.8191218,
    lon: 34.9983856,
    value: '32.8191218,34.9983856',
  },
  {
    label: 'HALETHORPE, Maryland, United States',
    lat: 39.2398292,
    lon: -76.6802471,
    value: '39.2398292,-76.6802471',
  },
  {
    label: 'Halle (Saale), Germany',
    lat: 51.4825041,
    lon: 11.9705452,
    value: '51.4825041,11.9705452',
  },
  {
    label: 'Hamburg, Germany',
    lat: 53.550341,
    lon: 10.000654,
    value: '53.550341,10.000654',
  },
  {
    label: 'Hamburg, New York, United States',
    lat: 42.716293,
    lon: -78.828717,
    value: '42.716293,-78.828717',
  },
  {
    label: 'Hamburg, Pennsylvania, United States',
    lat: 40.5555764,
    lon: -75.9822929,
    value: '40.5555764,-75.9822929',
  },
  {
    label: 'Hamilton, Canada',
    lat: 43.2560802,
    lon: -79.8728583,
    value: '43.2560802,-79.8728583',
  },
  {
    label: 'Hamilton, New Jersey, United States',
    lat: 40.2070573,
    lon: -74.0812506,
    value: '40.2070573,-74.0812506',
  },
  {
    label: 'Hamme, Belgium',
    lat: 51.0977642,
    lon: 4.1377229,
    value: '51.0977642,4.1377229',
  },
  {
    label: 'Hampton, Virginia, United States',
    lat: 37.0300969,
    lon: -76.3452057,
    value: '37.0300969,-76.3452057',
  },
  {
    label: 'Hanahan, South Carolina, United States',
    lat: 32.9138914,
    lon: -80.0088359,
    value: '32.9138914,-80.0088359',
  },
  {
    label: 'Hangzhou, China',
    lat: 30.2489634,
    lon: 120.2052342,
    value: '30.2489634,120.2052342',
  },
  {
    label: 'Hannover, Germany',
    lat: 52.3744779,
    lon: 9.7385532,
    value: '52.3744779,9.7385532',
  },
  {
    label: 'Hanoi, Viet Nam',
    lat: 21.0283334,
    lon: 105.854041,
    value: '21.0283334,105.854041',
  },
  {
    label: 'Hanover, Maryland, United States',
    lat: 39.1928853,
    lon: -76.7241371,
    value: '39.1928853,-76.7241371',
  },
  {
    label: 'Hanover, New Hampshire, United States',
    lat: 43.703622,
    lon: -72.288666,
    value: '43.703622,-72.288666',
  },
  {
    label: 'Haridwar, UT, India',
    lat: 29.9384473,
    lon: 78.1452985,
    value: '29.9384473,78.1452985',
  },
  {
    label: 'Harlow, United Kingdom',
    lat: 51.7676194,
    lon: 0.0974893,
    value: '51.7676194,0.0974893',
  },
  {
    label: 'Hattiesburg, Mississippi, United States',
    lat: 31.3271189,
    lon: -89.2903392,
    value: '31.3271189,-89.2903392',
  },
  {
    label: 'Havant, United Kingdom',
    lat: 50.8519351,
    lon: -0.9821263,
    value: '50.8519351,-0.9821263',
  },
  {
    label: 'Haverhill, Massachusetts, United States',
    lat: 42.7777829,
    lon: -71.0767724,
    value: '42.7777829,-71.0767724',
  },
  {
    label: 'Hawthorne, California, United States',
    lat: 33.9188589,
    lon: -118.3483256,
    value: '33.9188589,-118.3483256',
  },
  {
    label: 'Hawthorne, New York, United States',
    lat: 41.1073184,
    lon: -73.7959667,
    value: '41.1073184,-73.7959667',
  },
  {
    label: 'Haymarket, Virginia, United States',
    lat: 38.8121398,
    lon: -77.6368038,
    value: '38.8121398,-77.6368038',
  },
  {
    label: 'Hayward, California, United States',
    lat: 37.6688205,
    lon: -122.080796,
    value: '37.6688205,-122.080796',
  },
  {
    label: 'Hazel Park, Michigan, United States',
    lat: 42.4620142,
    lon: -83.1035688,
    value: '42.4620142,-83.1035688',
  },
  {
    label: 'Hazelwood, Missouri, United States',
    lat: 38.7761446,
    lon: -90.3715909,
    value: '38.7761446,-90.3715909',
  },
  {
    label: 'Hazleton Township, Pennsylvania, United States',
    lat: 40.949945,
    lon: -75.970342,
    value: '40.949945,-75.970342',
  },
  {
    label: 'Hazleton, Pennsylvania, United States',
    lat: 40.9646867,
    lon: -75.9852789,
    value: '40.9646867,-75.9852789',
  },
  {
    label: 'Hebron, Kentucky, United States',
    lat: 39.065813,
    lon: -84.7027575,
    value: '39.065813,-84.7027575',
  },
  {
    label: 'Heidenheim an der Brenz, Germany',
    lat: 48.6767637,
    lon: 10.152923,
    value: '48.6767637,10.152923',
  },
  {
    label: 'Helmstedt, Germany',
    lat: 52.2277037,
    lon: 11.0104702,
    value: '52.2277037,11.0104702',
  },
  {
    label: 'Helsinki, Finland',
    lat: 60.1674881,
    lon: 24.9427473,
    value: '60.1674881,24.9427473',
  },
  {
    label: 'Henderson, Nevada, United States',
    lat: 36.0301134,
    lon: -114.9826194,
    value: '36.0301134,-114.9826194',
  },
  {
    label: 'Heredia, Costa Rica',
    lat: 9.9981515,
    lon: -84.1170163,
    value: '9.9981515,-84.1170163',
  },
  {
    label: 'Hermiston, Oregon, United States',
    lat: 45.8404101,
    lon: -119.28946,
    value: '45.8404101,-119.28946',
  },
  {
    label: 'Hermosillo, Mexico',
    lat: 29.0948207,
    lon: -110.9692202,
    value: '29.0948207,-110.9692202',
  },
  {
    label: 'Herndon, Virginia, United States',
    lat: 38.9695316,
    lon: -77.3859479,
    value: '38.9695316,-77.3859479',
  },
  {
    label: 'Hialeah, Florida, United States',
    lat: 25.8575963,
    lon: -80.2781057,
    value: '25.8575963,-80.2781057',
  },
  {
    label: 'Hilliard, Ohio, United States',
    lat: 40.033814,
    lon: -83.1596108,
    value: '40.033814,-83.1596108',
  },
  {
    label: 'Hillsboro, Oregon, United States',
    lat: 45.5228939,
    lon: -122.989827,
    value: '45.5228939,-122.989827',
  },
  {
    label: 'Hinjewadi, Maharashtra, India',
    lat: 18.607716449999998,
    lon: 73.71979497,
    value: '18.607716449999998,73.719794973868',
  },
  {
    label: 'Hirakata, Japan',
    lat: 34.818215,
    lon: 135.659225,
    value: '34.818215,135.659225',
  },
  {
    label: 'Hiratsuka, Japan',
    lat: 35.357674,
    lon: 139.318278,
    value: '35.357674,139.318278',
  },
  {
    label: 'Hiroshima, Japan',
    lat: 34.3917241,
    lon: 132.4517589,
    value: '34.3917241,132.4517589',
  },
  {
    label: 'Hisar, Haryana, India',
    lat: 29.1562688,
    lon: 75.7292303,
    value: '29.1562688,75.7292303',
  },
  {
    label: 'Ho Chi Minh, Viet Nam',
    lat: 10.7763897,
    lon: 106.7011391,
    value: '10.7763897,106.7011391',
  },
  {
    label: 'Hoboken, New Jersey, United States',
    lat: 40.7433066,
    lon: -74.0323752,
    value: '40.7433066,-74.0323752',
  },
  {
    label: 'Hochiminhcity, Viet Nam',
    lat: 22.8328517,
    lon: 104.9878376,
    value: '22.8328517,104.9878376',
  },
  {
    label: 'Hockenheim, Germany',
    lat: 49.3188892,
    lon: 8.5475467,
    value: '49.3188892,8.5475467',
  },
  {
    label: 'Hoersching, Austria',
    lat: 48.2264661,
    lon: 14.1782774,
    value: '48.2264661,14.1782774',
  },
  {
    label: 'Hofuf, Saudi Arabia',
    lat: 25.3696585,
    lon: 49.5865224,
    value: '25.3696585,49.5865224',
  },
  {
    label: 'Holbrook, New York, United States',
    lat: 40.794373,
    lon: -73.070022,
    value: '40.794373,-73.070022',
  },
  {
    label: 'Hollister, California, United States',
    lat: 36.8524545,
    lon: -121.4016021,
    value: '36.8524545,-121.4016021',
  },
  {
    label: 'Holyoke, Massachusetts, United States',
    lat: 42.2071434,
    lon: -72.60765,
    value: '42.2071434,-72.60765',
  },
  {
    label: 'Hong Kong, Hong Kong',
    lat: 22.381131,
    lon: 114.135572,
    value: '22.381131,114.135572',
  },
  {
    label: 'Honolulu, Hawaii, United States',
    lat: 21.304547,
    lon: -157.855676,
    value: '21.304547,-157.855676',
  },
  {
    label: 'Hooghly, West Bengal, India',
    lat: 22.872939,
    lon: 88.17086,
    value: '22.872939,88.17086',
  },
  {
    label: 'Hooksett, New Hampshire, United States',
    lat: 43.096972,
    lon: -71.465378,
    value: '43.096972,-71.465378',
  },
  {
    label: 'Hoppegarten, Germany',
    lat: 52.550231,
    lon: 13.641194431680784,
    value: '52.550231,13.641194431680784',
  },
  {
    label: 'Horn Lake, Mississippi, United States',
    lat: 34.9553719,
    lon: -90.0348139,
    value: '34.9553719,-90.0348139',
  },
  {
    label: 'Horn-Bad Meinberg, Germany',
    lat: 51.8801277,
    lon: 8.9731695,
    value: '51.8801277,8.9731695',
  },
  {
    label: 'Hosakote, Karnataka, India',
    lat: 13.7546467,
    lon: 77.7955625,
    value: '13.7546467,77.7955625',
  },
  {
    label: 'Hosur, TN, India',
    lat: 12.7328844,
    lon: 77.8309478,
    value: '12.7328844,77.8309478',
  },
  {
    label: 'Houston, Texas, United States',
    lat: 29.7589382,
    lon: -95.3676974,
    value: '29.7589382,-95.3676974',
  },
  {
    label: 'Howrah, West Bengal, India',
    lat: 22.5736296,
    lon: 88.3251045,
    value: '22.5736296,88.3251045',
  },
  {
    label: 'Hsinchu City, Taiwan',
    lat: 24.791708,
    lon: 120.987171,
    value: '24.791708,120.987171',
  },
  {
    label: 'Hubli, Karnataka, India',
    lat: 15.3518378,
    lon: 75.1379848,
    value: '15.3518378,75.1379848',
  },
  {
    label: 'Hull, United Kingdom',
    lat: 53.7435722,
    lon: -0.3394758,
    value: '53.7435722,-0.3394758',
  },
  {
    label: 'Humble, Texas, United States',
    lat: 29.9988312,
    lon: -95.2621553,
    value: '29.9988312,-95.2621553',
  },
  {
    label: 'Huntersville, North Carolina, United States',
    lat: 35.4108278,
    lon: -80.8429304,
    value: '35.4108278,-80.8429304',
  },
  {
    label: 'Huntington Beach, California, United States',
    lat: 33.6783336,
    lon: -118.000016,
    value: '33.6783336,-118.000016',
  },
  {
    label: 'Huntington, West Virginia, United States',
    lat: 38.4192496,
    lon: -82.445154,
    value: '38.4192496,-82.445154',
  },
  {
    label: 'Huntley, Illinois, United States',
    lat: 42.1722503,
    lon: -88.42692,
    value: '42.1722503,-88.42692',
  },
  {
    label: 'Hurghada, Egypt',
    lat: 27.222556,
    lon: 33.8307062,
    value: '27.222556,33.8307062',
  },
  {
    label: 'Hyattsville, Maryland, United States',
    lat: 38.9529442,
    lon: -76.9408647,
    value: '38.9529442,-76.9408647',
  },
  {
    label: 'Hyderabad, Telangana, India',
    lat: 17.360589,
    lon: 78.4740613,
    value: '17.360589,78.4740613',
  },
  {
    label: 'Hyogo, Japan',
    lat: 34.4512228,
    lon: 135.7738924,
    value: '34.4512228,135.7738924',
  },
  {
    label: 'Iasi, Romania',
    lat: 47.1615598,
    lon: 27.5837814,
    value: '47.1615598,27.5837814',
  },
  {
    label: 'Ibaraki, Japan',
    lat: 36.2869536,
    lon: 140.4703384,
    value: '36.2869536,140.4703384',
  },
  {
    label: 'Ichikawa, Japan',
    lat: 35.729412,
    lon: 139.928568,
    value: '35.729412,139.928568',
  },
  {
    label: 'IDAHO FALLS, Idaho, United States',
    lat: 43.4887907,
    lon: -112.03628,
    value: '43.4887907,-112.03628',
  },
  {
    label: 'Illescas, Spain',
    lat: 40.1248436,
    lon: -3.8492688,
    value: '40.1248436,-3.8492688',
  },
  {
    label: 'Imperial, Pennsylvania, United States',
    lat: 40.4495127,
    lon: -80.2445041,
    value: '40.4495127,-80.2445041',
  },
  {
    label: 'Inazawa, Japan',
    lat: 35.240484,
    lon: 136.758964,
    value: '35.240484,136.758964',
  },
  {
    label: 'Indaiatuba, Brazil',
    lat: -23.0908356,
    lon: -47.2180677,
    value: '-23.0908356,-47.2180677',
  },
  {
    label: 'Indianapolis, Indiana, United States',
    lat: 39.7683331,
    lon: -86.1583502,
    value: '39.7683331,-86.1583502',
  },
  {
    label: 'Indore, Madhya Pradesh, India',
    lat: 22.7203616,
    lon: 75.8681996,
    value: '22.7203616,75.8681996',
  },
  {
    label: 'Inglewood, California, United States',
    lat: 33.9562003,
    lon: -118.353132,
    value: '33.9562003,-118.353132',
  },
  {
    label: 'Inzai, Japan',
    lat: 35.8322582,
    lon: 140.1452981,
    value: '35.8322582,140.1452981',
  },
  {
    label: 'Iowa City, Iowa, United States',
    lat: 41.6612561,
    lon: -91.5299106,
    value: '41.6612561,-91.5299106',
  },
  {
    label: 'Ipswich, United Kingdom',
    lat: 52.0579324,
    lon: 1.1528095,
    value: '52.0579324,1.1528095',
  },
  {
    label: 'Ireland, Ireland',
    lat: 53.4152268,
    lon: -7.966611695,
    value: '53.4152268,-7.96661169478397',
  },
  {
    label: 'Irvine, California, United States',
    lat: 33.6856969,
    lon: -117.825981,
    value: '33.6856969,-117.825981',
  },
  {
    label: 'Irving, Texas, United States',
    lat: 32.8295183,
    lon: -96.9442177,
    value: '32.8295183,-96.9442177',
  },
  {
    label: 'Isehara-shi, Japan',
    lat: 35.4023968,
    lon: 139.2996106,
    value: '35.4023968,139.2996106',
  },
  {
    label: 'Isehara, Japan',
    lat: 35.4023968,
    lon: 139.2996106,
    value: '35.4023968,139.2996106',
  },
  {
    label: 'Isla Vista, California, United States',
    lat: 34.4115125,
    lon: -119.8562131,
    value: '34.4115125,-119.8562131',
  },
  {
    label: 'Ismailia, Egypt',
    lat: 30.6043775,
    lon: 32.2770825,
    value: '30.6043775,32.2770825',
  },
  {
    label: 'Istanbul, Turkey',
    lat: 41.006381,
    lon: 28.9758715,
    value: '41.006381,28.9758715',
  },
  {
    label: 'ITAITINGA, Brazil',
    lat: -3.965768,
    lon: -38.529763,
    value: '-3.965768,-38.529763',
  },
  {
    label: 'Jabalpur, Madhya Pradesh, India',
    lat: 23.1608938,
    lon: 79.9497702,
    value: '23.1608938,79.9497702',
  },
  {
    label: 'Jackson, Mississippi, United States',
    lat: 32.2998686,
    lon: -90.1830408,
    value: '32.2998686,-90.1830408',
  },
  {
    label: 'Jackson, Missouri, United States',
    lat: 37.3828316,
    lon: -89.6684487,
    value: '37.3828316,-89.6684487',
  },
  {
    label: 'Jacksonville, Florida, United States',
    lat: 30.3321838,
    lon: -81.655651,
    value: '30.3321838,-81.655651',
  },
  {
    label: 'Jaipur, Rajasthan, India',
    lat: 26.9154576,
    lon: 75.8189817,
    value: '26.9154576,75.8189817',
  },
  {
    label: 'Jakarta, Indonesia',
    lat: -6.175247,
    lon: 106.8270488,
    value: '-6.175247,106.8270488',
  },
  {
    label: "Jakkur, B'lore, Karnataka, India",
    lat: 13.094316,
    lon: 77.625176,
    value: '13.094316,77.625176',
  },
  {
    label: 'Jalandhar, Punjab, India',
    lat: 31.3323762,
    lon: 75.576889,
    value: '31.3323762,75.576889',
  },
  {
    label: 'Jalgaon, Maharashtra, India',
    lat: 20.9968453,
    lon: 75.5683795,
    value: '20.9968453,75.5683795',
  },
  {
    label: 'Jalukbari, Assam, India',
    lat: 26.1554983,
    lon: 91.671224,
    value: '26.1554983,91.671224',
  },
  {
    label: 'Jamalpur, Bihar, India',
    lat: 25.3138767,
    lon: 86.4897172,
    value: '25.3138767,86.4897172',
  },
  {
    label: 'Jamalpur, Haryana, India',
    lat: 28.9617956,
    lon: 75.9568209,
    value: '28.9617956,75.9568209',
  },
  {
    label: 'Jammu, Jammu and Kashmir, India',
    lat: 32.7185614,
    lon: 74.8580917,
    value: '32.7185614,74.8580917',
  },
  {
    label: 'Janakpuri, Delhi, Delhi, India',
    lat: 28.624944,
    lon: 77.075389,
    value: '28.624944,77.075389',
  },
  {
    label: 'Jandakot, Australia',
    lat: -32.1023906,
    lon: 115.8723359,
    value: '-32.1023906,115.8723359',
  },
  {
    label: 'Jeddah, Saudi Arabia',
    lat: 21.5810088,
    lon: 39.1653612,
    value: '21.5810088,39.1653612',
  },
  {
    label: 'Jefferson, Georgia, United States',
    lat: 30.9666202,
    lon: -81.7901087,
    value: '30.9666202,-81.7901087',
  },
  {
    label: 'Jeffersonville, Indiana, United States',
    lat: 38.2770227,
    lon: -85.7371604,
    value: '38.2770227,-85.7371604',
  },
  {
    label: 'Jersey City, New Jersey, United States',
    lat: 40.7215682,
    lon: -74.047455,
    value: '40.7215682,-74.047455',
  },
  {
    label: 'Jessup, Maryland, United States',
    lat: 39.1492746,
    lon: -76.7752493,
    value: '39.1492746,-76.7752493',
  },
  {
    label: 'Jessup, Pennsylvania, United States',
    lat: 41.4686924,
    lon: -75.5621309,
    value: '41.4686924,-75.5621309',
  },
  {
    label: 'Jhajjar, Haryana, India',
    lat: 28.6066833,
    lon: 76.6580764,
    value: '28.6066833,76.6580764',
  },
  {
    label: 'Jhansi, Uttar Pradesh, India',
    lat: 25.4502464,
    lon: 78.5800085,
    value: '25.4502464,78.5800085',
  },
  {
    label: 'Jirkov, Czech Republic',
    lat: 50.5000597,
    lon: 13.4476266,
    value: '50.5000597,13.4476266',
  },
  {
    label: 'João Pessoa, Brazil',
    lat: -7.1215981,
    lon: -34.882028,
    value: '-7.1215981,-34.882028',
  },
  {
    label: 'Jodhpur, Rajasthan, India',
    lat: 26.2967719,
    lon: 73.0351433,
    value: '26.2967719,73.0351433',
  },
  {
    label: 'Johannesburg, South Africa',
    lat: -26.205,
    lon: 28.049722,
    value: '-26.205,28.049722',
  },
  {
    label: 'Johnstown, Ohio, United States',
    lat: 40.1536742,
    lon: -82.6851699,
    value: '40.1536742,-82.6851699',
  },
  {
    label: 'Joliet, Illinois, United States',
    lat: 41.5263603,
    lon: -88.0840212,
    value: '41.5263603,-88.0840212',
  },
  {
    label: 'Joplin, Missouri, United States',
    lat: 37.0841838,
    lon: -94.5133385,
    value: '37.0841838,-94.5133385',
  },
  {
    label: 'Jorhat, Assam, India',
    lat: 26.7577925,
    lon: 94.2079645,
    value: '26.7577925,94.2079645',
  },
  {
    label: 'Jundiai, Brazil',
    lat: -23.1887668,
    lon: -46.884506,
    value: '-23.1887668,-46.884506',
  },
  {
    label: 'Jupiter, Florida, United States',
    lat: 26.9342246,
    lon: -80.0942087,
    value: '26.9342246,-80.0942087',
  },
  {
    label: 'Jurupa Valley, California, United States',
    lat: 33.9798472,
    lon: -117.4515754,
    value: '33.9798472,-117.4515754',
  },
  {
    label: 'Kabelsketal, Germany',
    lat: 51.4375947,
    lon: 12.1050069,
    value: '51.4375947,12.1050069',
  },
  {
    label: 'Kadoma-shi, Japan',
    lat: 34.728801,
    lon: 135.59691,
    value: '34.728801,135.59691',
  },
  {
    label: 'Kagawa, Japan',
    lat: 34.2480104,
    lon: 134.0586579,
    value: '34.2480104,134.0586579',
  },
  {
    label: 'Kaiserslautern, Germany',
    lat: 49.4432174,
    lon: 7.7689951,
    value: '49.4432174,7.7689951',
  },
  {
    label: 'Kanagawa, Japan',
    lat: 35.1342467,
    lon: 133.0911577,
    value: '35.1342467,133.0911577',
  },
  {
    label: 'Kanchipuram, TN, India',
    lat: 12.836393,
    lon: 79.7053304,
    value: '12.836393,79.7053304',
  },
  {
    label: 'Kanpur, Uttar Pradesh, India',
    lat: 26.4609135,
    lon: 80.3217588,
    value: '26.4609135,80.3217588',
  },
  {
    label: 'Kansas City, Kansas, United States',
    lat: 39.1134562,
    lon: -94.626497,
    value: '39.1134562,-94.626497',
  },
  {
    label: 'Kansas City, Missouri, United States',
    lat: 39.100105,
    lon: -94.5781416,
    value: '39.100105,-94.5781416',
  },
  {
    label: 'Karimnagar, Telangana, India',
    lat: 18.4348122,
    lon: 79.1328042,
    value: '18.4348122,79.1328042',
  },
  {
    label: 'Karlsdorf, Austria',
    lat: 48.7050924,
    lon: 16.0228885,
    value: '48.7050924,16.0228885',
  },
  {
    label: 'Karwar, Karnataka, India',
    lat: 14.8120931,
    lon: 74.1319229,
    value: '14.8120931,74.1319229',
  },
  {
    label: 'Kashiwa, Japan',
    lat: 35.8676218,
    lon: 139.9756876,
    value: '35.8676218,139.9756876',
  },
  {
    label: 'Katrineholm, Sweden',
    lat: 58.9959119,
    lon: 16.2062225,
    value: '58.9959119,16.2062225',
  },
  {
    label: 'Katy, Texas, United States',
    lat: 29.7857853,
    lon: -95.8243956,
    value: '29.7857853,-95.8243956',
  },
  {
    label: 'Kawagoe, Japan',
    lat: 35.9251145,
    lon: 139.4856927,
    value: '35.9251145,139.4856927',
  },
  {
    label: 'Kawaguchi-shi, Saitama, Japan',
    lat: 35.8078228,
    lon: 139.7241054,
    value: '35.8078228,139.7241054',
  },
  {
    label: 'Kawaguchi, Japan',
    lat: 35.8078228,
    lon: 139.7241054,
    value: '35.8078228,139.7241054',
  },
  {
    label: 'Kawajima, Japan',
    lat: 35.9924411,
    lon: 139.4840808,
    value: '35.9924411,139.4840808',
  },
  {
    label: 'Kawasaki, Japan',
    lat: 35.5305307,
    lon: 139.7028012,
    value: '35.5305307,139.7028012',
  },
  {
    label: 'Kearny, New Jersey, United States',
    lat: 40.7684342,
    lon: -74.1454214,
    value: '40.7684342,-74.1454214',
  },
  {
    label: 'Kegworth, United Kingdom',
    lat: 52.8380135,
    lon: -1.287637709,
    value: '52.8380135,-1.28763770917947',
  },
  {
    label: 'Kempen, Germany',
    lat: 51.3642126,
    lon: 6.4195011,
    value: '51.3642126,6.4195011',
  },
  {
    label: 'Kemps Creek, Australia',
    lat: -33.8813335,
    lon: 150.7837238,
    value: '-33.8813335,150.7837238',
  },
  {
    label: 'Kenosha, Wisconsin, United States',
    lat: 42.5846773,
    lon: -87.8212263,
    value: '42.5846773,-87.8212263',
  },
  {
    label: 'Kent, Washington, United States',
    lat: 47.3826903,
    lon: -122.2270272,
    value: '47.3826903,-122.2270272',
  },
  {
    label: 'Kernersville, North Carolina, United States',
    lat: 36.1198589,
    lon: -80.0736533,
    value: '36.1198589,-80.0736533',
  },
  {
    label: 'Kettering, Ohio, United States',
    lat: 39.6895036,
    lon: -84.1688274,
    value: '39.6895036,-84.1688274',
  },
  {
    label: 'Kewdale, Australia',
    lat: -31.9760655,
    lon: 115.9423461,
    value: '-31.9760655,115.9423461',
  },
  {
    label: 'Khammam, Andhra Pradesh, India',
    lat: 17.2465351,
    lon: 80.1500326,
    value: '17.2465351,80.1500326',
  },
  {
    label: 'Kharagpur, West Bengal, India',
    lat: 22.34309,
    lon: 87.3012875,
    value: '22.34309,87.3012875',
  },
  {
    label: 'Kinston, North Carolina, United States',
    lat: 35.2626635,
    lon: -77.5816353,
    value: '35.2626635,-77.5816353',
  },
  {
    label: 'Kirkland, Washington, United States',
    lat: 47.6765382,
    lon: -122.2070775,
    value: '47.6765382,-122.2070775',
  },
  {
    label: 'Kitchener, Canada',
    lat: 43.451291,
    lon: -80.4927815,
    value: '43.451291,-80.4927815',
  },
  {
    label: 'Klagenfurt, Austria',
    lat: 46.623943,
    lon: 14.3075976,
    value: '46.623943,14.3075976',
  },
  {
    label: 'Kleinostheim, Germany',
    lat: 50.0038042,
    lon: 9.0655061,
    value: '50.0038042,9.0655061',
  },
  {
    label: 'Knottingley, United Kingdom',
    lat: 53.7092062,
    lon: -1.238314,
    value: '53.7092062,-1.238314',
  },
  {
    label: 'Knoxville, Tennessee, United States',
    lat: 35.9603948,
    lon: -83.9210261,
    value: '35.9603948,-83.9210261',
  },
  {
    label: 'Knuellwald, Germany',
    lat: 51.002789,
    lon: 9.5070574,
    value: '51.002789,9.5070574',
  },
  {
    label: 'Kobe, Japan',
    lat: 34.6932379,
    lon: 135.1943764,
    value: '34.6932379,135.1943764',
  },
  {
    label: 'Kobern-Gondorf, Germany',
    lat: 50.3069935,
    lon: 7.4583,
    value: '50.3069935,7.4583',
  },
  {
    label: 'Kocaeli, Turkey',
    lat: 40.7653892,
    lon: 29.9407361,
    value: '40.7653892,29.9407361',
  },
  {
    label: 'Kochi, Japan',
    lat: 33.5680384,
    lon: 133.5394221,
    value: '33.5680384,133.5394221',
  },
  {
    label: 'Kochi, Kerala, India',
    lat: 9.9674277,
    lon: 76.2454436,
    value: '9.9674277,76.2454436',
  },
  {
    label: 'Koeln, Germany',
    lat: 50.928344,
    lon: 7.002998,
    value: '50.928344,7.002998',
  },
  {
    label: 'Kojetin, Czech Republic',
    lat: 49.6571124,
    lon: 15.6580813,
    value: '49.6571124,15.6580813',
  },
  {
    label: 'Kolhapur, Maharashtra, India',
    lat: 16.7028412,
    lon: 74.2405329,
    value: '16.7028412,74.2405329',
  },
  {
    label: 'Kolkata, Telangana, India',
    lat: 22.5726459,
    lon: 88.3638953,
    value: '22.5726459,88.3638953',
  },
  {
    label: 'Königs Wusterhausen, Germany',
    lat: 52.2994511,
    lon: 13.6244389,
    value: '52.2994511,13.6244389',
  },
  {
    label: 'Korntal-Muenchingen, Germany',
    lat: 48.8432607,
    lon: 9.1068611,
    value: '48.8432607,9.1068611',
  },
  {
    label: 'Kota, Rajasthan, India',
    lat: 25,
    lon: 76.5,
    value: '25,76.5',
  },
  {
    label: 'Kothagudem, Telangana, India',
    lat: 17.5456307,
    lon: 80.6116162,
    value: '17.5456307,80.6116162',
  },
  {
    label: 'Koto-ku, Japan',
    lat: 35.6727747,
    lon: 139.8169621,
    value: '35.6727747,139.8169621',
  },
  {
    label: 'Kozikode, Kerala, India',
    lat: 11.2450558,
    lon: 75.7754716,
    value: '11.2450558,75.7754716',
  },
  {
    label: 'Krajkow, Poland',
    lat: 50.9613856,
    lon: 17.0643007,
    value: '50.9613856,17.0643007',
  },
  {
    label: 'Krakow, Poland',
    lat: 50.0619474,
    lon: 19.9368564,
    value: '50.0619474,19.9368564',
  },
  {
    label: 'Krefeld, Germany',
    lat: 51.3331205,
    lon: 6.5623343,
    value: '51.3331205,6.5623343',
  },
  {
    label: 'Krzyzowice, Poland',
    lat: 50.9932263,
    lon: 16.8770564,
    value: '50.9932263,16.8770564',
  },
  {
    label: 'Kuala Lampur, Malaysia',
    lat: 3.160318,
    lon: 101.691466,
    value: '3.160318,101.691466',
  },
  {
    label: 'Kuala Lumpur, Malaysia',
    lat: 3.1516964,
    lon: 101.6942371,
    value: '3.1516964,101.6942371',
  },
  {
    label: 'Kuki-shi, Japan',
    lat: 36.0618828,
    lon: 139.6667081,
    value: '36.0618828,139.6667081',
  },
  {
    label: 'Kumamoto, Japan',
    lat: 32.6450475,
    lon: 130.6341345,
    value: '32.6450475,130.6341345',
  },
  {
    label: 'Kunshan, China',
    lat: 31.3869323,
    lon: 120.9764683,
    value: '31.3869323,120.9764683',
  },
  {
    label: 'Kurnool, Andhra Pradesh, India',
    lat: 15.8309251,
    lon: 78.0425373,
    value: '15.8309251,78.0425373',
  },
  {
    label: 'Kyle, Texas, United States',
    lat: 29.9892816,
    lon: -97.877174,
    value: '29.9892816,-97.877174',
  },
  {
    label: 'Kyoto, Japan',
    lat: 35.021041,
    lon: 135.7556075,
    value: '35.021041,135.7556075',
  },
  {
    label: 'La Coruna, Spain',
    lat: 41.7933365,
    lon: -6.255967,
    value: '41.7933365,-6.255967',
  },
  {
    label: 'La Crosse, Wisconsin, United States',
    lat: 43.8122836,
    lon: -91.2514355,
    value: '43.8122836,-91.2514355',
  },
  {
    label: 'La Habra, California, United States',
    lat: 33.9316066,
    lon: -117.9454867,
    value: '33.9316066,-117.9454867',
  },
  {
    label: 'La Marque, Texas, United States',
    lat: 29.3685674,
    lon: -94.9713134,
    value: '29.3685674,-94.9713134',
  },
  {
    label: 'La Spezia, Italy',
    lat: 44.1033871,
    lon: 9.8217022,
    value: '44.1033871,9.8217022',
  },
  {
    label: 'La Vergne, Tennessee, United States',
    lat: 36.0156178,
    lon: -86.5819394,
    value: '36.0156178,-86.5819394',
  },
  {
    label: 'La Verne, California, United States',
    lat: 34.1008426,
    lon: -117.7678355,
    value: '34.1008426,-117.7678355',
  },
  {
    label: 'Lacey, Washington, United States',
    lat: 47.0263876,
    lon: -122.8072257,
    value: '47.0263876,-122.8072257',
  },
  {
    label: 'Lachine, Canada',
    lat: 45.435114,
    lon: -73.6837987,
    value: '45.435114,-73.6837987',
  },
  {
    label: 'Lafayette, Indiana, United States',
    lat: 40.4191229,
    lon: -86.8919011,
    value: '40.4191229,-86.8919011',
  },
  {
    label: 'Lakeland, Florida, United States',
    lat: 28.0394654,
    lon: -81.9498042,
    value: '28.0394654,-81.9498042',
  },
  {
    label: 'Lakeville, Minnesota, United States',
    lat: 44.650051,
    lon: -93.2432791,
    value: '44.650051,-93.2432791',
  },
  {
    label: 'Lakewood, Colorado, United States',
    lat: 39.7085736,
    lon: -105.0846694,
    value: '39.7085736,-105.0846694',
  },
  {
    label: 'Lakewood, Washington, United States',
    lat: 47.1717649,
    lon: -122.518458,
    value: '47.1717649,-122.518458',
  },
  {
    label: 'Lampertswalde, Germany',
    lat: 51.3161354,
    lon: 13.6731506,
    value: '51.3161354,13.6731506',
  },
  {
    label: 'Lancaster, New York, United States',
    lat: 42.9005956,
    lon: -78.669997,
    value: '42.9005956,-78.669997',
  },
  {
    label: 'Lancaster, Texas, United States',
    lat: 32.5920798,
    lon: -96.7561083,
    value: '32.5920798,-96.7561083',
  },
  {
    label: 'Land O Lakes, Florida, United States',
    lat: 28.2188992,
    lon: -82.4575938,
    value: '28.2188992,-82.4575938',
  },
  {
    label: 'Langfang, China',
    lat: 39.5302055,
    lon: 116.6926134,
    value: '39.5302055,116.6926134',
  },
  {
    label: 'Langhorne, Pennsylvania, United States',
    lat: 40.1745538,
    lon: -74.9226665,
    value: '40.1745538,-74.9226665',
  },
  {
    label: 'Langley, Canada',
    lat: 49.1039289,
    lon: -122.657047,
    value: '49.1039289,-122.657047',
  },
  {
    label: 'Lanham, Maryland, United States',
    lat: 38.9670551,
    lon: -76.8619166,
    value: '38.9670551,-76.8619166',
  },
  {
    label: 'Lansing, Michigan, United States',
    lat: 42.7337712,
    lon: -84.5553805,
    value: '42.7337712,-84.5553805',
  },
  {
    label: 'Las Vegas, Nevada, United States',
    lat: 36.1672559,
    lon: -115.148516,
    value: '36.1672559,-115.148516',
  },
  {
    label: 'Laurel, Maryland, United States',
    lat: 39.0984317,
    lon: -76.8485094,
    value: '39.0984317,-76.8485094',
  },
  {
    label: 'Lauwin-Planque, France',
    lat: 50.3906656,
    lon: 3.0462262,
    value: '50.3906656,3.0462262',
  },
  {
    label: 'Laval, Canada',
    lat: 45.60558895,
    lon: -73.73456015,
    value: '45.60558895,-73.7345601450884',
  },
  {
    label: 'Lawrence Township, New Jersey, United States',
    lat: 40.2774754,
    lon: -74.7296047,
    value: '40.2774754,-74.7296047',
  },
  {
    label: 'Lazio, Italy',
    lat: 41.9808038,
    lon: 12.7662312,
    value: '41.9808038,12.7662312',
  },
  {
    label: 'Lebanon, Pennsylvania, United States',
    lat: 40.3429683,
    lon: -76.4233816,
    value: '40.3429683,-76.4233816',
  },
  {
    label: 'Lebanon, Tennessee, United States',
    lat: 36.2079779,
    lon: -86.2910945,
    value: '36.2079779,-86.2910945',
  },
  {
    label: 'Leeds, United Kingdom',
    lat: 53.7974185,
    lon: -1.5437941,
    value: '53.7974185,-1.5437941',
  },
  {
    label: 'Leicester, United Kingdom',
    lat: 52.6362,
    lon: -1.1331969,
    value: '52.6362,-1.1331969',
  },
  {
    label: 'Leipzig, Germany',
    lat: 51.3406321,
    lon: 12.3747329,
    value: '51.3406321,12.3747329',
  },
  {
    label: 'Lenexa, Kansas, United States',
    lat: 38.9697458,
    lon: -94.7845837,
    value: '38.9697458,-94.7845837',
  },
  {
    label: 'Lengede, Germany',
    lat: 52.205671,
    lon: 10.3103638,
    value: '52.205671,10.3103638',
  },
  {
    label: 'Leon, Mexico',
    lat: 21.1218598,
    lon: -101.6825081,
    value: '21.1218598,-101.6825081',
  },
  {
    label: 'León, Mexico',
    lat: 21.1218598,
    lon: -101.6825081,
    value: '21.1218598,-101.6825081',
  },
  {
    label: 'Levittown, Pennsylvania, United States',
    lat: 40.154762,
    lon: -74.8380029,
    value: '40.154762,-74.8380029',
  },
  {
    label: 'Lewisberry, Pennsylvania, United States',
    lat: 40.1350929,
    lon: -76.8596945,
    value: '40.1350929,-76.8596945',
  },
  {
    label: 'Lewisville, Texas, United States',
    lat: 33.046233,
    lon: -96.994174,
    value: '33.046233,-96.994174',
  },
  {
    label: 'Lexington, Kentucky, United States',
    lat: 38.0464066,
    lon: -84.4970393,
    value: '38.0464066,-84.4970393',
  },
  {
    label: 'Liberty, Missouri, United States',
    lat: 39.246479,
    lon: -94.419079,
    value: '39.246479,-94.419079',
  },
  {
    label: 'Liege, Belgium',
    lat: 50.6450944,
    lon: 5.5736112,
    value: '50.6450944,5.5736112',
  },
  {
    label: 'Lille, France',
    lat: 50.6365654,
    lon: 3.0635282,
    value: '50.6365654,3.0635282',
  },
  {
    label: 'Limerick, Ireland',
    lat: 52.661252,
    lon: -8.6301239,
    value: '52.661252,-8.6301239',
  },
  {
    label: 'Linden, New Jersey, United States',
    lat: 40.6220478,
    lon: -74.2445902,
    value: '40.6220478,-74.2445902',
  },
  {
    label: 'Lisbon, Portugal',
    lat: 38.7077507,
    lon: -9.1365919,
    value: '38.7077507,-9.1365919',
  },
  {
    label: 'Litchfield Park, Arizona, United States',
    lat: 33.4933796,
    lon: -112.358124,
    value: '33.4933796,-112.358124',
  },
  {
    label: 'Lithia Springs, Georgia, United States',
    lat: 33.7939953,
    lon: -84.6604899,
    value: '33.7939953,-84.6604899',
  },
  {
    label: 'Little Rock, Arkansas, United States',
    lat: 34.7465071,
    lon: -92.2896267,
    value: '34.7465071,-92.2896267',
  },
  {
    label: 'Littlehampton, United Kingdom',
    lat: 50.8095801,
    lon: -0.5424424,
    value: '50.8095801,-0.5424424',
  },
  {
    label: 'Littleton, Colorado, United States',
    lat: 39.613321,
    lon: -105.016649,
    value: '39.613321,-105.016649',
  },
  {
    label: 'Littleton, Massachusetts, United States',
    lat: 42.5375917,
    lon: -71.5120096,
    value: '42.5375917,-71.5120096',
  },
  {
    label: 'Livermore, California, United States',
    lat: 37.6820583,
    lon: -121.768053,
    value: '37.6820583,-121.768053',
  },
  {
    label: 'Liverpool, New York, United States',
    lat: 43.106456,
    lon: -76.217705,
    value: '43.106456,-76.217705',
  },
  {
    label: 'Livonia, Michigan, United States',
    lat: 42.36837,
    lon: -83.3527097,
    value: '42.36837,-83.3527097',
  },
  {
    label: 'Lockbourne, Ohio, United States',
    lat: 39.8092301,
    lon: -82.972404,
    value: '39.8092301,-82.972404',
  },
  {
    label: 'Lodi, New Jersey, United States',
    lat: 40.8823215,
    lon: -74.0831971,
    value: '40.8823215,-74.0831971',
  },
  {
    label: 'Lodz, Poland',
    lat: 51.7687323,
    lon: 19.4569911,
    value: '51.7687323,19.4569911',
  },
  {
    label: 'Logan Township, New Jersey, United States',
    lat: 39.794784199999995,
    lon: -75.34883104,
    value: '39.794784199999995,-75.3488310396537',
  },
  {
    label: 'Logrono, Spain',
    lat: 42.4661196,
    lon: -2.4396676,
    value: '42.4661196,-2.4396676',
  },
  {
    label: 'London, Canada',
    lat: 42.9832406,
    lon: -81.243372,
    value: '42.9832406,-81.243372',
  },
  {
    label: 'London, United Kingdom',
    lat: 51.5074456,
    lon: -0.1277653,
    value: '51.5074456,-0.1277653',
  },
  {
    label: 'Long Beach, California, United States',
    lat: 33.7690164,
    lon: -118.191604,
    value: '33.7690164,-118.191604',
  },
  {
    label: 'Longueuil, Canada',
    lat: 45.521906,
    lon: -73.4644578,
    value: '45.521906,-73.4644578',
  },
  {
    label: 'Longvic, France',
    lat: 47.2841003,
    lon: 5.0574798,
    value: '47.2841003,5.0574798',
  },
  {
    label: 'Lorton, Virginia, United States',
    lat: 38.7043397,
    lon: -77.220343,
    value: '38.7043397,-77.220343',
  },
  {
    label: 'Los Angeles, California, United States',
    lat: 34.0536909,
    lon: -118.242766,
    value: '34.0536909,-118.242766',
  },
  {
    label: 'Los Gatos, California, United States',
    lat: 37.226611,
    lon: -121.9746797,
    value: '37.226611,-121.9746797',
  },
  {
    label: 'Louisa, Virginia, United States',
    lat: 38.0250438,
    lon: -78.0035745,
    value: '38.0250438,-78.0035745',
  },
  {
    label: 'Louisville, Colorado, United States',
    lat: 39.977763,
    lon: -105.1319296,
    value: '39.977763,-105.1319296',
  },
  {
    label: 'Louisville, Kentucky, United States',
    lat: 38.2542376,
    lon: -85.759407,
    value: '38.2542376,-85.759407',
  },
  {
    label: 'Louisville, Tennessee, United States',
    lat: 35.8217479,
    lon: -84.0479643,
    value: '35.8217479,-84.0479643',
  },
  {
    label: 'Loveland, Colorado, United States',
    lat: 40.385549,
    lon: -105.0443613,
    value: '40.385549,-105.044361320949',
  },
  {
    label: 'Lowell, Arkansas, United States',
    lat: 36.2553543,
    lon: -94.1307587,
    value: '36.2553543,-94.1307587',
  },
  {
    label: 'Lubbock, Texas, United States',
    lat: 33.5855677,
    lon: -101.8470215,
    value: '33.5855677,-101.8470215',
  },
  {
    label: 'Lucknow, Uttar Pradesh, India',
    lat: 26.8381,
    lon: 80.9346001,
    value: '26.8381,80.9346001',
  },
  {
    label: 'Ludhiana, Punjab, India',
    lat: 30.9090157,
    lon: 75.851601,
    value: '30.9090157,75.851601',
  },
  {
    label: 'Lutterworth, United Kingdom',
    lat: 52.459305150000006,
    lon: -1.203445931,
    value: '52.459305150000006,-1.20344593058076',
  },
  {
    label: 'Luxembourg, Luxembourg',
    lat: 49.6112768,
    lon: 6.129799,
    value: '49.6112768,6.129799',
  },
  {
    label: 'Lyon, France',
    lat: 45.7578137,
    lon: 4.8320114,
    value: '45.7578137,4.8320114',
  },
  {
    label: 'Macchiareddu, Italy',
    lat: 39.2355695,
    lon: 8.985450228991997,
    value: '39.2355695,8.985450228991997',
  },
  {
    label: 'Machida, Japan',
    lat: 35.564193,
    lon: 139.442839,
    value: '35.564193,139.442839',
  },
  {
    label: 'Machilipatnam, Andhra Pradesh, India',
    lat: 16.1817369,
    lon: 81.1348181,
    value: '16.1817369,81.1348181',
  },
  {
    label: 'Macon, Georgia, United States',
    lat: 32.8406946,
    lon: -83.6324022,
    value: '32.8406946,-83.6324022',
  },
  {
    label: 'Macquarie Park, Australia',
    lat: -35.2511133,
    lon: 149.0633056,
    value: '-35.2511133,149.0633056',
  },
  {
    label: 'Madison, Alabama, United States',
    lat: 34.6928726,
    lon: -86.7511427,
    value: '34.6928726,-86.7511427',
  },
  {
    label: 'Madison, New Jersey, United States',
    lat: 40.7598227,
    lon: -74.417097,
    value: '40.7598227,-74.417097',
  },
  {
    label: 'Madison, Tennessee, United States',
    lat: 36.2561646,
    lon: -86.7138861,
    value: '36.2561646,-86.7138861',
  },
  {
    label: 'Madison, Wisconsin, United States',
    lat: 43.074761,
    lon: -89.3837613,
    value: '43.074761,-89.3837613',
  },
  {
    label: 'Madrid, Spain',
    lat: 40.4167047,
    lon: -3.7035825,
    value: '40.4167047,-3.7035825',
  },
  {
    label: 'Madurai, TN, India',
    lat: 9.9261153,
    lon: 78.1140983,
    value: '9.9261153,78.1140983',
  },
  {
    label: 'Maebashi, Japan',
    lat: 36.3893418,
    lon: 139.0632826,
    value: '36.3893418,139.0632826',
  },
  {
    label: 'Magdeburg, Germany',
    lat: 52.1315889,
    lon: 11.6399609,
    value: '52.1315889,11.6399609',
  },
  {
    label: "Mahadevpura, B'lore, Karnataka, India",
    lat: 14.882195,
    lon: 75.57454,
    value: '14.882195,75.57454',
  },
  {
    label: 'Maharashtra, Maharashtra, India',
    lat: 17.9677491,
    lon: 75.0908512,
    value: '17.9677491,75.0908512',
  },
  {
    label: 'Mahbub Nagar, Telangana, India',
    lat: 19.01185,
    lon: 78.101825,
    value: '19.01185,78.101825',
  },
  {
    label: 'Mahwah, New Jersey, United States',
    lat: 41.0887072,
    lon: -74.1437552,
    value: '41.0887072,-74.1437552',
  },
  {
    label: 'Maidstone, United Kingdom',
    lat: 51.2748258,
    lon: 0.5231646,
    value: '51.2748258,0.5231646',
  },
  {
    label: 'Makkah, Saudi Arabia',
    lat: 21.420847,
    lon: 39.826869,
    value: '21.420847,39.826869',
  },
  {
    label: 'Malad, Maharashtra, India',
    lat: 19.1860219,
    lon: 72.8563181,
    value: '19.1860219,72.8563181',
  },
  {
    label: 'Malaga, Spain',
    lat: 36.7213028,
    lon: -4.4216366,
    value: '36.7213028,-4.4216366',
  },
  {
    label: 'Malappuram, Kerala, India',
    lat: 11.0428925,
    lon: 76.0807838,
    value: '11.0428925,76.0807838',
  },
  {
    label: 'Malvern, Pennsylvania, United States',
    lat: 40.036202,
    lon: -75.5138959,
    value: '40.036202,-75.5138959',
  },
  {
    label: 'Manama, Bahrain',
    lat: 26.2235041,
    lon: 50.5822436,
    value: '26.2235041,50.5822436',
  },
  {
    label: 'Manassas, Virginia, United States',
    lat: 38.7509488,
    lon: -77.4752667,
    value: '38.7509488,-77.4752667',
  },
  {
    label: 'Manchester, Connecticut, United States',
    lat: 41.7834017,
    lon: -72.5231973,
    value: '41.7834017,-72.5231973',
  },
  {
    label: 'Manchester, New Hampshire, United States',
    lat: 42.9956397,
    lon: -71.4547891,
    value: '42.9956397,-71.4547891',
  },
  {
    label: 'Manchester, Pennsylvania, United States',
    lat: 40.0588102,
    lon: -76.7211368,
    value: '40.0588102,-76.7211368',
  },
  {
    label: 'Manchester, United Kingdom',
    lat: 53.4794892,
    lon: -2.2451148,
    value: '53.4794892,-2.2451148',
  },
  {
    label: 'Mandya, Karnataka, India',
    lat: 12.5238888,
    lon: 76.8961961,
    value: '12.5238888,76.8961961',
  },
  {
    label: 'Manesar, Haryana, India',
    lat: 28.3617283,
    lon: 76.9402153,
    value: '28.3617283,76.9402153',
  },
  {
    label: 'Mangalagiri, Andhra Pradesh, India',
    lat: 16.4318209,
    lon: 80.5688069,
    value: '16.4318209,80.5688069',
  },
  {
    label: 'Mangalore, Karnataka, India',
    lat: 15.9231076,
    lon: 75.7622963,
    value: '15.9231076,75.7622963',
  },
  {
    label: 'Manila, Philippines',
    lat: 14.5906346,
    lon: 120.9799964,
    value: '14.5906346,120.9799964',
  },
  {
    label: 'Manipal, Karnataka, India',
    lat: 13.3519723,
    lon: 74.7870308,
    value: '13.3519723,74.7870308',
  },
  {
    label: 'Mannheim, Germany',
    lat: 49.4892913,
    lon: 8.4673098,
    value: '49.4892913,8.4673098',
  },
  {
    label: 'Mansfield, Massachusetts, United States',
    lat: 42.0334326,
    lon: -71.2189405,
    value: '42.0334326,-71.2189405',
  },
  {
    label: 'Mansfield, Texas, United States',
    lat: 32.563487,
    lon: -97.1420308,
    value: '32.563487,-97.1420308',
  },
  {
    label: 'Mansfield, United Kingdom',
    lat: 53.1443785,
    lon: -1.1964165,
    value: '53.1443785,-1.1964165',
  },
  {
    label: 'Mansoura, Egypt',
    lat: 28.075892099999997,
    lon: 30.750367351843558,
    value: '28.075892099999997,30.750367351843558',
  },
  {
    label: 'Manteca, California, United States',
    lat: 37.81623435,
    lon: -121.2217882,
    value: '37.81623435,-121.221788153852',
  },
  {
    label: 'Maple Grove, Minnesota, United States',
    lat: 45.0984983,
    lon: -93.4419222,
    value: '45.0984983,-93.4419222',
  },
  {
    label: 'Maple Valley, Washington, United States',
    lat: 47.3664231,
    lon: -122.0437127,
    value: '47.3664231,-122.0437127',
  },
  {
    label: 'Maraimalai Nagar, TN, India',
    lat: 12.7843041,
    lon: 80.02960345848473,
    value: '12.7843041,80.02960345848473',
  },
  {
    label: 'Markham, Illinois, United States',
    lat: 39.7458798,
    lon: -90.3279008,
    value: '39.7458798,-90.3279008',
  },
  {
    label: 'Marseille, France',
    lat: 43.2961743,
    lon: 5.3699525,
    value: '43.2961743,5.3699525',
  },
  {
    label: 'Martorelles, Spain',
    lat: 41.52851,
    lon: 2.2374755,
    value: '41.52851,2.2374755',
  },
  {
    label: 'Maryland Heights, Missouri, United States',
    lat: 38.7150511,
    lon: -90.435999,
    value: '38.7150511,-90.435999',
  },
  {
    label: 'Maryville, Tennessee, United States',
    lat: 35.7564719,
    lon: -83.9704593,
    value: '35.7564719,-83.9704593',
  },
  {
    label: 'Marzano, Italy',
    lat: 45.247308,
    lon: 9.2953889,
    value: '45.247308,9.2953889',
  },
  {
    label: 'Maspeth, New York, United States',
    lat: 40.723158,
    lon: -73.912637,
    value: '40.723158,-73.912637',
  },
  {
    label: 'Matteson, Illinois, United States',
    lat: 41.509832,
    lon: -87.739267,
    value: '41.509832,-87.739267',
  },
  {
    label: 'Maumelle, Arkansas, United States',
    lat: 34.856613,
    lon: -92.4056858,
    value: '34.856613,-92.4056858',
  },
  {
    label: 'Mayapuri, Delhi, Delhi, India',
    lat: 28.6371795,
    lon: 77.1297333,
    value: '28.6371795,77.1297333',
  },
  {
    label: 'Mayfield Village, Ohio, United States',
    lat: 41.536382849999995,
    lon: -81.45873093,
    value: '41.536382849999995,-81.4587309256664',
  },
  {
    label: 'McAllen, Texas, United States',
    lat: 26.204114,
    lon: -98.2300605,
    value: '26.204114,-98.2300605',
  },
  {
    label: 'McDonough, Georgia, United States',
    lat: 33.4473361,
    lon: -84.1468616,
    value: '33.4473361,-84.1468616',
  },
  {
    label: 'McKinney, Texas, United States',
    lat: 33.1976496,
    lon: -96.6154471,
    value: '33.1976496,-96.6154471',
  },
  {
    label: 'Mebane, North Carolina, United States',
    lat: 36.0959715,
    lon: -79.2669619,
    value: '36.0959715,-79.2669619',
  },
  {
    label: 'Medellin, Colombia',
    lat: 6.2433515,
    lon: -75.5752006,
    value: '6.2433515,-75.5752006',
  },
  {
    label: 'Medford, Massachusetts, United States',
    lat: 42.4184296,
    lon: -71.1061639,
    value: '42.4184296,-71.1061639',
  },
  {
    label: 'Medina, Saudi Arabia',
    lat: 24.471153,
    lon: 39.6111216,
    value: '24.471153,39.6111216',
  },
  {
    label: 'Meerut, Uttar Pradesh, India',
    lat: 28.9826533,
    lon: 77.7081013,
    value: '28.9826533,77.7081013',
  },
  {
    label: 'Mehsana, Gujarat, India',
    lat: 23.6015557,
    lon: 72.3867981,
    value: '23.6015557,72.3867981',
  },
  {
    label: 'Melbourne, Australia',
    lat: -37.8142454,
    lon: 144.9631732,
    value: '-37.8142454,144.9631732',
  },
  {
    label: 'Melrose Park, Illinois, United States',
    lat: 41.900299,
    lon: -87.8569745,
    value: '41.900299,-87.8569745',
  },
  {
    label: 'Melville, New York, United States',
    lat: 40.7934322,
    lon: -73.4151214,
    value: '40.7934322,-73.4151214',
  },
  {
    label: 'Memphis, Tennessee, United States',
    lat: 35.1460249,
    lon: -90.0517638,
    value: '35.1460249,-90.0517638',
  },
  {
    label: 'Merida, Mexico',
    lat: 20.9670759,
    lon: -89.6237402,
    value: '20.9670759,-89.6237402',
  },
  {
    label: 'Merida, Spain',
    lat: 38.9174665,
    lon: -6.3443977,
    value: '38.9174665,-6.3443977',
  },
  {
    label: 'Meridian, Idaho, United States',
    lat: 43.6086295,
    lon: -116.392326,
    value: '43.6086295,-116.392326',
  },
  {
    label: 'Mesa, Arizona, United States',
    lat: 33.4151005,
    lon: -111.831455,
    value: '33.4151005,-111.831455',
  },
  {
    label: 'Mesquite, Texas, United States',
    lat: 32.7666103,
    lon: -96.599472,
    value: '32.7666103,-96.599472',
  },
  {
    label: 'Messina, Italy',
    lat: 38.1937571,
    lon: 15.5542082,
    value: '38.1937571,15.5542082',
  },
  {
    label: 'Messkirch, Germany',
    lat: 47.9939249,
    lon: 9.1122006,
    value: '47.9939249,9.1122006',
  },
  {
    label: 'Mexico City, Mexico',
    lat: 19.4326296,
    lon: -99.1331785,
    value: '19.4326296,-99.1331785',
  },
  {
    label: 'Mezzate, Italy',
    lat: 45.4465182,
    lon: 9.2938253,
    value: '45.4465182,9.2938253',
  },
  {
    label: 'Miami, Florida, United States',
    lat: 25.7741728,
    lon: -80.19362,
    value: '25.7741728,-80.19362',
  },
  {
    label: 'Middleborough, Massachusetts, United States',
    lat: 41.8931576,
    lon: -70.9111517,
    value: '41.8931576,-70.9111517',
  },
  {
    label: 'Middleburg Heights, Ohio, United States',
    lat: 41.3614401,
    lon: -81.812912,
    value: '41.3614401,-81.812912',
  },
  {
    label: 'Middlesex, New Jersey, United States',
    lat: 40.5734911,
    lon: -74.494037,
    value: '40.5734911,-74.494037',
  },
  {
    label: 'Middletown, Delaware, United States',
    lat: 39.4497639,
    lon: -75.7166345,
    value: '39.4497639,-75.7166345',
  },
  {
    label: 'Middletown, New Jersey, United States',
    lat: 40.9159321,
    lon: -74.51349,
    value: '40.9159321,-74.51349',
  },
  {
    label: 'Midnapore, West Bengal, India',
    lat: 23.41146995,
    lon: 87.84499444913176,
    value: '23.41146995,87.84499444913176',
  },
  {
    label: 'Milan, Italy',
    lat: 45.4641943,
    lon: 9.1896346,
    value: '45.4641943,9.1896346',
  },
  {
    label: 'Milano, Italy',
    lat: 42.7823859,
    lon: 12.5980599,
    value: '42.7823859,12.5980599',
  },
  {
    label: 'Milford, Massachusetts, United States',
    lat: 42.1413353,
    lon: -71.5192019,
    value: '42.1413353,-71.5192019',
  },
  {
    label: 'Mills River, North Carolina, United States',
    lat: 35.3884479,
    lon: -82.566789,
    value: '35.3884479,-82.566789',
  },
  {
    label: 'Milpitas, California, United States',
    lat: 37.4332273,
    lon: -121.8989248,
    value: '37.4332273,-121.8989248',
  },
  {
    label: 'Milton Keynes, United Kingdom',
    lat: 52.0406502,
    lon: -0.7594092,
    value: '52.0406502,-0.7594092',
  },
  {
    label: 'Milton, Florida, United States',
    lat: 30.6324149,
    lon: -87.0396881,
    value: '30.6324149,-87.0396881',
  },
  {
    label: 'Milwaukee, Wisconsin, United States',
    lat: 43.0349931,
    lon: -87.922497,
    value: '43.0349931,-87.922497',
  },
  {
    label: 'Minneapolis, Minnesota, United States',
    lat: 44.9772995,
    lon: -93.2654692,
    value: '44.9772995,-93.2654692',
  },
  {
    label: 'Mira Loma, California, United States',
    lat: 33.9863913,
    lon: -117.5227325,
    value: '33.9863913,-117.5227325',
  },
  {
    label: 'Mishima, Japan',
    lat: 35.147361,
    lon: 138.948903,
    value: '35.147361,138.948903',
  },
  {
    label: 'Mission Viejo, California, United States',
    lat: 33.5965685,
    lon: -117.659405,
    value: '33.5965685,-117.659405',
  },
  {
    label: 'Mississauga, Canada',
    lat: 43.5896231,
    lon: -79.6443879,
    value: '43.5896231,-79.6443879',
  },
  {
    label: 'Missoula, Montana, United States',
    lat: 46.8701049,
    lon: -113.995267,
    value: '46.8701049,-113.995267',
  },
  {
    label: 'Miyagi, Japan',
    lat: 38.3880155,
    lon: 140.9761025,
    value: '38.3880155,140.9761025',
  },
  {
    label: 'Moenchengladbach, Germany',
    lat: 51.1947131,
    lon: 6.4353792,
    value: '51.1947131,6.4353792',
  },
  {
    label: 'Mollet del Vallès, Spain',
    lat: 41.5393484,
    lon: 2.2130934,
    value: '41.5393484,2.2130934',
  },
  {
    label: 'Monee, Illinois, United States',
    lat: 41.420034,
    lon: -87.7417115,
    value: '41.420034,-87.7417115',
  },
  {
    label: 'Monroe Township, New Jersey, United States',
    lat: 40.335756,
    lon: -74.4334464,
    value: '40.335756,-74.4334464',
  },
  {
    label: 'Monroe, Ohio, United States',
    lat: 39.4432691,
    lon: -84.363365,
    value: '39.4432691,-84.363365',
  },
  {
    label: 'Montcada i Reixac, Spain',
    lat: 41.4823115,
    lon: 2.1869985,
    value: '41.4823115,2.1869985',
  },
  {
    label: 'Montelimar, France',
    lat: 44.5579391,
    lon: 4.750318,
    value: '44.5579391,4.750318',
  },
  {
    label: 'Monterrey, Mexico',
    lat: 25.6802019,
    lon: -100.315258,
    value: '25.6802019,-100.315258',
  },
  {
    label: 'Montgomery, Pennsylvania, United States',
    lat: 41.1703592,
    lon: -76.8769076,
    value: '41.1703592,-76.8769076',
  },
  {
    label: 'Montreal, Canada',
    lat: 45.5031824,
    lon: -73.5698065,
    value: '45.5031824,-73.5698065',
  },
  {
    label: 'Moonachie, New Jersey, United States',
    lat: 40.8412112,
    lon: -74.0451405,
    value: '40.8412112,-74.0451405',
  },
  {
    label: 'Moorebank, Australia',
    lat: -33.9332633,
    lon: 150.949686,
    value: '-33.9332633,150.949686',
  },
  {
    label: 'Moorpark, California, United States',
    lat: 34.285558,
    lon: -118.8820414,
    value: '34.285558,-118.8820414',
  },
  {
    label: 'Moosburg, Germany',
    lat: 48.4667,
    lon: 11.9333,
    value: '48.4667,11.9333',
  },
  {
    label: 'Moradabad, Uttar Pradesh, India',
    lat: 28.8334982,
    lon: 78.7732864,
    value: '28.8334982,78.7732864',
  },
  {
    label: 'Moreland, Georgia, United States',
    lat: 33.2867851,
    lon: -84.7677112,
    value: '33.2867851,-84.7677112',
  },
  {
    label: 'Moreno Valley, California, United States',
    lat: 33.937517,
    lon: -117.2305944,
    value: '33.937517,-117.2305944',
  },
  {
    label: 'Morioka, Japan',
    lat: 39.7021331,
    lon: 141.1545397,
    value: '39.7021331,141.1545397',
  },
  {
    label: 'Moriyama, Japan',
    lat: 35.0852735,
    lon: 135.9677855,
    value: '35.0852735,135.9677855',
  },
  {
    label: 'Morlaas, France',
    lat: 43.3454394,
    lon: -0.2649452,
    value: '43.3454394,-0.2649452',
  },
  {
    label: 'Morocco, Morocco',
    lat: 31.1728205,
    lon: -7.3362482,
    value: '31.1728205,-7.3362482',
  },
  {
    label: 'Morrisville, North Carolina, United States',
    lat: 35.824341,
    lon: -78.8300321,
    value: '35.824341,-78.8300321',
  },
  {
    label: 'Morton Grove, Illinois, United States',
    lat: 42.0405857,
    lon: -87.7825696,
    value: '42.0405857,-87.7825696',
  },
  {
    label: 'Móstoles, Spain',
    lat: 40.3238525,
    lon: -3.8649214,
    value: '40.3238525,-3.8649214',
  },
  {
    label: 'Moult, France',
    lat: 49.115483,
    lon: -0.1657186,
    value: '49.115483,-0.1657186',
  },
  {
    label: 'Mount Joy, Pennsylvania, United States',
    lat: 40.1098561,
    lon: -76.5033406,
    value: '40.1098561,-76.5033406',
  },
  {
    label: 'Mount Pleasant, Michigan, United States',
    lat: 43.597646,
    lon: -84.7668495,
    value: '43.597646,-84.7668495',
  },
  {
    label: 'Mount Pleasant, South Carolina, United States',
    lat: 32.7940651,
    lon: -79.8625851,
    value: '32.7940651,-79.8625851',
  },
  {
    label: 'Mt. Juliet, Tennessee, United States',
    lat: 36.200055,
    lon: -86.5186054,
    value: '36.200055,-86.5186054',
  },
  {
    label: 'Muenchen, Germany',
    lat: 50.8699757,
    lon: 11.2535193,
    value: '50.8699757,11.2535193',
  },
  {
    label: 'Mulgrave, Australia',
    lat: -37.9261741,
    lon: 145.1723107,
    value: '-37.9261741,145.1723107',
  },
  {
    label: 'Mumabi, Maharashtra, India',
    lat: 19.405779,
    lon: 75.70204,
    value: '19.405779,75.70204',
  },
  {
    label: 'Mumbai, Maharashtra, India',
    lat: 19.0785451,
    lon: 72.878176,
    value: '19.0785451,72.878176',
  },
  {
    label: 'München, Germany',
    lat: 51.6066028,
    lon: 13.3125927,
    value: '51.6066028,13.3125927',
  },
  {
    label: 'Munich, Germany',
    lat: 48.1371079,
    lon: 11.5753822,
    value: '48.1371079,11.5753822',
  },
  {
    label: 'Murcia, Spain',
    lat: 37.9923795,
    lon: -1.1305431,
    value: '37.9923795,-1.1305431',
  },
  {
    label: 'Murfreesboro, Tennessee, United States',
    lat: 35.8460396,
    lon: -86.3921096,
    value: '35.8460396,-86.3921096',
  },
  {
    label: 'Murrieta, California, United States',
    lat: 33.560832,
    lon: -117.210656,
    value: '33.560832,-117.210656',
  },
  {
    label: 'Murshidabad, West Bengal, India',
    lat: 24.1745993,
    lon: 88.2721335,
    value: '24.1745993,88.2721335',
  },
  {
    label: 'Muzaffarnagar, Uttar Pradesh, India',
    lat: 29.4662369,
    lon: 77.6961699,
    value: '29.4662369,77.6961699',
  },
  {
    label: 'Muzaffarpur, Bihar, India',
    lat: 26.1182898,
    lon: 85.3857888,
    value: '26.1182898,85.3857888',
  },
  {
    label: 'Mysore, Karnataka, India',
    lat: 12.3051828,
    lon: 76.6553609,
    value: '12.3051828,76.6553609',
  },
  {
    label: 'NA, Andhra Pradesh, India',
    lat: 17.4268432,
    lon: 78.48517323211993,
    value: '17.4268432,78.48517323211993',
  },
  {
    label: 'Nagano, Japan',
    lat: 36.1143945,
    lon: 138.0319015,
    value: '36.1143945,138.0319015',
  },
  {
    label: 'Nagareyama, Japan',
    lat: 35.8562314,
    lon: 139.9026259,
    value: '35.8562314,139.9026259',
  },
  {
    label: 'Nagercoil, TN, India',
    lat: 8.1880471,
    lon: 77.4290492,
    value: '8.1880471,77.4290492',
  },
  {
    label: 'Nagoya, Japan',
    lat: 35.1851045,
    lon: 136.8998438,
    value: '35.1851045,136.8998438',
  },
  {
    label: 'Nagpur, Maharashtra, India',
    lat: 21.1498134,
    lon: 79.0820556,
    value: '21.1498134,79.0820556',
  },
  {
    label: 'Nairobi, Kenya',
    lat: -1.2832533,
    lon: 36.8172449,
    value: '-1.2832533,36.8172449',
  },
  {
    label: 'Nalgonda, Telangana, India',
    lat: 17.0504406,
    lon: 79.2669235,
    value: '17.0504406,79.2669235',
  },
  {
    label: 'Nampa, Idaho, United States',
    lat: 43.5737361,
    lon: -116.559631,
    value: '43.5737361,-116.559631',
  },
  {
    label: 'Nanded, Maharashtra, India',
    lat: 19.17257215,
    lon: 77.29141237169956,
    value: '19.17257215,77.29141237169956',
  },
  // {
  //   "label": "Nanital, UT, India",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Nanjing, China',
    lat: 24.5167811,
    lon: 117.3523949,
    value: '24.5167811,117.3523949',
  },
  {
    label: 'Nantes, France',
    lat: 47.2186371,
    lon: -1.5541362,
    value: '47.2186371,-1.5541362',
  },
  {
    label: 'Naperville, Illinois, United States',
    lat: 41.7728699,
    lon: -88.1479278,
    value: '41.7728699,-88.1479278',
  },
  {
    label: 'Naples, Florida, United States',
    lat: 26.1421976,
    lon: -81.7942944,
    value: '26.1421976,-81.7942944',
  },
  {
    label: 'Narashino, Japan',
    lat: 35.670572,
    lon: 140.018956,
    value: '35.670572,140.018956',
  },
  {
    label: 'Narita, Japan',
    lat: 35.7767683,
    lon: 140.3183376,
    value: '35.7767683,140.3183376',
  },
  {
    label: 'Nashua, New Hampshire, United States',
    lat: 42.7656251,
    lon: -71.4677032,
    value: '42.7656251,-71.4677032',
  },
  {
    label: 'Nashville, Tennessee, United States',
    lat: 36.1622767,
    lon: -86.7742984,
    value: '36.1622767,-86.7742984',
  },
  {
    label: 'Nasik, Maharashtra, India',
    lat: 20.22545585,
    lon: 74.10423303714919,
    value: '20.22545585,74.10423303714919',
  },
  {
    label: 'National City, California, United States',
    lat: 32.6781085,
    lon: -117.0991967,
    value: '32.6781085,-117.0991967',
  },
  {
    label: 'Navi Mumbai, Maharashtra, India',
    lat: 19.0308262,
    lon: 73.0198537,
    value: '19.0308262,73.0198537',
  },
  {
    label: 'Nellore, Andhra Pradesh, India',
    lat: 14.4493717,
    lon: 79.9873763,
    value: '14.4493717,79.9873763',
  },
  {
    label: 'Neu Wulmstorf, Germany',
    lat: 53.4682413,
    lon: 9.7916569,
    value: '53.4682413,9.7916569',
  },
  {
    label: 'Neubrandenburg, Germany',
    lat: 53.5574458,
    lon: 13.2602781,
    value: '53.5574458,13.2602781',
  },
  {
    label: 'Neuenburg am Rhein, Germany',
    lat: 47.8155916,
    lon: 7.5627871,
    value: '47.8155916,7.5627871',
  },
  {
    label: 'Neuwied, Germany',
    lat: 50.4302393,
    lon: 7.466302,
    value: '50.4302393,7.466302',
  },
  {
    label: 'New Albany, Ohio, United States',
    lat: 40.942281,
    lon: -80.8359159,
    value: '40.942281,-80.8359159',
  },
  {
    label: 'New Berlin, Wisconsin, United States',
    lat: 42.976453,
    lon: -88.1090391,
    value: '42.976453,-88.1090391',
  },
  {
    label: 'New Castle, Delaware, United States',
    lat: 39.6620572,
    lon: -75.5663132,
    value: '39.6620572,-75.5663132',
  },
  {
    label: 'New Century, Kansas, United States',
    lat: 38.825165,
    lon: -94.830121,
    value: '38.825165,-94.830121',
  },
  {
    label: 'New Delhi, Delhi, India',
    lat: 28.6138954,
    lon: 77.2090057,
    value: '28.6138954,77.2090057',
  },
  {
    label: 'New DL, Delhi, India',
    lat: 28.6138954,
    lon: 77.2090057,
    value: '28.6138954,77.2090057',
  },
  {
    label: 'New Westminster, Canada',
    lat: 49.2067726,
    lon: -122.9108818,
    value: '49.2067726,-122.9108818',
  },
  {
    label: 'New Windsor, New York, United States',
    lat: 41.4767605,
    lon: -74.0237519,
    value: '41.4767605,-74.0237519',
  },
  {
    label: 'New York, New York, United States',
    lat: 40.7127281,
    lon: -74.0060152,
    value: '40.7127281,-74.0060152',
  },
  {
    label: 'Newark, California, United States',
    lat: 37.5296593,
    lon: -122.0402399,
    value: '37.5296593,-122.0402399',
  },
  {
    label: 'Newark, New Jersey, United States',
    lat: 40.735657,
    lon: -74.1723667,
    value: '40.735657,-74.1723667',
  },
  {
    label: 'Newcastle, United Kingdom',
    lat: 54.2118323,
    lon: -5.8897216,
    value: '54.2118323,-5.8897216',
  },
  {
    label: 'Newington, Connecticut, United States',
    lat: 41.6978777,
    lon: -72.7237063,
    value: '41.6978777,-72.7237063',
  },
  {
    label: 'Niigata, Japan',
    lat: 37.6452283,
    lon: 138.7669125,
    value: '37.6452283,138.7669125',
  },
  {
    label: 'Ningbo, China',
    lat: 29.8622194,
    lon: 121.6203873,
    value: '29.8622194,121.6203873',
  },
  {
    label: 'Nishinomiya-shi, Hyogo, Japan',
    lat: 34.7386033,
    lon: 135.3394138,
    value: '34.7386033,135.3394138',
  },
  {
    label: 'Nisku, Canada',
    lat: 53.333333,
    lon: -113.533333,
    value: '53.333333,-113.533333',
  },
  {
    label: 'Nittenau, Germany',
    lat: 49.1980429,
    lon: 12.2718828,
    value: '49.1980429,12.2718828',
  },
  {
    label: 'Nizamabad, Telangana, India',
    lat: 18.6732693,
    lon: 78.0978477,
    value: '18.6732693,78.0978477',
  },
  {
    label: 'Noda, Japan',
    lat: 35.9549076,
    lon: 139.8748667,
    value: '35.9549076,139.8748667',
  },
  {
    label: 'Noida, Uttar Pradesh, India',
    lat: 28.5706333,
    lon: 77.3272147,
    value: '28.5706333,77.3272147',
  },
  {
    label: 'Noisy-le-Grand, France',
    lat: 48.8478586,
    lon: 2.5529612,
    value: '48.8478586,2.5529612',
  },
  {
    label: 'Norcross, Georgia, United States',
    lat: 33.9412127,
    lon: -84.2135309,
    value: '33.9412127,-84.2135309',
  },
  {
    label: 'Norfolk, Virginia, United States',
    lat: 36.8448348,
    lon: -76.2863999,
    value: '36.8448348,-76.2863999',
  },
  {
    label: 'Norridge, Illinois, United States',
    lat: 41.9633641,
    lon: -87.827284,
    value: '41.9633641,-87.827284',
  },
  {
    label: 'Norristown, Pennsylvania, United States',
    lat: 40.121497,
    lon: -75.3399048,
    value: '40.121497,-75.3399048',
  },
  {
    label: 'North Andover, Massachusetts, United States',
    lat: 42.6839894,
    lon: -71.1127065,
    value: '42.6839894,-71.1127065',
  },
  {
    label: 'North Birmingham, United Kingdom',
    lat: 52.405705999999995,
    lon: -1.941447974,
    value: '52.405705999999995,-1.94144797352941',
  },
  {
    label: 'North East, Maryland, United States',
    lat: 39.5992989,
    lon: -75.9424217,
    value: '39.5992989,-75.9424217',
  },
  {
    label: 'North Haven, Connecticut, United States',
    lat: 41.3909305,
    lon: -72.859545,
    value: '41.3909305,-72.859545',
  },
  {
    label: 'North Hollywood, California, United States',
    lat: 34.1649502,
    lon: -118.374752,
    value: '34.1649502,-118.374752',
  },
  {
    label: 'North Jackson, Ohio, United States',
    lat: 41.1001272,
    lon: -80.8571741,
    value: '41.1001272,-80.8571741',
  },
  {
    label: 'North Las Vegas, Nevada, United States',
    lat: 36.2005843,
    lon: -115.121584,
    value: '36.2005843,-115.121584',
  },
  {
    label: 'North Little Rock, Arkansas, United States',
    lat: 34.769536,
    lon: -92.2670941,
    value: '34.769536,-92.2670941',
  },
  {
    label: 'North Pekin, Illinois, United States',
    lat: 40.6150384,
    lon: -89.6223229,
    value: '40.6150384,-89.6223229',
  },
  {
    label: 'North Randall, Ohio, United States',
    lat: 41.434424,
    lon: -81.525492,
    value: '41.434424,-81.525492',
  },
  {
    label: 'North Reading, Massachusetts, United States',
    lat: 42.5750939,
    lon: -71.0786653,
    value: '42.5750939,-71.0786653',
  },
  {
    label: 'North Riverside, Illinois, United States',
    lat: 41.84686,
    lon: -87.8152024,
    value: '41.84686,-87.8152024',
  },
  {
    label: 'North Salt Lake, Utah, United States',
    lat: 40.8485564,
    lon: -111.906882,
    value: '40.8485564,-111.906882',
  },
  {
    label: 'North Versailles, Pennsylvania, United States',
    lat: 40.3797913,
    lon: -79.8094916,
    value: '40.3797913,-79.8094916',
  },
  {
    label: 'Northborough, Massachusetts, United States',
    lat: 42.3196015,
    lon: -71.6422408,
    value: '42.3196015,-71.6422408',
  },
  {
    label: 'Northridge, California, United States',
    lat: 34.2345615,
    lon: -118.5369316,
    value: '34.2345615,-118.5369316',
  },
  {
    label: 'Norwich, United Kingdom',
    lat: 52.6285576,
    lon: 1.2923954,
    value: '52.6285576,1.2923954',
  },
  {
    label: 'Norwood, Massachusetts, United States',
    lat: 42.194543,
    lon: -71.1994976,
    value: '42.194543,-71.1994976',
  },
  {
    label: 'Nova Santa Rita, Brazil',
    lat: -29.8489972,
    lon: -51.2764275,
    value: '-29.8489972,-51.2764275',
  },
  {
    label: 'Nuetzen, Germany',
    lat: 53.8653583,
    lon: 9.9207809,
    value: '53.8653583,9.9207809',
  },
  {
    label: 'Nuremberg, Germany',
    lat: 49.453872,
    lon: 11.077298,
    value: '49.453872,11.077298',
  },
  {
    label: 'O Porriño, Spain',
    lat: 42.1618812,
    lon: -8.6202683,
    value: '42.1618812,-8.6202683',
  },
  {
    label: 'Oak Creek, Wisconsin, United States',
    lat: 42.898729,
    lon: -87.9177966,
    value: '42.898729,-87.9177966',
  },
  {
    label: 'Oak Lawn, Illinois, United States',
    lat: 41.7108662,
    lon: -87.7581081,
    value: '41.7108662,-87.7581081',
  },
  {
    label: 'Oakland, California, United States',
    lat: 37.8044557,
    lon: -122.271356,
    value: '37.8044557,-122.271356',
  },
  {
    label: 'Oakley, California, United States',
    lat: 37.9974219,
    lon: -121.7124536,
    value: '37.9974219,-121.7124536',
  },
  {
    label: 'Oakville, Canada',
    lat: 43.447436,
    lon: -79.666672,
    value: '43.447436,-79.666672',
  },
  {
    label: 'Oberhausen, Germany',
    lat: 51.4696137,
    lon: 6.8514435,
    value: '51.4696137,6.8514435',
  },
  {
    label: 'Obetz, Ohio, United States',
    lat: 39.8789527,
    lon: -82.9507363,
    value: '39.8789527,-82.9507363',
  },
  {
    label: 'Ocala, Florida, United States',
    lat: 29.1871986,
    lon: -82.1400923,
    value: '29.1871986,-82.1400923',
  },
  {
    label: 'Ocean, New Jersey, United States',
    lat: 39.2776156,
    lon: -74.5746001,
    value: '39.2776156,-74.5746001',
  },
  {
    label: 'Oceanside, New York, United States',
    lat: 40.6390936,
    lon: -73.6399765,
    value: '40.6390936,-73.6399765',
  },
  {
    label: 'Odawara, Japan',
    lat: 35.263676,
    lon: 139.150229,
    value: '35.263676,139.150229',
  },
  {
    label: 'Odessa, Texas, United States',
    lat: 31.8457149,
    lon: -102.367687,
    value: '31.8457149,-102.367687',
  },
  {
    label: 'Oelde, Germany',
    lat: 51.8260823,
    lon: 8.1453352,
    value: '51.8260823,8.1453352',
  },
  {
    label: 'Oerlenbach, Germany',
    lat: 50.1432164,
    lon: 10.1340843,
    value: '50.1432164,10.1340843',
  },
  {
    label: 'Okayama, Japan',
    lat: 34.6553944,
    lon: 133.9194595,
    value: '34.6553944,133.9194595',
  },
  {
    label: 'Okazaki, Japan',
    lat: 34.950974,
    lon: 137.260842,
    value: '34.950974,137.260842',
  },
  {
    label: 'Okhla, Delhi, India',
    lat: 30.45685,
    lon: 78.44788,
    value: '30.45685,78.44788',
  },
  {
    label: 'Okinawa, Japan',
    lat: 26.3343738,
    lon: 127.8056597,
    value: '26.3343738,127.8056597',
  },
  {
    label: 'Oklahoma City, Oklahoma, United States',
    lat: 35.4729886,
    lon: -97.5170536,
    value: '35.4729886,-97.5170536',
  },
  {
    label: 'Okmiany, Poland',
    lat: 51.2615543,
    lon: 15.7860835,
    value: '51.2615543,15.7860835',
  },
  {
    label: 'Olathe, Kansas, United States',
    lat: 38.8838856,
    lon: -94.81887,
    value: '38.8838856,-94.81887',
  },
  {
    label: 'Olching, Germany',
    lat: 48.2036863,
    lon: 11.3311557,
    value: '48.2036863,11.3311557',
  },
  {
    label: 'Olive Branch, Mississippi, United States',
    lat: 34.9617605,
    lon: -89.8295315,
    value: '34.9617605,-89.8295315',
  },
  {
    label: 'Omaha, Nebraska, United States',
    lat: 41.2587459,
    lon: -95.9383758,
    value: '41.2587459,-95.9383758',
  },
  {
    label: 'Ome, Japan',
    lat: 35.803601,
    lon: 139.238128,
    value: '35.803601,139.238128',
  },
  {
    label: 'Onda, Spain',
    lat: 39.9621514,
    lon: -0.2593846,
    value: '39.9621514,-0.2593846',
  },
  {
    label: 'Ontario, California, United States',
    lat: 34.065846,
    lon: -117.64843,
    value: '34.065846,-117.64843',
  },
  {
    label: 'Ontigola, Spain',
    lat: 40.00348,
    lon: -3.572782,
    value: '40.00348,-3.572782',
  },
  {
    label: 'Opa-Locka, Florida, United States',
    lat: 25.9023168,
    lon: -80.2503271,
    value: '25.9023168,-80.2503271',
  },
  {
    label: 'Orange, Connecticut, United States',
    lat: 41.2784304,
    lon: -73.0256609,
    value: '41.2784304,-73.0256609',
  },
  {
    label: 'Origgio, Italy',
    lat: 45.5976933,
    lon: 9.0180613,
    value: '45.5976933,9.0180613',
  },
  {
    label: 'Orland, California, United States',
    lat: 39.7471106,
    lon: -122.191135,
    value: '39.7471106,-122.191135',
  },
  {
    label: 'Orlando, Florida, United States',
    lat: 28.5421109,
    lon: -81.3790304,
    value: '28.5421109,-81.3790304',
  },
  {
    label: 'Orleans, France',
    lat: 47.9027336,
    lon: 1.9086066,
    value: '47.9027336,1.9086066',
  },
  {
    label: 'Orly, France',
    lat: 48.7431683,
    lon: 2.402391,
    value: '48.7431683,2.402391',
  },
  {
    label: 'Ormes, France',
    lat: 49.0533673,
    lon: 0.9634334,
    value: '49.0533673,0.9634334',
  },
  {
    label: 'Orpington, United Kingdom',
    lat: 51.3796373,
    lon: 0.1053732,
    value: '51.3796373,0.1053732',
  },
  {
    label: 'Osaka, Japan',
    lat: 34.6937569,
    lon: 135.5014539,
    value: '34.6937569,135.5014539',
  },
  {
    label: 'Oshkosh, Wisconsin, United States',
    lat: 44.0206919,
    lon: -88.5408574,
    value: '44.0206919,-88.5408574',
  },
  {
    label: 'Oslo, Norway',
    lat: 59.9133301,
    lon: 10.7389701,
    value: '59.9133301,10.7389701',
  },
  {
    label: 'Ota-ku, Japan',
    lat: 35.561206,
    lon: 139.715843,
    value: '35.561206,139.715843',
  },
  {
    label: 'Ota, Japan',
    lat: 35.561206,
    lon: 139.715843,
    value: '35.561206,139.715843',
  },
  {
    label: 'Ottawa, Canada',
    lat: 45.4208777,
    lon: -75.6901106,
    value: '45.4208777,-75.6901106',
  },
  {
    label: 'Oxnard, California, United States',
    lat: 34.1976308,
    lon: -119.180381,
    value: '34.1976308,-119.180381',
  },
  {
    label: 'Pabianice, Poland',
    lat: 51.6639859,
    lon: 19.3535024,
    value: '51.6639859,19.3535024',
  },
  {
    label: 'Paderborn, Germany',
    lat: 51.7177044,
    lon: 8.752653,
    value: '51.7177044,8.752653',
  },
  {
    label: 'Padova, Italy',
    lat: 45.50193505,
    lon: 9.230626210500741,
    value: '45.50193505,9.230626210500741',
  },
  {
    label: 'Palatine, Illinois, United States',
    lat: 42.1105779,
    lon: -88.0336888,
    value: '42.1105779,-88.0336888',
  },
  {
    label: 'Palermo, Italy',
    lat: 38.1112268,
    lon: 13.3524434,
    value: '38.1112268,13.3524434',
  },
  {
    label: 'Palm Beach, Florida, United States',
    lat: 26.7056172,
    lon: -80.0361517,
    value: '26.7056172,-80.0361517',
  },
  {
    label: 'Palma de Mallorca, Spain',
    lat: 39.5695818,
    lon: 2.6500745,
    value: '39.5695818,2.6500745',
  },
  {
    label: 'Palmdale, California, United States',
    lat: 34.5793131,
    lon: -118.11711,
    value: '34.5793131,-118.11711',
  },
  {
    label: 'Palmetto, Florida, United States',
    lat: 27.5214269,
    lon: -82.5723193,
    value: '27.5214269,-82.5723193',
  },
  {
    label: 'Palmetto, Georgia, United States',
    lat: 33.9059516,
    lon: -82.9229218,
    value: '33.9059516,-82.9229218',
  },
  {
    label: 'Palo Alto, California, United States',
    lat: 37.4443293,
    lon: -122.1598465,
    value: '37.4443293,-122.1598465',
  },
  {
    label: 'Pamplona, Spain',
    lat: 42.8181992,
    lon: -1.6440089,
    value: '42.8181992,-1.6440089',
  },
  {
    label: 'Panchkula, Haryana, India',
    lat: 30.6975401,
    lon: 76.8551066,
    value: '30.6975401,76.8551066',
  },
  {
    label: 'Panipat, Haryana, India',
    lat: 29.3912753,
    lon: 76.9771675,
    value: '29.3912753,76.9771675',
  },
  {
    label: 'Pantin, France',
    lat: 48.8965023,
    lon: 2.4019804,
    value: '48.8965023,2.4019804',
  },
  {
    label: 'Paramus, New Jersey, United States',
    lat: 40.9452911,
    lon: -74.0735899,
    value: '40.9452911,-74.0735899',
  },
  {
    label: 'Paris, France',
    lat: 48.8534951,
    lon: 2.3483915,
    value: '48.8534951,2.3483915',
  },
  {
    label: 'Park City, Kansas, United States',
    lat: 37.8000123,
    lon: -97.3183763,
    value: '37.8000123,-97.3183763',
  },
  {
    label: 'Park City, Utah, United States',
    lat: 40.6460921,
    lon: -111.4979963,
    value: '40.6460921,-111.4979963',
  },
  {
    label: 'Parma, Italy',
    lat: 44.8013678,
    lon: 10.3280833,
    value: '44.8013678,10.3280833',
  },
  {
    label: 'Pasadena, California, United States',
    lat: 34.1476507,
    lon: -118.144155,
    value: '34.1476507,-118.144155',
  },
  {
    label: 'Pasadena, Texas, United States',
    lat: 29.6910753,
    lon: -95.2092076,
    value: '29.6910753,-95.2092076',
  },
  {
    label: 'Pasay City, Philippines',
    lat: 6.9449039,
    lon: 122.0302828,
    value: '6.9449039,122.0302828',
  },
  {
    label: 'Passo Corese, Italy',
    lat: 42.1518132,
    lon: 12.6520839,
    value: '42.1518132,12.6520839',
  },
  {
    label: 'Paterna, Spain',
    lat: 39.5039227,
    lon: -0.4421014,
    value: '39.5039227,-0.4421014',
  },
  {
    label: 'Patiala, Punjab, India',
    lat: 30.3295605,
    lon: 76.4127819,
    value: '30.3295605,76.4127819',
  },
  {
    label: 'Patna, Bihar, India',
    lat: 25.6093239,
    lon: 85.1235252,
    value: '25.6093239,85.1235252',
  },
  {
    label: 'Patterson, California, United States',
    lat: 37.471623,
    lon: -121.1296949,
    value: '37.471623,-121.1296949',
  },
  {
    label: 'Paulinia, Brazil',
    lat: -22.7630391,
    lon: -47.1532213,
    value: '-22.7630391,-47.1532213',
  },
  {
    label: 'Pembroke Park, Florida, United States',
    lat: 25.9878691,
    lon: -80.1747687,
    value: '25.9878691,-80.1747687',
  },
  {
    label: 'Pendergrass, Georgia, United States',
    lat: 34.1623292,
    lon: -83.6779474,
    value: '34.1623292,-83.6779474',
  },
  {
    label: 'Penns Grove, New Jersey, United States',
    lat: 39.7295581,
    lon: -75.4679734,
    value: '39.7295581,-75.4679734',
  },
  {
    label: 'Pennsylvania, Pennsylvania, United States',
    lat: 40.7058962,
    lon: -78.0036156,
    value: '40.7058962,-78.0036156',
  },
  {
    label: 'Pensacola, Florida, United States',
    lat: 30.421309,
    lon: -87.2169149,
    value: '30.421309,-87.2169149',
  },
  {
    label: 'Perranporth, United Kingdom',
    lat: 50.3444208,
    lon: -5.153844,
    value: '50.3444208,-5.153844',
  },
  {
    label: 'Perris, California, United States',
    lat: 33.7825194,
    lon: -117.2286478,
    value: '33.7825194,-117.2286478',
  },
  {
    label: 'Perth, Australia',
    lat: -31.9558933,
    lon: 115.8605855,
    value: '-31.9558933,115.8605855',
  },
  {
    label: 'Perugia, Italy',
    lat: 43.1119613,
    lon: 12.3890104,
    value: '43.1119613,12.3890104',
  },
  {
    label: 'Peterborough, United Kingdom',
    lat: 52.5725769,
    lon: -0.2427336,
    value: '52.5725769,-0.2427336',
  },
  {
    label: 'Petersburg, Virginia, United States',
    lat: 38.90925365,
    lon: -78.05232299,
    value: '38.90925365,-78.0523229942713',
  },
  {
    label: 'Pewaukee, Wisconsin, United States',
    lat: 43.0805651,
    lon: -88.2612045,
    value: '43.0805651,-88.2612045',
  },
  {
    label: 'Pflugerville, Texas, United States',
    lat: 30.4393696,
    lon: -97.6200043,
    value: '30.4393696,-97.6200043',
  },
  {
    label: 'Pforzheim, Germany',
    lat: 48.8908846,
    lon: 8.7029532,
    value: '48.8908846,8.7029532',
  },
  {
    label: 'Philadelphia, Pennsylvania, United States',
    lat: 39.9527237,
    lon: -75.1635262,
    value: '39.9527237,-75.1635262',
  },
  {
    label: 'Philippines, Philippines',
    lat: 7.38327,
    lon: 122.7627,
    value: '7.38327,122.7627',
  },
  {
    label: 'Phoenix, Arizona, United States',
    lat: 33.4484367,
    lon: -112.074141,
    value: '33.4484367,-112.074141',
  },
  {
    label: 'Pilani, Rajasthan, India',
    lat: 28.3622661,
    lon: 75.6042342,
    value: '28.3622661,75.6042342',
  },
  {
    label: 'Pilsen, Czech Republic',
    lat: 49.7477415,
    lon: 13.3775249,
    value: '49.7477415,13.3775249',
  },
  {
    label: 'Pineville, North Carolina, United States',
    lat: 35.0855409,
    lon: -80.8871253,
    value: '35.0855409,-80.8871253',
  },
  {
    label: 'PINKENBA, Australia',
    lat: -27.394503,
    lon: 153.14308374799714,
    value: '-27.394503,153.14308374799714',
  },
  {
    label: 'Pioltello, Italy',
    lat: 45.5010216,
    lon: 9.3263354,
    value: '45.5010216,9.3263354',
  },
  {
    label: 'Pisa, Italy',
    lat: 43.7159395,
    lon: 10.4018624,
    value: '43.7159395,10.4018624',
  },
  {
    label: 'Pitt Meadows, Canada',
    lat: 49.2207623,
    lon: -122.690153,
    value: '49.2207623,-122.690153',
  },
  {
    label: 'Pittsburgh, Pennsylvania, United States',
    lat: 40.4416941,
    lon: -79.9900861,
    value: '40.4416941,-79.9900861',
  },
  {
    label: 'Pittston, Pennsylvania, United States',
    lat: 41.3259134,
    lon: -75.7893604,
    value: '41.3259134,-75.7893604',
  },
  {
    label: 'Plain City, Ohio, United States',
    lat: 40.1078821,
    lon: -83.2669677,
    value: '40.1078821,-83.2669677',
  },
  {
    label: 'Plainfield, Indiana, United States',
    lat: 39.703872,
    lon: -86.399477,
    value: '39.703872,-86.399477',
  },
  {
    label: 'Playa del Carmen, Mexico',
    lat: 25.941,
    lon: -100.347995,
    value: '25.941,-100.347995',
  },
  {
    label: 'Plymouth, Massachusetts, United States',
    lat: 41.9583799,
    lon: -70.6673851,
    value: '41.9583799,-70.6673851',
  },
  {
    label: 'Plymouth, Michigan, United States',
    lat: 42.3712,
    lon: -83.4675021,
    value: '42.3712,-83.4675021',
  },
  {
    label: 'Plymouth, Minnesota, United States',
    lat: 45.0065149,
    lon: -93.46651,
    value: '45.0065149,-93.46651',
  },
  {
    label: 'Pointe-Claire, Canada',
    lat: 45.4566767,
    lon: -73.8067442,
    value: '45.4566767,-73.8067442',
  },
  {
    label: 'Pommersfelden, Germany',
    lat: 49.7672835,
    lon: 10.8179673,
    value: '49.7672835,10.8179673',
  },
  {
    label: 'Pompano Beach, Florida, United States',
    lat: 26.2378597,
    lon: -80.1247667,
    value: '26.2378597,-80.1247667',
  },
  {
    label: 'Pondicherry, Puducherry, India',
    lat: 11.9340568,
    lon: 79.8306447,
    value: '11.9340568,79.8306447',
  },
  {
    label: 'Pondicherry, TN, India',
    lat: 11.9340568,
    lon: 79.8306447,
    value: '11.9340568,79.8306447',
  },
  {
    label: 'Pontiac, Michigan, United States',
    lat: 42.6389216,
    lon: -83.2910468,
    value: '42.6389216,-83.2910468',
  },
  {
    label: 'Pooler, Georgia, United States',
    lat: 32.1158983,
    lon: -81.2495131,
    value: '32.1158983,-81.2495131',
  },
  {
    label: 'Port Allen, Louisiana, United States',
    lat: 30.45335,
    lon: -91.2100569,
    value: '30.45335,-91.2100569',
  },
  {
    label: 'Port St. Lucie, Florida, United States',
    lat: 27.2939333,
    lon: -80.3503283,
    value: '27.2939333,-80.3503283',
  },
  {
    label: 'Port Wentworth, Georgia, United States',
    lat: 32.149092,
    lon: -81.1631681,
    value: '32.149092,-81.1631681',
  },
  {
    label: 'Portadown, United Kingdom',
    lat: 54.4242284,
    lon: -6.4412133,
    value: '54.4242284,-6.4412133',
  },
  {
    label: 'Portes-les-Valence, France',
    lat: 44.8753434,
    lon: 4.8774936,
    value: '44.8753434,4.8774936',
  },
  {
    label: 'Portland, Maine, United States',
    lat: 43.6573605,
    lon: -70.2586618,
    value: '43.6573605,-70.2586618',
  },
  {
    label: 'Portland, Oregon, United States',
    lat: 45.5202471,
    lon: -122.674194,
    value: '45.5202471,-122.674194',
  },
  {
    label: 'Poway, California, United States',
    lat: 32.9628234,
    lon: -117.035864,
    value: '32.9628234,-117.035864',
  },
  {
    label: 'Poznan, Poland',
    lat: 52.4082663,
    lon: 16.9335199,
    value: '52.4082663,16.9335199',
  },
  {
    label: 'Prague, Czech Republic',
    lat: 50.0874654,
    lon: 14.4212535,
    value: '50.0874654,14.4212535',
  },
  {
    label: 'Prakasam, Andhra Pradesh, India',
    lat: 15.6589741,
    lon: 79.47164372680194,
    value: '15.6589741,79.47164372680194',
  },
  {
    label: 'Prescott Valley, Arizona, United States',
    lat: 34.6100243,
    lon: -112.315721,
    value: '34.6100243,-112.315721',
  },
  {
    label: 'Prince George, Virginia, United States',
    lat: 37.2204272,
    lon: -77.288033,
    value: '37.2204272,-77.288033',
  },
  {
    label: 'Providence, Rhode Island, United States',
    lat: 41.8239891,
    lon: -71.4128343,
    value: '41.8239891,-71.4128343',
  },
  {
    label: 'Przytoczna, Poland',
    lat: 52.5776,
    lon: 15.67994,
    value: '52.5776,15.67994',
  },
  {
    label: 'Puerto de Santa Maria, Spain',
    lat: 38.0420693,
    lon: -6.1835772,
    value: '38.0420693,-6.1835772',
  },
  {
    label: 'Puerto Real, Spain',
    lat: 36.5286856,
    lon: -6.1902161,
    value: '36.5286856,-6.1902161',
  },
  {
    label: 'Pune, Maharashtra, India',
    lat: 18.521428,
    lon: 73.8544541,
    value: '18.521428,73.8544541',
  },
  {
    label: 'Puyallup, Washington, United States',
    lat: 47.1849009,
    lon: -122.29214,
    value: '47.1849009,-122.29214',
  },
  {
    label: 'Qassim, Saudi Arabia',
    lat: 26.2506327,
    lon: 44.01691829675531,
    value: '26.2506327,44.01691829675531',
  },
  {
    label: 'Qingdao, China',
    lat: 36.0637967,
    lon: 120.3192081,
    value: '36.0637967,120.3192081',
  },
  {
    label: 'Quakertown, Pennsylvania, United States',
    lat: 40.4417682,
    lon: -75.3415667,
    value: '40.4417682,-75.3415667',
  },
  {
    label: 'Queretaro, Mexico',
    lat: 20.5954708,
    lon: -100.397059,
    value: '20.5954708,-100.397059',
  },
  {
    label: 'Rabat, Morocco',
    lat: 34.02236,
    lon: -6.8340222,
    value: '34.02236,-6.8340222',
  },
  {
    label: 'Rae Bareli, Uttar Pradesh, India',
    lat: 26.220643,
    lon: 81.249029,
    value: '26.220643,81.249029',
  },
  {
    label: 'Ragusa, Italy',
    lat: 36.9219828,
    lon: 14.7213455,
    value: '36.9219828,14.7213455',
  },
  {
    label: 'Raigad, Maharashtra, India',
    lat: 18.2369566,
    lon: 73.4471601,
    value: '18.2369566,73.4471601',
  },
  {
    label: 'Raipur, Connecticut, India',
    lat: 21.2380912,
    lon: 81.6336993,
    value: '21.2380912,81.6336993',
  },
  // {
  //   "label": "Rajahmundhry, Andhra Pradesh, India",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Rajkot, Gujarat, India',
    lat: 22.3053263,
    lon: 70.8028377,
    value: '22.3053263,70.8028377',
  },
  {
    label: 'Raleigh, North Carolina, United States',
    lat: 35.7803977,
    lon: -78.6390989,
    value: '35.7803977,-78.6390989',
  },
  {
    label: 'Ramstein-Miesenbach, Germany',
    lat: 49.4518819,
    lon: 7.5543118,
    value: '49.4518819,7.5543118',
  },
  {
    label: 'Ranchi, Jharkhand, India',
    lat: 23.3700501,
    lon: 85.3250387,
    value: '23.3700501,85.3250387',
  },
  {
    label: 'Ras Al-Khor, United Arab Emirates',
    lat: 25.772778,
    lon: 55.931419,
    value: '25.772778,55.931419',
  },
  {
    label: 'Ratnagiri, Haryana, India',
    lat: 16.9933521,
    lon: 73.2954371,
    value: '16.9933521,73.2954371',
  },
  {
    label: 'Ratnagiri, Maharashtra, India',
    lat: 16.9933521,
    lon: 73.2954371,
    value: '16.9933521,73.2954371',
  },
  {
    label: 'Raunheim, Germany',
    lat: 50.0097685,
    lon: 8.4499439,
    value: '50.0097685,8.4499439',
  },
  {
    label: 'Ravenhall, Australia',
    lat: -37.7754956,
    lon: 144.7366326,
    value: '-37.7754956,144.7366326',
  },
  {
    label: 'Reading, Pennsylvania, United States',
    lat: 40.335345,
    lon: -75.9279495,
    value: '40.335345,-75.9279495',
  },
  {
    label: 'Reading, United Kingdom',
    lat: 51.4564242,
    lon: -0.9700664,
    value: '51.4564242,-0.9700664',
  },
  {
    label: 'Reau, France',
    lat: 48.610231,
    lon: 2.6235754,
    value: '48.610231,2.6235754',
  },
  {
    label: 'Recife, Brazil',
    lat: -8.0584933,
    lon: -34.8848193,
    value: '-8.0584933,-34.8848193',
  },
  {
    label: 'Redlands, California, United States',
    lat: 34.0550328,
    lon: -117.1827445,
    value: '34.0550328,-117.1827445',
  },
  {
    label: 'Redmond, Washington, United States',
    lat: 47.6694141,
    lon: -122.1238767,
    value: '47.6694141,-122.1238767',
  },
  {
    label: 'Regensburg, Germany',
    lat: 49.0195333,
    lon: 12.0974869,
    value: '49.0195333,12.0974869',
  },
  {
    label: 'Regents Park, Australia',
    lat: -27.6766915,
    lon: 153.04152190069408,
    value: '-27.6766914999999,153.04152190069408',
  },
  {
    label: 'Reggio Calabria, Italy',
    lat: 38.1035389,
    lon: 15.6397556,
    value: '38.1035389,15.6397556',
  },
  {
    label: 'Reno, Nevada, United States',
    lat: 39.5261206,
    lon: -119.8126581,
    value: '39.5261206,-119.8126581',
  },
  {
    label: 'Renton, Washington, United States',
    lat: 47.4799078,
    lon: -122.2034496,
    value: '47.4799078,-122.2034496',
  },
  {
    label: 'Republic, Missouri, United States',
    lat: 37.1193808,
    lon: -93.4803765,
    value: '37.1193808,-93.4803765',
  },
  {
    label: 'Revere, Massachusetts, United States',
    lat: 42.4084302,
    lon: -71.0119948,
    value: '42.4084302,-71.0119948',
  },
  {
    label: 'Rheinberg, Germany',
    lat: 51.5458979,
    lon: 6.6014097,
    value: '51.5458979,6.6014097',
  },
  {
    label: 'Rialto, California, United States',
    lat: 34.1064001,
    lon: -117.3703235,
    value: '34.1064001,-117.3703235',
  },
  {
    label: 'Richardson, Texas, United States',
    lat: 32.9481789,
    lon: -96.7297206,
    value: '32.9481789,-96.7297206',
  },
  {
    label: 'Richmond, California, United States',
    lat: 37.9357576,
    lon: -122.347748,
    value: '37.9357576,-122.347748',
  },
  {
    label: 'Richmond, Canada',
    lat: 49.163168,
    lon: -123.137414,
    value: '49.163168,-123.137414',
  },
  {
    label: 'Richmond, Texas, United States',
    lat: 29.5821811,
    lon: -95.7607832,
    value: '29.5821811,-95.7607832',
  },
  {
    label: 'Richmond, Virginia, United States',
    lat: 37.5385087,
    lon: -77.43428,
    value: '37.5385087,-77.43428',
  },
  {
    label: 'Ridgmont, United Kingdom',
    lat: 52.0151934,
    lon: -0.5808838,
    value: '52.0151934,-0.5808838',
  },
  {
    label: 'Riese Pio, Italy',
    lat: 45.7455136,
    lon: 11.9049454,
    value: '45.7455136,11.9049454',
  },
  {
    label: 'Rio de Janeiro, Brazil',
    lat: -22.9110137,
    lon: -43.2093727,
    value: '-22.9110137,-43.2093727',
  },
  {
    label: 'Riudellots de la Selva, Spain',
    lat: 41.8954981,
    lon: 2.8022296,
    value: '41.8954981,2.8022296',
  },
  {
    label: 'Rivalta Di Torino, Italy',
    lat: 45.034045,
    lon: 7.518419,
    value: '45.034045,7.518419',
  },
  {
    label: 'Riverside, California, United States',
    lat: 33.9824949,
    lon: -117.374238,
    value: '33.9824949,-117.374238',
  },
  {
    label: 'Riverside, Missouri, United States',
    lat: 39.1775032,
    lon: -94.6130121,
    value: '39.1775032,-94.6130121',
  },
  {
    label: 'Rivesaltes, France',
    lat: 42.7683568,
    lon: 2.8709277,
    value: '42.7683568,2.8709277',
  },
  {
    label: 'Riviera Beach, Florida, United States',
    lat: 26.784343,
    lon: -80.0650293,
    value: '26.784343,-80.0650293',
  },
  {
    label: 'Riyadh, Saudi Arabia',
    lat: 24.638916,
    lon: 46.7160104,
    value: '24.638916,46.7160104',
  },
  {
    label: 'Roanoke, Texas, United States',
    lat: 33.0040126,
    lon: -97.2258483,
    value: '33.0040126,-97.2258483',
  },
  {
    label: 'Robbinsville, New Jersey, United States',
    lat: 40.218974,
    lon: -74.6276275,
    value: '40.218974,-74.6276275',
  },
  {
    label: 'Rochdale, United Kingdom',
    lat: 53.6153659,
    lon: -2.1557561,
    value: '53.6153659,-2.1557561',
  },
  {
    label: 'Rochester, New York, United States',
    lat: 43.157285,
    lon: -77.615214,
    value: '43.157285,-77.615214',
  },
  {
    label: 'Rock Tavern, New York, United States',
    lat: 41.4662049,
    lon: -74.1912587,
    value: '41.4662049,-74.1912587',
  },
  {
    label: 'Rockaway, New Jersey, United States',
    lat: 40.9012101,
    lon: -74.5143232,
    value: '40.9012101,-74.5143232',
  },
  {
    label: 'Rockford, Illinois, United States',
    lat: 42.2713945,
    lon: -89.093966,
    value: '42.2713945,-89.093966',
  },
  {
    label: 'Rockville, Maryland, United States',
    lat: 39.0817985,
    lon: -77.1516844,
    value: '39.0817985,-77.1516844',
  },
  {
    label: 'Rocky View County, Canada',
    lat: 51.3699347,
    lon: -114.014186,
    value: '51.3699347,-114.014186',
  },
  {
    label: 'Roma, Italy',
    lat: 41.8933203,
    lon: 12.4829321,
    value: '41.8933203,12.4829321',
  },
  {
    label: 'Rome, Italy',
    lat: 41.8933203,
    lon: 12.4829321,
    value: '41.8933203,12.4829321',
  },
  {
    label: 'Romeoville, Illinois, United States',
    lat: 41.6475306,
    lon: -88.0895061,
    value: '41.6475306,-88.0895061',
  },
  {
    label: 'Romulus, Michigan, United States',
    lat: 42.2222614,
    lon: -83.3965995,
    value: '42.2222614,-83.3965995',
  },
  {
    label: 'Rosedale, New York, United States',
    lat: 40.8909329,
    lon: -73.7934663,
    value: '40.8909329,-73.7934663',
  },
  {
    label: 'Rosemead, California, United States',
    lat: 34.0676169,
    lon: -118.0879763,
    value: '34.0676169,-118.0879763',
  },
  {
    label: 'Roseville, California, United States',
    lat: 38.7521235,
    lon: -121.2880059,
    value: '38.7521235,-121.2880059',
  },
  {
    label: 'Rossford, Ohio, United States',
    lat: 41.6097726,
    lon: -83.5643782,
    value: '41.6097726,-83.5643782',
  },
  {
    label: 'Rostock, Germany',
    lat: 54.0886707,
    lon: 12.1400211,
    value: '54.0886707,12.1400211',
  },
  {
    label: 'Round Rock, Texas, United States',
    lat: 30.5085915,
    lon: -97.6788056,
    value: '30.5085915,-97.6788056',
  },
  {
    label: 'Rovido (MI), Italy',
    lat: 45.4174715,
    lon: 9.1005005,
    value: '45.4174715,9.1005005',
  },
  {
    label: 'Rovigo, Italy',
    lat: 45.0698166,
    lon: 11.78882,
    value: '45.0698166,11.78882',
  },
  {
    label: 'Royapettah, TN, India',
    lat: 13.0554718,
    lon: 80.2639706,
    value: '13.0554718,80.2639706',
  },
  {
    label: 'Rozenburg, Netherlands',
    lat: 51.9056445,
    lon: 4.2415465,
    value: '51.9056445,4.2415465',
  },
  {
    label: 'Rubí, Spain',
    lat: 41.4936194,
    lon: 2.0319476,
    value: '41.4936194,2.0319476',
  },
  {
    label: 'Rudrapur, UT, India',
    lat: 28.9707183,
    lon: 79.3972845,
    value: '28.9707183,79.3972845',
  },
  {
    label: 'Rugby, United Kingdom',
    lat: 52.3726682,
    lon: -1.2620038,
    value: '52.3726682,-1.2620038',
  },
  {
    label: 'Ruskin, Florida, United States',
    lat: 27.7208575,
    lon: -82.4331528,
    value: '27.7208575,-82.4331528',
  },
  {
    label: 'Rutherford, New Jersey, United States',
    lat: 40.826489,
    lon: -74.1068091,
    value: '40.826489,-74.1068091',
  },
  {
    label: 'Sacramento, California, United States',
    lat: 38.5810606,
    lon: -121.493895,
    value: '38.5810606,-121.493895',
  },
  {
    label: 'Sagamihara, Japan',
    lat: 35.56559,
    lon: 139.236215,
    value: '35.56559,139.236215',
  },
  {
    label: 'Sagar, Madhya Pradesh, India',
    lat: 23.8417793,
    lon: 78.7466817,
    value: '23.8417793,78.7466817',
  },
  {
    label: 'Saginaw, Michigan, United States',
    lat: 43.4200387,
    lon: -83.9490365,
    value: '43.4200387,-83.9490365',
  },
  {
    label: 'Saharanpur, Uttar Pradesh, India',
    lat: 29.9637438,
    lon: 77.5427464,
    value: '29.9637438,77.5427464',
  },
  {
    label: 'Saint Denis, France',
    lat: -20.8799889,
    lon: 55.448137,
    value: '-20.8799889,55.448137',
  },
  {
    label: 'Saint Louis, Missouri, United States',
    lat: 38.6280278,
    lon: -90.1910154,
    value: '38.6280278,-90.1910154',
  },
  {
    label: 'Saint Petersburg, Florida, United States',
    lat: 27.7700475,
    lon: -82.6359078,
    value: '27.7700475,-82.6359078',
  },
  {
    label: 'Saint Rose, Louisiana, United States',
    lat: 29.9468716,
    lon: -90.3231349,
    value: '29.9468716,-90.3231349',
  },
  {
    label: 'Saitama, Japan',
    lat: 35.8616402,
    lon: 139.6457957,
    value: '35.8616402,139.6457957',
  },
  {
    label: 'SAKADO, Japan',
    lat: 35.9572312,
    lon: 139.4029048,
    value: '35.9572312,139.4029048',
  },
  {
    label: 'Sakai, Japan',
    lat: 34.5289178,
    lon: 135.5015548,
    value: '34.5289178,135.5015548',
  },
  {
    label: 'Salem, Massachusetts, United States',
    lat: 42.5195292,
    lon: -70.8967226,
    value: '42.5195292,-70.8967226',
  },
  {
    label: 'Salem, Oregon, United States',
    lat: 44.9391565,
    lon: -123.033121,
    value: '44.9391565,-123.033121',
  },
  {
    label: 'Salem, TN, India',
    lat: 11.6551982,
    lon: 78.1581771,
    value: '11.6551982,78.1581771',
  },
  {
    label: 'Salt Lake City, Utah, United States',
    lat: 40.7596198,
    lon: -111.886797,
    value: '40.7596198,-111.886797',
  },
  {
    label: 'Salvador, Brazil',
    lat: -12.9822499,
    lon: -38.4812772,
    value: '-12.9822499,-38.4812772',
  },
  {
    label: 'San Andrés Cholula, Mexico',
    lat: 19.0519385,
    lon: -98.2976151,
    value: '19.0519385,-98.2976151',
  },
  {
    label: 'San Antonio, Texas, United States',
    lat: 29.4246002,
    lon: -98.4951405,
    value: '29.4246002,-98.4951405',
  },
  {
    label: 'San Bernardino, California, United States',
    lat: 34.1081621,
    lon: -117.28946,
    value: '34.1081621,-117.28946',
  },
  {
    label: 'San Diego, California, United States',
    lat: 32.7174202,
    lon: -117.162772,
    value: '32.7174202,-117.162772',
  },
  {
    label: 'San Fernando de Henares, Spain',
    lat: 40.4248548,
    lon: -3.5350369,
    value: '40.4248548,-3.5350369',
  },
  {
    label: 'San Francisco De Heredia, Costa Rica',
    lat: 9.994821,
    lon: -84.12922,
    value: '9.994821,-84.12922',
  },
  {
    label: 'San Francisco, California, United States',
    lat: 37.7792588,
    lon: -122.4193286,
    value: '37.7792588,-122.4193286',
  },
  {
    label: 'San Giovanni Teatino, Italy',
    lat: 42.4105412,
    lon: 14.2010296,
    value: '42.4105412,14.2010296',
  },
  {
    label: 'San Jose, California, United States',
    lat: 37.3361663,
    lon: -121.890591,
    value: '37.3361663,-121.890591',
  },
  {
    label: 'San Jose, Costa Rica',
    lat: 9.9325427,
    lon: -84.0795782,
    value: '9.9325427,-84.0795782',
  },
  {
    label: 'San Leandro, California, United States',
    lat: 37.7249296,
    lon: -122.1560768,
    value: '37.7249296,-122.1560768',
  },
  {
    label: 'San Luis Obispo, California, United States',
    lat: 35.2827525,
    lon: -120.659615,
    value: '35.2827525,-120.659615',
  },
  {
    label: 'San Luis Potosi, Mexico',
    lat: 22.5000001,
    lon: -100.4949145,
    value: '22.5000001,-100.4949145',
  },
  {
    label: 'San Marcos, Texas, United States',
    lat: 29.8826436,
    lon: -97.9405828,
    value: '29.8826436,-97.9405828',
  },
  {
    label: 'San Salvo, Italy',
    lat: 42.0433265,
    lon: 14.7272302,
    value: '42.0433265,14.7272302',
  },
  {
    label: 'San Sebastian, Spain',
    lat: 43.3224219,
    lon: -1.9838889,
    value: '43.3224219,-1.9838889',
  },
  // {
  //   "label": "SanBernardino, California, United States",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Sangareddy, Telangana, India',
    lat: 17.6155151,
    lon: 78.0817225,
    value: '17.6155151,78.0817225',
  },
  {
    label: 'Santa Ana, California, United States',
    lat: 33.7494951,
    lon: -117.873221,
    value: '33.7494951,-117.873221',
  },
  {
    label: 'Santa Barbara, California, United States',
    lat: 34.4221319,
    lon: -119.702667,
    value: '34.4221319,-119.702667',
  },
  {
    label: 'Santa Catarina, Mexico',
    lat: 20.2967,
    lon: -97.48,
    value: '20.2967,-97.48',
  },
  {
    label: 'Santa Clara, California, United States',
    lat: 37.3541132,
    lon: -121.955174,
    value: '37.3541132,-121.955174',
  },
  {
    label: 'Santa Clarita, California, United States',
    lat: 34.4127963,
    lon: -118.5538088,
    value: '34.4127963,-118.5538088',
  },
  {
    label: 'Santa Cruz, California, United States',
    lat: 36.9743626,
    lon: -122.0294673,
    value: '36.9743626,-122.0294673',
  },
  {
    label: 'Santa Fe Springs, California, United States',
    lat: 33.9480787,
    lon: -118.0691499,
    value: '33.9480787,-118.0691499',
  },
  {
    label: 'Santa Fe, New Mexico, United States',
    lat: 35.6876096,
    lon: -105.938456,
    value: '35.6876096,-105.938456',
  },
  {
    label: 'Santa Fe, Spain',
    lat: 37.1895171,
    lon: -3.7183561,
    value: '37.1895171,-3.7183561',
  },
  {
    label: 'Santa Monica, California, United States',
    lat: 34.0194704,
    lon: -118.491227,
    value: '34.0194704,-118.491227',
  },
  {
    label: 'Santander, Spain',
    lat: 43.4620412,
    lon: -3.8099719,
    value: '43.4620412,-3.8099719',
  },
  {
    label: 'Santarcangelo di Romagna, Italy',
    lat: 44.0634029,
    lon: 12.4467143,
    value: '44.0634029,12.4467143',
  },
  {
    label: 'Santiago de Queretaro, Mexico',
    lat: 20.5954708,
    lon: -100.397059,
    value: '20.5954708,-100.397059',
  },
  {
    label: 'Santiago, Chile',
    lat: -33.4377756,
    lon: -70.6504502,
    value: '-33.4377756,-70.6504502',
  },
  {
    label: 'Sao Joao de Meriti, Brazil',
    lat: -22.8043744,
    lon: -43.3724125,
    value: '-22.8043744,-43.3724125',
  },
  {
    label: 'Sao Paulo, Brazil',
    lat: -23.5506507,
    lon: -46.6333824,
    value: '-23.5506507,-46.6333824',
  },
  {
    label: 'Sapporo, Japan',
    lat: 43.061936,
    lon: 141.3542924,
    value: '43.061936,141.3542924',
  },
  {
    label: 'Saram, Puducherry, India',
    lat: 11.93996325,
    lon: 79.81429824315448,
    value: '11.93996325,79.81429824315448',
  },
  {
    label: 'Saran, France',
    lat: 47.9494039,
    lon: 1.8764209,
    value: '47.9494039,1.8764209',
  },
  {
    label: 'Sarasota, Florida, United States',
    lat: 27.3365805,
    lon: -82.5308545,
    value: '27.3365805,-82.5308545',
  },
  {
    label: 'Sassari, Italy',
    lat: 40.7232643,
    lon: 8.5610074,
    value: '40.7232643,8.5610074',
  },
  {
    label: 'Satara, Maharashtra, India',
    lat: 17.688321,
    lon: 74.0041726,
    value: '17.688321,74.0041726',
  },
  {
    label: 'Satolas-et-Bonce, France',
    lat: 45.681867999999994,
    lon: 5.120308248632403,
    value: '45.681867999999994,5.120308248632403',
  },
  {
    label: 'SAUGET, Illinois, United States',
    lat: 38.590186,
    lon: -90.1692755,
    value: '38.590186,-90.1692755',
  },
  {
    label: 'Savannah, Georgia, United States',
    lat: 32.0790074,
    lon: -81.0921335,
    value: '32.0790074,-81.0921335',
  },
  {
    label: 'Sayama, Japan',
    lat: 35.8528971,
    lon: 139.4122999,
    value: '35.8528971,139.4122999',
  },
  {
    label: 'Scarborough, Canada',
    lat: 43.7729744,
    lon: -79.2576479,
    value: '43.7729744,-79.2576479',
  },
  {
    label: 'Schaumburg, Illinois, United States',
    lat: 42.0333608,
    lon: -88.083406,
    value: '42.0333608,-88.083406',
  },
  {
    label: 'Schertz, Texas, United States',
    lat: 29.5641617,
    lon: -98.2695702,
    value: '29.5641617,-98.2695702',
  },
  {
    label: 'Schkeuditz, Germany',
    lat: 51.3963509,
    lon: 12.2216292,
    value: '51.3963509,12.2216292',
  },
  {
    label: 'Schonefeld, Germany',
    lat: 51.9789028,
    lon: 12.8309403,
    value: '51.9789028,12.8309403',
  },
  {
    label: 'Schortens, Germany',
    lat: 53.5366547,
    lon: 7.9467348,
    value: '53.5366547,7.9467348',
  },
  {
    label: 'Seaford, Delaware, United States',
    lat: 38.6413452,
    lon: -75.6114584,
    value: '38.6413452,-75.6114584',
  },
  {
    label: 'Seatac, Washington, United States',
    lat: 47.4449086,
    lon: -122.283084,
    value: '47.4449086,-122.283084',
  },
  {
    label: 'Seattle, Washington, United States',
    lat: 47.6038321,
    lon: -122.330062,
    value: '47.6038321,-122.330062',
  },
  {
    label: 'Sec-18, Gurgaon, Haryana, India',
    lat: 28.42826235,
    lon: 77.00270014657752,
    value: '28.42826235,77.00270014657752',
  },
  {
    label: 'Sec-24, Faridabad, Haryana, India',
    lat: 30.74350245,
    lon: 76.75868656327823,
    value: '30.74350245,76.75868656327823',
  },
  {
    label: 'Secunderabad, Telangana, India',
    lat: 17.4337246,
    lon: 78.5006827,
    value: '17.4337246,78.5006827',
  },
  {
    label: 'Seffner, Florida, United States',
    lat: 27.9836329,
    lon: -82.2756468,
    value: '27.9836329,-82.2756468',
  },
  {
    label: 'Sendai, Japan',
    lat: 38.2677554,
    lon: 140.8691498,
    value: '38.2677554,140.8691498',
  },
  {
    label: 'Senlis, France',
    lat: 49.2065404,
    lon: 2.5846582,
    value: '49.2065404,2.5846582',
  },
  {
    label: 'Seoul, Korea, Republic of',
    lat: 37.5279835,
    lon: 126.99257,
    value: '37.5279835,126.99257',
  },
  {
    label: 'Sered, Slovakia',
    lat: 48.2889032,
    lon: 17.7308808,
    value: '48.2889032,17.7308808',
  },
  {
    label: 'Severn Beach, United Kingdom',
    lat: 51.5618116,
    lon: -2.6619317,
    value: '51.5618116,-2.6619317',
  },
  {
    label: 'Severn, Maryland, United States',
    lat: 39.1278864,
    lon: -76.6869128,
    value: '39.1278864,-76.6869128',
  },
  {
    label: 'Sevilla, Spain',
    lat: 37.3886303,
    lon: -5.9953403,
    value: '37.3886303,-5.9953403',
  },
  {
    label: 'SEVREY, France',
    lat: 46.7382911,
    lon: 4.840874,
    value: '46.7382911,4.840874',
  },
  {
    label: 'Sewickley, Pennsylvania, United States',
    lat: 40.5403417,
    lon: -80.1805269,
    value: '40.5403417,-80.1805269',
  },
  {
    label: 'Seynod, France',
    lat: 45.8880398,
    lon: 6.0992785,
    value: '45.8880398,6.0992785',
  },
  {
    label: 'Shafter, California, United States',
    lat: 35.501461,
    lon: -119.273682,
    value: '35.501461,-119.273682',
  },
  {
    label: 'Shakopee, Minnesota, United States',
    lat: 44.7980186,
    lon: -93.5268986,
    value: '44.7980186,-93.5268986',
  },
  {
    label: 'Shanghai, China',
    lat: 31.2323437,
    lon: 121.4691024,
    value: '31.2323437,121.4691024',
  },
  {
    label: 'Shawnee, Kansas, United States',
    lat: 39.0229714,
    lon: -94.7145415,
    value: '39.0229714,-94.7145415',
  },
  {
    label: 'Sheffield, United Kingdom',
    lat: 53.3806626,
    lon: -1.4702278,
    value: '53.3806626,-1.4702278',
  },
  {
    label: 'Shelby Charter Township, Michigan, United States',
    lat: 42.6696409,
    lon: -83.0338011,
    value: '42.6696409,-83.0338011',
  },
  {
    label: 'Shenzhen, China',
    lat: 22.5445741,
    lon: 114.0545429,
    value: '22.5445741,114.0545429',
  },
  {
    label: 'Shepherdsville, Kentucky, United States',
    lat: 37.9885936,
    lon: -85.7158246,
    value: '37.9885936,-85.7158246',
  },
  {
    label: 'Shilong, Meghalaya, India',
    lat: 25.500167,
    lon: 91.457209,
    value: '25.500167,91.457209',
  },
  {
    label: 'Shimla, Himachal Pradesh, India',
    lat: 31.1041526,
    lon: 77.1709729,
    value: '31.1041526,77.1709729',
  },
  {
    label: 'Shimoga, Karnataka, India',
    lat: 13.9326093,
    lon: 75.574978,
    value: '13.9326093,75.574978',
  },
  {
    label: 'Shippensburg, Pennsylvania, United States',
    lat: 40.0507198,
    lon: -77.5205485,
    value: '40.0507198,-77.5205485',
  },
  {
    label: 'Shirley, New York, United States',
    lat: 40.8012894,
    lon: -72.8675941,
    value: '40.8012894,-72.8675941',
  },
  {
    label: 'Shreveport, Louisiana, United States',
    lat: 32.5135356,
    lon: -93.7477839,
    value: '32.5135356,-93.7477839',
  },
  {
    label: 'Sidney, Canada',
    lat: 48.6505788,
    lon: -123.3983246,
    value: '48.6505788,-123.3983246',
  },
  {
    label: 'Siero, Spain',
    lat: 43.3952982,
    lon: -5.635702394,
    value: '43.3952982,-5.63570239376241',
  },
  {
    label: 'Sikar, Rajasthan, India',
    lat: 27.6091864,
    lon: 75.1395503,
    value: '27.6091864,75.1395503',
  },
  {
    label: 'Silchar, Assam, India',
    lat: 24.829588,
    lon: 92.797148,
    value: '24.829588,92.797148',
  },
  {
    label: 'Siliguri, West Bengal, India',
    lat: 26.7164127,
    lon: 88.4309916,
    value: '26.7164127,88.4309916',
  },
  {
    label: 'Simi Valley, California, United States',
    lat: 34.2677404,
    lon: -118.7538071,
    value: '34.2677404,-118.7538071',
  },
  {
    label: 'Sindelfingen, Germany',
    lat: 48.7084162,
    lon: 9.0035455,
    value: '48.7084162,9.0035455',
  },
  {
    label: 'Singapore, Singapore',
    lat: 1.2899175,
    lon: 103.8519072,
    value: '1.2899175,103.8519072',
  },
  {
    label: 'Sioux Falls, South Dakota, United States',
    lat: 43.5488256,
    lon: -96.7307737,
    value: '43.5488256,-96.7307737',
  },
  {
    label: 'Sirsa, Haryana, India',
    lat: 29.5372849,
    lon: 75.0377649,
    value: '29.5372849,75.0377649',
  },
  {
    label: 'Sitapur, Uttar Pradesh, India',
    lat: 27.5615568,
    lon: 80.6840082,
    value: '27.5615568,80.6840082',
  },
  {
    label: 'Skokie, Illinois, United States',
    lat: 42.0262972,
    lon: -87.7521069,
    value: '42.0262972,-87.7521069',
  },
  {
    label: 'Smeaton Grange, Australia',
    lat: -34.0418831,
    lon: 150.7539029,
    value: '-34.0418831,150.7539029',
  },
  {
    label: 'Smithfield, North Carolina, United States',
    lat: 35.5111996,
    lon: -78.346102,
    value: '35.5111996,-78.346102',
  },
  {
    label: 'Smyrna, Georgia, United States',
    lat: 33.883887,
    lon: -84.5147454,
    value: '33.883887,-84.5147454',
  },
  {
    label: 'Soccorso, Italy',
    lat: 38.1599156,
    lon: 15.3082482,
    value: '38.1599156,15.3082482',
  },
  {
    label: 'Soja, Japan',
    lat: 34.6728162,
    lon: 133.7466763,
    value: '34.6728162,133.7466763',
  },
  {
    label: 'Solapur, Maharashtra, India',
    lat: 17.6699734,
    lon: 75.9008118,
    value: '17.6699734,75.9008118',
  },
  {
    label: 'Solon, Ohio, United States',
    lat: 41.3897764,
    lon: -81.4412259,
    value: '41.3897764,-81.4412259',
  },
  {
    label: 'Somerset, New Jersey, United States',
    lat: 40.2762189,
    lon: -74.8490528,
    value: '40.2762189,-74.8490528',
  },
  {
    label: 'Sonepat, Haryana, India',
    lat: 28.99197,
    lon: 77.0342633,
    value: '28.99197,77.0342633',
  },
  {
    label: 'Sonipat, Haryana, India',
    lat: 28.9953758,
    lon: 77.0233627,
    value: '28.9953758,77.0233627',
  },
  {
    label: 'Sorbiers, France',
    lat: 45.4873195,
    lon: 4.4507034,
    value: '45.4873195,4.4507034',
  },
  {
    label: 'Sosnowiec, Poland',
    lat: 50.2780834,
    lon: 19.1342944,
    value: '50.2780834,19.1342944',
  },
  {
    label: 'South Bend, Indiana, United States',
    lat: 41.6833813,
    lon: -86.2500066,
    value: '41.6833813,-86.2500066',
  },
  {
    label: 'South Delhi, Delhi, India',
    lat: 28.477998,
    lon: 77.171516,
    value: '28.477998,77.171516',
  },
  {
    label: 'South Gate, California, United States',
    lat: 33.9463456,
    lon: -118.200981,
    value: '33.9463456,-118.200981',
  },
  {
    label: 'South Marston, United Kingdom',
    lat: 51.5899016,
    lon: -1.7223667,
    value: '51.5899016,-1.7223667',
  },
  {
    label: 'South San Francisco, California, United States',
    lat: 37.6535403,
    lon: -122.4168664,
    value: '37.6535403,-122.4168664',
  },
  {
    label: 'South Windsor, Connecticut, United States',
    lat: 41.8489872,
    lon: -72.5717551,
    value: '41.8489872,-72.5717551',
  },
  {
    label: 'Sparrows Point, Maryland, United States',
    lat: 39.219275,
    lon: -76.4760724,
    value: '39.219275,-76.4760724',
  },
  {
    label: 'Sparrows Pooint, Maryland, United States',
    lat: 39.239217,
    lon: -76.441895,
    value: '39.239217,-76.441895',
  },
  {
    label: 'Spartanburg, South Carolina, United States',
    lat: 34.9498007,
    lon: -81.9320157,
    value: '34.9498007,-81.9320157',
  },
  {
    label: 'Spilamberto, Italy',
    lat: 44.533413,
    lon: 11.0240826,
    value: '44.533413,11.0240826',
  },
  {
    label: 'Spokane Valley, Washington, United States',
    lat: 47.6571104,
    lon: -117.2613936,
    value: '47.6571104,-117.2613936',
  },
  {
    label: 'Spokane, Washington, United States',
    lat: 47.6571934,
    lon: -117.42351,
    value: '47.6571934,-117.42351',
  },
  {
    label: 'Spring, Texas, United States',
    lat: 30.0798826,
    lon: -95.4172549,
    value: '30.0798826,-95.4172549',
  },
  {
    label: 'Springdale, Ohio, United States',
    lat: 39.287002,
    lon: -84.4852213,
    value: '39.287002,-84.4852213',
  },
  {
    label: 'Springfield, Missouri, United States',
    lat: 37.2081729,
    lon: -93.2922715,
    value: '37.2081729,-93.2922715',
  },
  {
    label: 'Springfield, Virginia, United States',
    lat: 38.7791227,
    lon: -77.1861196,
    value: '38.7791227,-77.1861196',
  },
  {
    label: 'Sriganganagar, Rajasthan, India',
    lat: 29.9319645,
    lon: 73.8723448,
    value: '29.9319645,73.8723448',
  },
  {
    label: 'Srikakulam, Andhra Pradesh, India',
    lat: 18.2949307,
    lon: 83.8938844,
    value: '18.2949307,83.8938844',
  },
  {
    label: 'St Petersburg, Florida, United States',
    lat: 27.7700475,
    lon: -82.6359078,
    value: '27.7700475,-82.6359078',
  },
  {
    label: 'St-Etienne-du-Rouvray, France',
    lat: 49.3831,
    lon: 1.09163,
    value: '49.3831,1.09163',
  },
  {
    label: 'St. Cloud, Minnesota, United States',
    lat: 45.5616075,
    lon: -94.1642004,
    value: '45.5616075,-94.1642004',
  },
  {
    label: 'St. Peters, Missouri, United States',
    lat: 38.791612,
    lon: -90.5958468,
    value: '38.791612,-90.5958468',
  },
  {
    label: 'St. Thomas, Canada',
    lat: 42.779022,
    lon: -81.1929882,
    value: '42.779022,-81.1929882',
  },
  // {
  //   "label": "Standortuebergreifend, Germany",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Staten Island, New York, United States',
    lat: 40.5834557,
    lon: -74.1496048,
    value: '40.5834557,-74.1496048',
  },
  {
    label: 'Sterling Heights, Michigan, United States',
    lat: 42.5803122,
    lon: -83.0302033,
    value: '42.5803122,-83.0302033',
  },
  {
    label: 'Sterling, Virginia, United States',
    lat: 39.0017455,
    lon: -77.4033349,
    value: '39.0017455,-77.4033349',
  },
  {
    label: 'Stillwater, Oklahoma, United States',
    lat: 36.1156306,
    lon: -97.0585717,
    value: '36.1156306,-97.0585717',
  },
  {
    label: 'Stockholm, Sweden',
    lat: 59.3251172,
    lon: 18.0710935,
    value: '59.3251172,18.0710935',
  },
  {
    label: 'Stockton-on-Tees, United Kingdom',
    lat: 54.564094,
    lon: -1.3129164,
    value: '54.564094,-1.3129164',
  },
  {
    label: 'Stockton, California, United States',
    lat: 37.9577016,
    lon: -121.290779,
    value: '37.9577016,-121.290779',
  },
  {
    label: 'Stoke, United Kingdom',
    lat: 53.0162014,
    lon: -2.1812607,
    value: '53.0162014,-2.1812607',
  },
  {
    label: 'Stone Mountain, Georgia, United States',
    lat: 33.8080924,
    lon: -84.1706273,
    value: '33.8080924,-84.1706273',
  },
  {
    label: 'Stoney Creek, Canada',
    lat: 53.9460845,
    lon: -124.1092574,
    value: '53.9460845,-124.1092574',
  },
  {
    label: 'Stoughton, Massachusetts, United States',
    lat: 42.1252079,
    lon: -71.1022015,
    value: '42.1252079,-71.1022015',
  },
  {
    label: 'Strasbourg, France',
    lat: 48.584614,
    lon: 7.7507127,
    value: '48.584614,7.7507127',
  },
  {
    label: 'Stratford, Connecticut, United States',
    lat: 41.1845415,
    lon: -73.1331651,
    value: '41.1845415,-73.1331651',
  },
  {
    label: 'Straubing, Germany',
    lat: 48.8819801,
    lon: 12.569716,
    value: '48.8819801,12.569716',
  },
  {
    label: 'Sturtevant, Wisconsin, United States',
    lat: 42.6980749,
    lon: -87.8945229,
    value: '42.6980749,-87.8945229',
  },
  {
    label: 'Stuttgart, Germany',
    lat: 48.7784485,
    lon: 9.1800132,
    value: '48.7784485,9.1800132',
  },
  {
    label: 'Suelzetal, Germany',
    lat: 52.015359,
    lon: 11.5432798,
    value: '52.015359,11.5432798',
  },
  {
    label: 'Suffolk, Virginia, United States',
    lat: 36.7282096,
    lon: -76.5835703,
    value: '36.7282096,-76.5835703',
  },
  {
    label: 'Sugar Land, Texas, United States',
    lat: 29.6196787,
    lon: -95.6349463,
    value: '29.6196787,-95.6349463',
  },
  {
    label: 'Sumner, Washington, United States',
    lat: 47.2061882,
    lon: -122.2312383,
    value: '47.2061882,-122.2312383',
  },
  {
    label: 'Sunnyvale, California, United States',
    lat: 37.3688301,
    lon: -122.036349,
    value: '37.3688301,-122.036349',
  },
  {
    label: 'Sunrise, Florida, United States',
    lat: 26.1666888,
    lon: -80.2787573,
    value: '26.1666888,-80.2787573',
  },
  {
    label: 'Surat, Gujarat, India',
    lat: 21.2094892,
    lon: 72.8317058,
    value: '21.2094892,72.8317058',
  },
  {
    label: 'Surprise, Arizona, United States',
    lat: 33.629247,
    lon: -112.3681428,
    value: '33.629247,-112.3681428',
  },
  {
    label: 'Surrey, Canada',
    lat: 49.1913033,
    lon: -122.849143,
    value: '49.1913033,-122.849143',
  },
  {
    label: 'Survilliers, France',
    lat: 49.1025201,
    lon: 2.5434395,
    value: '49.1025201,2.5434395',
  },
  {
    label: 'Sussex, Wisconsin, United States',
    lat: 43.1339001,
    lon: -88.2220372,
    value: '43.1339001,-88.2220372',
  },
  {
    label: 'Swansea, United Kingdom',
    lat: 51.6195955,
    lon: -3.9459248,
    value: '51.6195955,-3.9459248',
  },
  {
    label: 'Swedesboro, New Jersey, United States',
    lat: 39.7476136,
    lon: -75.3104654,
    value: '39.7476136,-75.3104654',
  },
  {
    label: 'Swiebodzin, Poland',
    lat: 52.2498357,
    lon: 15.5324973,
    value: '52.2498357,15.5324973',
  },
  {
    label: 'Sydney, Australia',
    lat: -33.8698439,
    lon: 151.2082848,
    value: '-33.8698439,151.2082848',
  },
  {
    label: 'Syosset, New York, United States',
    lat: 40.8262101,
    lon: -73.502068,
    value: '40.8262101,-73.502068',
  },
  {
    label: 'Syracuse, New York, United States',
    lat: 43.0481221,
    lon: -76.1474244,
    value: '43.0481221,-76.1474244',
  },
  {
    label: 'Szczecin, Poland',
    lat: 53.4301818,
    lon: 14.5509623,
    value: '53.4301818,14.5509623',
  },
  {
    label: 'Tachikawa, Japan',
    lat: 35.724463,
    lon: 139.404766,
    value: '35.724463,139.404766',
  },
  {
    label: 'Taguig, Philippines',
    lat: 14.5270538,
    lon: 121.0744942,
    value: '14.5270538,121.0744942',
  },
  {
    label: 'Taipei, Taiwan',
    lat: 25.0375198,
    lon: 121.5636796,
    value: '25.0375198,121.5636796',
  },
  {
    label: 'Tajimi, Japan',
    lat: 35.3329961,
    lon: 137.1319459,
    value: '35.3329961,137.1319459',
  },
  {
    label: 'Takatsuki, Japan',
    lat: 34.8812905,
    lon: 135.6012398,
    value: '34.8812905,135.6012398',
  },
  {
    label: 'Tallahassee, Florida, United States',
    lat: 30.4380832,
    lon: -84.2809332,
    value: '30.4380832,-84.2809332',
  },
  {
    label: 'Tamarac, Florida, United States',
    lat: 26.2128609,
    lon: -80.2497707,
    value: '26.2128609,-80.2497707',
  },
  {
    label: 'Tampa, Florida, United States',
    lat: 27.9477595,
    lon: -82.458444,
    value: '27.9477595,-82.458444',
  },
  {
    label: 'Tampere, Finland',
    lat: 61.4980214,
    lon: 23.7603118,
    value: '61.4980214,23.7603118',
  },
  {
    label: 'Tanner, Alabama, United States',
    lat: 34.7314787,
    lon: -86.9705632,
    value: '34.7314787,-86.9705632',
  },
  {
    label: 'Tanta, Egypt',
    lat: 30.7833666,
    lon: 30.9982536,
    value: '30.7833666,30.9982536',
  },
  {
    label: 'Taranto, Italy',
    lat: 40.4712427,
    lon: 17.2432278,
    value: '40.4712427,17.2432278',
  },
  {
    label: 'Tarragona, Spain',
    lat: 41.1172364,
    lon: 1.2546057,
    value: '41.1172364,1.2546057',
  },
  {
    label: 'Taunton, Massachusetts, United States',
    lat: 41.9019257,
    lon: -71.0931485,
    value: '41.9019257,-71.0931485',
  },
  {
    label: 'Tel Aviv, Israel',
    lat: 32.0852997,
    lon: 34.7818064,
    value: '32.0852997,34.7818064',
  },
  {
    label: 'Temecula, California, United States',
    lat: 33.4946353,
    lon: -117.147366,
    value: '33.4946353,-117.147366',
  },
  {
    label: 'Tempe, Arizona, United States',
    lat: 33.4255117,
    lon: -111.940016,
    value: '33.4255117,-111.940016',
  },
  {
    label: 'Temple Terrace, Florida, United States',
    lat: 28.0352964,
    lon: -82.3892596,
    value: '28.0352964,-82.3892596',
  },
  {
    label: 'Tenali, Andhra Pradesh, India',
    lat: 16.2377735,
    lon: 80.6464219,
    value: '16.2377735,80.6464219',
  },
  {
    label: 'Tenri, Japan',
    lat: 34.5965738,
    lon: 135.8373696,
    value: '34.5965738,135.8373696',
  },
  // {
  //   "label": "Tepotzetlan, Mexico",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Tepotzotlan, Mexico',
    lat: 19.7173392,
    lon: -99.2230683,
    value: '19.7173392,-99.2230683',
  },
  {
    label: 'Tepotzotlán, Mexico',
    lat: 19.7173392,
    lon: -99.2230683,
    value: '19.7173392,-99.2230683',
  },
  {
    label: 'Teterboro, New Jersey, United States',
    lat: 40.857483,
    lon: -74.06003621,
    value: '40.857483,-74.0600362129828',
  },
  {
    label: 'Teutschenthal, Germany',
    lat: 51.45,
    lon: 11.8,
    value: '51.45,11.8',
  },
  {
    label: 'Tezpur, Assam, India',
    lat: 26.6229928,
    lon: 92.7976082,
    value: '26.6229928,92.7976082',
  },
  {
    label: 'Thames Valley, United Kingdom',
    lat: 51.461042,
    lon: -0.938183,
    value: '51.461042,-0.938183',
  },
  {
    label: 'Thane, Maharashtra, India',
    lat: 19.1943294,
    lon: 72.9701779,
    value: '19.1943294,72.9701779',
  },
  {
    label: 'Theodore, Alabama, United States',
    lat: 30.5463684,
    lon: -88.20239369,
    value: '30.5463684,-88.2023936859469',
  },
  {
    label: 'Thiruvananthapuram, Kerala, India',
    lat: 8.4882267,
    lon: 76.947551,
    value: '8.4882267,76.947551',
  },
  {
    label: 'Thiruvarur, TN, India',
    lat: 10.7666538,
    lon: 79.6352476,
    value: '10.7666538,79.6352476',
  },
  {
    label: 'Thornton, Colorado, United States',
    lat: 39.8695516,
    lon: -104.985181,
    value: '39.8695516,-104.985181',
  },
  {
    label: 'Thousand Oaks, California, United States',
    lat: 34.1705609,
    lon: -118.8375937,
    value: '34.1705609,-118.8375937',
  },
  {
    label: 'Tianjin, China',
    lat: 39.1175488,
    lon: 117.1913008,
    value: '39.1175488,117.1913008',
  },
  {
    label: 'Tigard, Oregon, United States',
    lat: 45.4307473,
    lon: -122.771933,
    value: '45.4307473,-122.771933',
  },
  {
    label: 'Tijuana, Mexico',
    lat: 32.5317397,
    lon: -117.019529,
    value: '32.5317397,-117.019529',
  },
  {
    label: 'Timisoara, Romania',
    lat: 45.7538355,
    lon: 21.2257474,
    value: '45.7538355,21.2257474',
  },
  {
    label: 'Tinton Falls, New Jersey, United States',
    lat: 40.2890734,
    lon: -74.0927967,
    value: '40.2890734,-74.0927967',
  },
  {
    label: 'Tiruchirappalli, TN, India',
    lat: 10.804973,
    lon: 78.6870296,
    value: '10.804973,78.6870296',
  },
  {
    label: 'Tirunelveli, TN, India',
    lat: 8.7012203,
    lon: 77.5792694,
    value: '8.7012203,77.5792694',
  },
  {
    label: 'Tirupati, Andhra Pradesh, India',
    lat: 13.6316368,
    lon: 79.4231711,
    value: '13.6316368,79.4231711',
  },
  {
    label: 'Tirupur, TN, India',
    lat: 11.126384,
    lon: 77.332547,
    value: '11.126384,77.332547',
  },
  {
    label: 'Tiruvallur, TN, India',
    lat: 13.1394221,
    lon: 79.9070839,
    value: '13.1394221,79.9070839',
  },
  {
    label: 'Tokushima, Japan',
    lat: 34.0698307,
    lon: 134.5550353,
    value: '34.0698307,134.5550353',
  },
  {
    label: 'Tokyo, Japan',
    lat: 35.695126,
    lon: 139.75391,
    value: '35.695126,139.75391',
  },
  {
    label: 'Toledo, Ohio, United States',
    lat: 41.6529143,
    lon: -83.5378173,
    value: '41.6529143,-83.5378173',
  },
  {
    label: 'Toledo, Spain',
    lat: 39.8560679,
    lon: -4.0239568,
    value: '39.8560679,-4.0239568',
  },
  {
    label: 'Tolleson, Arizona, United States',
    lat: 33.4500497,
    lon: -112.259309,
    value: '33.4500497,-112.259309',
  },
  {
    label: 'Toluca, Mexico',
    lat: 19.292545,
    lon: -99.6569007,
    value: '19.292545,-99.6569007',
  },
  {
    label: 'Tonawanda, New York, United States',
    lat: 43.020679,
    lon: -78.878383,
    value: '43.020679,-78.878383',
  },
  {
    label: 'Torino, Italy',
    lat: 44.8887854,
    lon: 11.991409,
    value: '44.8887854,11.991409',
  },
  {
    label: 'Toronto, Canada',
    lat: 43.6534817,
    lon: -79.3839347,
    value: '43.6534817,-79.3839347',
  },
  {
    label: 'Torrance, California, United States',
    lat: 33.8371392,
    lon: -118.3413606,
    value: '33.8371392,-118.3413606',
  },
  {
    label: 'Torrazza, Italy',
    lat: 44.4727273,
    lon: 8.9593904,
    value: '44.4727273,8.9593904',
  },
  {
    label: 'Torreon, Mexico',
    lat: 25.542698,
    lon: -103.410512,
    value: '25.542698,-103.410512',
  },
  {
    label: 'Tosu, Japan',
    lat: 33.3778536,
    lon: 130.5061966,
    value: '33.3778536,130.5061966',
  },
  {
    label: 'Toulouse, France',
    lat: 43.6044622,
    lon: 1.4442469,
    value: '43.6044622,1.4442469',
  },
  {
    label: 'Toyama, Japan',
    lat: 36.6468015,
    lon: 137.2183531,
    value: '36.6468015,137.2183531',
  },
  {
    label: 'Tracy, California, United States',
    lat: 37.7385507,
    lon: -121.4201395,
    value: '37.7385507,-121.4201395',
  },
  {
    label: 'Trapani, Italy',
    lat: 38.0173961,
    lon: 12.5160225,
    value: '38.0173961,12.5160225',
  },
  {
    label: 'Trento, Italy',
    lat: 46.0664228,
    lon: 11.1257601,
    value: '46.0664228,11.1257601',
  },
  {
    label: 'Trichy, TN, India',
    lat: 10.80289,
    lon: 78.69875,
    value: '10.80289,78.69875',
  },
  {
    label: 'Trierweiler, Germany',
    lat: 49.762538,
    lon: 6.5589219,
    value: '49.762538,6.5589219',
  },
  // {
  //   "label": "Trisshur, Kerala, India",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'Trivandrum, Kerala, India',
    lat: 8.57697055,
    lon: 77.05012464,
    value: '8.57697054999999,77.0501246373072',
  },
  {
    label: 'Troisdorf, Germany',
    lat: 50.8153071,
    lon: 7.1593271,
    value: '50.8153071,7.1593271',
  },
  {
    label: 'Troutdale, Oregon, United States',
    lat: 45.5317928,
    lon: -122.391155,
    value: '45.5317928,-122.391155',
  },
  {
    label: 'Trumbull, Connecticut, United States',
    lat: 41.2428742,
    lon: -73.2006687,
    value: '41.2428742,-73.2006687',
  },
  {
    label: 'Tsawwassen First Nation, Canada',
    lat: 49.01667,
    lon: -123.08333,
    value: '49.01667,-123.08333',
  },
  {
    label: 'Tualatin, Oregon, United States',
    lat: 45.3838546,
    lon: -122.766351,
    value: '45.3838546,-122.766351',
  },
  {
    label: 'Tucson, Arizona, United States',
    lat: 32.2228765,
    lon: -110.974847,
    value: '32.2228765,-110.974847',
  },
  {
    label: 'Tuebingen, Germany',
    lat: 48.5236164,
    lon: 9.0535531,
    value: '48.5236164,9.0535531',
  },
  {
    label: 'Tukwila, Washington, United States',
    lat: 47.4627356,
    lon: -122.2559156,
    value: '47.4627356,-122.2559156',
  },
  {
    label: 'Tulsa, Oklahoma, United States',
    lat: 36.1563122,
    lon: -95.9927516,
    value: '36.1563122,-95.9927516',
  },
  {
    label: 'Tumkur, Karnataka, India',
    lat: 13.3400771,
    lon: 77.1006208,
    value: '13.3400771,77.1006208',
  },
  {
    label: 'Turin, Italy',
    lat: 45.0677551,
    lon: 7.6824892,
    value: '45.0677551,7.6824892',
  },
  {
    label: 'Turlock, California, United States',
    lat: 37.4946568,
    lon: -120.8465941,
    value: '37.4946568,-120.8465941',
  },
  {
    label: 'Twinsburg, Ohio, United States',
    lat: 41.3125552,
    lon: -81.4401129,
    value: '41.3125552,-81.4401129',
  },
  {
    label: 'Udaipur, Rajasthan, India',
    lat: 24.578721,
    lon: 73.6862571,
    value: '24.578721,73.6862571',
  },
  {
    label: 'Udine, Italy',
    lat: 46.0634632,
    lon: 13.2358377,
    value: '46.0634632,13.2358377',
  },
  {
    label: 'Ulm, Germany',
    lat: 48.3973957,
    lon: 9.9934104,
    value: '48.3973957,9.9934104',
  },
  {
    label: 'Umatilla, Oregon, United States',
    lat: 45.9173549,
    lon: -119.342517,
    value: '45.9173549,-119.342517',
  },
  {
    label: 'Union City, Georgia, United States',
    lat: 33.5870558,
    lon: -84.5424299,
    value: '33.5870558,-84.5424299',
  },
  {
    label: 'Union, Ohio, United States',
    lat: 39.9122954,
    lon: -84.30402154,
    value: '39.9122954,-84.3040215352959',
  },
  {
    label: 'University Park, Illinois, United States',
    lat: 41.4400344,
    lon: -87.683377,
    value: '41.4400344,-87.683377',
  },
  {
    label: 'Unna, Germany',
    lat: 51.5348835,
    lon: 7.689014,
    value: '51.5348835,7.689014',
  },
  {
    label: 'Upper Marlboro, Maryland, United States',
    lat: 38.8162451,
    lon: -76.751708,
    value: '38.8162451,-76.751708',
  },
  {
    label: 'Urayasu-shi, Japan',
    lat: 35.6530518,
    lon: 139.9018495,
    value: '35.6530518,139.9018495',
  },
  {
    label: 'Utebo, Spain',
    lat: 41.7140829,
    lon: -0.9943593,
    value: '41.7140829,-0.9943593',
  },
  {
    label: 'Utsunomiya, Japan',
    lat: 36.5549677,
    lon: 139.8828776,
    value: '36.5549677,139.8828776',
  },
  {
    label: 'Uttar Pradesh, Uttar Pradesh, India',
    lat: 28.9786739,
    lon: 78.8817501,
    value: '28.9786739,78.8817501',
  },
  {
    label: 'Uxbridge, Massachusetts, United States',
    lat: 42.0768175,
    lon: -71.6298483,
    value: '42.0768175,-71.6298483',
  },
  {
    label: 'Vacaville, California, United States',
    lat: 38.3565773,
    lon: -121.9877444,
    value: '38.3565773,-121.9877444',
  },
  {
    label: 'Vadodara, Gujarat, India',
    lat: 22.2973142,
    lon: 73.1942567,
    value: '22.2973142,73.1942567',
  },
  {
    label: 'Valencia, California, United States',
    lat: 34.4131941,
    lon: -118.559849,
    value: '34.4131941,-118.559849',
  },
  {
    label: 'Valencia, Spain',
    lat: 39.4697065,
    lon: -0.3763353,
    value: '39.4697065,-0.3763353',
  },
  {
    label: 'Valladolid, Spain',
    lat: 41.6521328,
    lon: -4.728562,
    value: '41.6521328,-4.728562',
  },
  {
    label: 'Valle Trapaga - Trapagaran, Spain',
    lat: 43.2796428,
    lon: -3.057352924,
    value: '43.2796428,-3.05735292444867',
  },
  {
    label: 'Valley View, Ohio, United States',
    lat: 41.3878312,
    lon: -81.6045685,
    value: '41.3878312,-81.6045685',
  },
  {
    label: 'Vancouver, Canada',
    lat: 49.2608724,
    lon: -123.113952,
    value: '49.2608724,-123.113952',
  },
  {
    label: 'Vandalia, Ohio, United States',
    lat: 39.8906122,
    lon: -84.1988308,
    value: '39.8906122,-84.1988308',
  },
  {
    label: 'Varanasi, Uttar Pradesh, India',
    lat: 25.3356491,
    lon: 83.0076292,
    value: '25.3356491,83.0076292',
  },
  {
    label: 'Varennes, Canada',
    lat: 45.6834691,
    lon: -73.4362737,
    value: '45.6834691,-73.4362737',
  },
  {
    label: 'Västerås, Sweden',
    lat: 59.6110992,
    lon: 16.5463679,
    value: '59.6110992,16.5463679',
  },
  {
    label: 'Vaughan, Canada',
    lat: 43.7941544,
    lon: -79.5268023,
    value: '43.7941544,-79.5268023',
  },
  {
    label: 'VCC - Netherlands, Netherlands',
    lat: 52.554205,
    lon: 5.501896,
    value: '52.554205,5.501896',
  },
  {
    label: 'VCC - West Bengal, West Bengal, India',
    lat: 23.269974,
    lon: 87.815506,
    value: '23.269974,87.815506',
  },
  {
    label: 'Vellore, TN, India',
    lat: 12.9071753,
    lon: 79.1309695,
    value: '12.9071753,79.1309695',
  },
  {
    label: 'Venezia, Italy',
    lat: 45.4964644,
    lon: 10.2356355,
    value: '45.4964644,10.2356355034768',
  },
  {
    label: 'Venice, California, United States',
    lat: 33.995044,
    lon: -118.4668875,
    value: '33.995044,-118.4668875',
  },
  {
    label: 'Venice, Florida, United States',
    lat: 27.0999188,
    lon: -82.4543597,
    value: '27.0999188,-82.4543597',
  },
  {
    label: 'Veracruz, Mexico',
    lat: 19.333333,
    lon: -96.666667,
    value: '19.333333,-96.666667',
  },
  {
    label: 'Vercelli, Italy',
    lat: 45.3251557,
    lon: 8.4227666,
    value: '45.3251557,8.4227666',
  },
  {
    label: 'Vernon, California, United States',
    lat: 34.007135,
    lon: -118.22525,
    value: '34.007135,-118.22525',
  },
  {
    label: 'Verona, Italy',
    lat: 45.4384958,
    lon: 10.9924122,
    value: '45.4384958,10.9924122',
  },
  {
    label: 'Vicenza, Italy',
    lat: 45.5488306,
    lon: 11.5478825,
    value: '45.5488306,11.5478825',
  },
  {
    label: 'Victoria, Canada',
    lat: 48.4283182,
    lon: -123.3649533,
    value: '48.4283182,-123.3649533',
  },
  {
    label: 'Victorville, California, United States',
    lat: 34.5361067,
    lon: -117.2911565,
    value: '34.5361067,-117.2911565',
  },
  {
    label: 'Vienna, Austria',
    lat: 48.2083537,
    lon: 16.3725042,
    value: '48.2083537,16.3725042',
  },
  {
    label: 'Vijayawada, Andhra Pradesh, India',
    lat: 16.5087573,
    lon: 80.6185089,
    value: '16.5087573,80.6185089',
  },
  {
    label: 'Vijaywada, Andhra Pradesh, India',
    lat: 16.505297,
    lon: 80.62412868,
    value: '16.505297,80.6241286812015',
  },
  {
    label: 'Villahermosa, Mexico',
    lat: 17.9884657,
    lon: -92.9366326,
    value: '17.9884657,-92.9366326',
  },
  {
    label: 'Villeneuve-les-Bouloc, France',
    lat: 43.7698,
    lon: 1.42747,
    value: '43.7698,1.42747',
  },
  {
    label: 'Virginia Beach, Virginia, United States',
    lat: 36.8529841,
    lon: -75.9774183,
    value: '36.8529841,-75.9774183',
  },
  {
    label: 'Virudhunagar, TN, India',
    lat: 9.58224,
    lon: 77.9537,
    value: '9.58224,77.9537',
  },
  {
    label: 'Visakhapatnam, Andhra Pradesh, India',
    lat: 17.7231276,
    lon: 83.3012842,
    value: '17.7231276,83.3012842',
  },
  {
    label: 'Visalia, California, United States',
    lat: 36.3302284,
    lon: -119.292058,
    value: '36.3302284,-119.292058',
  },
  {
    label: 'Vishakapatnam, Andhra Pradesh, India',
    lat: 17.74615,
    lon: 83.2223716,
    value: '17.74615,83.2223716',
  },
  {
    label: 'Vista, California, United States',
    lat: 33.2021284,
    lon: -117.2424787,
    value: '33.2021284,-117.2424787',
  },
  {
    label: 'Vitoria, Brazil',
    lat: -20.3200917,
    lon: -40.3376682,
    value: '-20.3200917,-40.3376682',
  },
  {
    label: 'Vitoria, Spain',
    lat: 42.8465088,
    lon: -2.6724025,
    value: '42.8465088,-2.6724025',
  },
  {
    label: 'Vizag, Andhra Pradesh, India',
    lat: 17.833868,
    lon: 83.35317581,
    value: '17.833868,83.3531758097983',
  },
  {
    label: 'Vizianagaram, Andhra Pradesh, India',
    lat: 18.1139259,
    lon: 83.3979562,
    value: '18.1139259,83.3979562',
  },
  {
    label: 'Voelklingen, Germany',
    lat: 49.2522866,
    lon: 6.859519,
    value: '49.2522866,6.859519',
  },
  {
    label: 'Von Ormy, Texas, United States',
    lat: 29.286868,
    lon: -98.6482144,
    value: '29.286868,-98.6482144',
  },
  {
    label: 'Waco, Texas, United States',
    lat: 31.5491899,
    lon: -97.1474628,
    value: '31.5491899,-97.1474628',
  },
  {
    label: 'Wakefield, United Kingdom',
    lat: 53.6829541,
    lon: -1.4967286,
    value: '53.6829541,-1.4967286',
  },
  {
    label: 'Waldorf, Maryland, United States',
    lat: 38.6263882,
    lon: -76.9106581,
    value: '38.6263882,-76.9106581',
  },
  {
    label: 'Walker, Michigan, United States',
    lat: 43.005597,
    lon: -85.7741906,
    value: '43.005597,-85.7741906',
  },
  {
    label: 'Wallingford, Connecticut, United States',
    lat: 41.4570418,
    lon: -72.8231552,
    value: '41.4570418,-72.8231552',
  },
  {
    label: 'Warangal, Andhra Pradesh, India',
    lat: 17.9820644,
    lon: 79.5970954,
    value: '17.9820644,79.5970954',
  },
  {
    label: 'Warangal, Telangana, India',
    lat: 17.9820644,
    lon: 79.5970954,
    value: '17.9820644,79.5970954',
  },
  {
    label: 'Warrenton, Virginia, United States',
    lat: 38.7135498,
    lon: -77.795367,
    value: '38.7135498,-77.795367',
  },
  {
    label: 'Warrington, Pennsylvania, United States',
    lat: 40.2492741,
    lon: -75.1340604,
    value: '40.2492741,-75.1340604',
  },
  {
    label: 'Warrington, United Kingdom',
    lat: 53.3899497,
    lon: -2.5943178,
    value: '53.3899497,-2.5943178',
  },
  {
    label: 'Warsaw, Poland',
    lat: 52.2319581,
    lon: 21.0067249,
    value: '52.2319581,21.0067249',
  },
  {
    label: 'Washington DC, District of Columbia, United States',
    lat: 38.8950368,
    lon: -77.0365427,
    value: '38.8950368,-77.0365427',
  },
  {
    label: 'Washington, District of Columbia, United States',
    lat: 38.8950368,
    lon: -77.0365427,
    value: '38.8950368,-77.0365427',
  },
  {
    label: 'Washington, Indiana, United States',
    lat: 38.6592152,
    lon: -87.172789,
    value: '38.6592152,-87.172789',
  },
  {
    label: 'Waterfall City, South Africa',
    lat: -33.9587751,
    lon: 18.4183862,
    value: '-33.9587751,18.4183862',
  },
  {
    label: 'Waukegan, Illinois, United States',
    lat: 42.3636331,
    lon: -87.8447938,
    value: '42.3636331,-87.8447938',
  },
  {
    label: 'Waynesboro, Virginia, United States',
    lat: 38.0686668,
    lon: -78.8893999,
    value: '38.0686668,-78.8893999',
  },
  {
    label: 'Weiterstadt, Germany',
    lat: 49.9178,
    lon: 8.5924,
    value: '49.9178,8.5924',
  },
  {
    label: 'Wellington, New Zealand',
    lat: -41.2887953,
    lon: 174.7772114,
    value: '-41.2887953,174.7772114',
  },
  {
    label: 'Wenden, Germany',
    lat: 50.9691051,
    lon: 7.8733251,
    value: '50.9691051,7.8733251',
  },
  {
    label: 'Werder, Germany',
    lat: 53.7164587,
    lon: 13.3581433,
    value: '53.7164587,13.3581433',
  },
  {
    label: 'Werne, Germany',
    lat: 51.66268,
    lon: 7.6355052,
    value: '51.66268,7.6355052',
  },
  {
    label: 'West Caldwell, New Jersey, United States',
    lat: 40.8409329,
    lon: -74.3018154,
    value: '40.8409329,-74.3018154',
  },
  {
    label: 'West Chester, Ohio, United States',
    lat: 40.2336795,
    lon: -81.3442776,
    value: '40.2336795,-81.3442776',
  },
  {
    label: 'West Chicago, Illinois, United States',
    lat: 41.8847507,
    lon: -88.2039607,
    value: '41.8847507,-88.2039607',
  },
  {
    label: 'West Columbia, South Carolina, United States',
    lat: 33.9934867,
    lon: -81.0739826,
    value: '33.9934867,-81.0739826',
  },
  {
    label: 'West Deptford, New Jersey, United States',
    lat: 39.832362,
    lon: -75.1888708,
    value: '39.832362,-75.1888708',
  },
  {
    label: 'West Fargo, North Dakota, United States',
    lat: 46.874967,
    lon: -96.900362,
    value: '46.874967,-96.900362',
  },
  {
    label: 'West Hollywood, California, United States',
    lat: 34.0923014,
    lon: -118.3692894,
    value: '34.0923014,-118.3692894',
  },
  {
    label: 'West Jefferson, Ohio, United States',
    lat: 39.944784,
    lon: -83.2688045,
    value: '39.944784,-83.2688045',
  },
  {
    label: 'West Jordan, Utah, United States',
    lat: 40.6061337,
    lon: -111.9395211,
    value: '40.6061337,-111.9395211',
  },
  {
    label: 'West Palm Beach, Florida, United States',
    lat: 26.715364,
    lon: -80.0532942,
    value: '26.715364,-80.0532942',
  },
  {
    label: 'West Sacramento, California, United States',
    lat: 38.5804609,
    lon: -121.530234,
    value: '38.5804609,-121.530234',
  },
  {
    label: 'West Valley City, Utah, United States',
    lat: 40.6967254,
    lon: -111.986821,
    value: '40.6967254,-111.986821',
  },
  {
    label: 'West Valley, Utah, United States',
    lat: 40.6967254,
    lon: -111.986821,
    value: '40.6967254,-111.986821',
  },
  {
    label: 'Westborough, Massachusetts, United States',
    lat: 42.2694308,
    lon: -71.6129907,
    value: '42.2694308,-71.6129907',
  },
  {
    label: 'Westhampton Beach, New York, United States',
    lat: 40.8102203,
    lon: -72.6430174,
    value: '40.8102203,-72.6430174',
  },
  {
    label: 'Westmont, Illinois, United States',
    lat: 41.7950525,
    lon: -87.9751964,
    value: '41.7950525,-87.9751964',
  },
  {
    label: 'Weybridge, United Kingdom',
    lat: 51.3727175,
    lon: -0.4549297,
    value: '51.3727175,-0.4549297',
  },
  {
    label: 'Whitby, Canada',
    lat: 43.87982,
    lon: -78.9421751,
    value: '43.87982,-78.9421751',
  },
  {
    label: 'White Haven, Pennsylvania, United States',
    lat: 41.06064,
    lon: -75.774082,
    value: '41.06064,-75.774082',
  },
  {
    label: 'White Marsh, Maryland, United States',
    lat: 39.3819338,
    lon: -76.457304,
    value: '39.3819338,-76.457304',
  },
  {
    label: 'Whiteland, Indiana, United States',
    lat: 39.5500485,
    lon: -86.0797079,
    value: '39.5500485,-86.0797079',
  },
  {
    label: 'Whitestown, Indiana, United States',
    lat: 39.9972626,
    lon: -86.3458296,
    value: '39.9972626,-86.3458296',
  },
  {
    label: 'Whitsett, North Carolina, United States',
    lat: 36.0712466,
    lon: -79.5644686,
    value: '36.0712466,-79.5644686',
  },
  {
    label: 'Whittier, California, United States',
    lat: 33.9749136,
    lon: -118.0338664,
    value: '33.9749136,-118.0338664',
  },
  {
    label: 'Wichita, Kansas, United States',
    lat: 37.6922361,
    lon: -97.3375448,
    value: '37.6922361,-97.3375448',
  },
  {
    label: 'Wilmer, Texas, United States',
    lat: 32.5908185,
    lon: -96.6739427,
    value: '32.5908185,-96.6739426989587',
  },
  {
    label: 'Wilmington, Delaware, United States',
    lat: 39.7459468,
    lon: -75.546589,
    value: '39.7459468,-75.546589',
  },
  {
    label: 'Wilmington, Illinois, United States',
    lat: 39.4794415,
    lon: -90.48745575,
    value: '39.4794415,-90.4874557487872',
  },
  {
    label: 'Wilmington, Massachusetts, United States',
    lat: 42.5464828,
    lon: -71.1736669,
    value: '42.5464828,-71.1736669',
  },
  {
    label: 'Wilmington, Ohio, United States',
    lat: 39.4453393,
    lon: -83.8285375,
    value: '39.4453393,-83.8285375',
  },
  {
    label: 'Windsor, Canada',
    lat: 42.3167397,
    lon: -83.0373389,
    value: '42.3167397,-83.0373389',
  },
  {
    label: 'Windsor, Connecticut, United States',
    lat: 41.8525984,
    lon: -72.6437022,
    value: '41.8525984,-72.6437022',
  },
  {
    label: 'Winnipeg, Canada',
    lat: 49.8955367,
    lon: -97.1384584,
    value: '49.8955367,-97.1384584',
  },
  {
    label: 'Winsen (Luhe), Germany',
    lat: 53.3636474,
    lon: 10.2059206,
    value: '53.3636474,10.2059206',
  },
  {
    label: 'Witten, Germany',
    lat: 51.4370171,
    lon: 7.335124,
    value: '51.4370171,7.335124',
  },
  {
    label: 'Wixom, Michigan, United States',
    lat: 42.5246873,
    lon: -83.5363637,
    value: '42.5246873,-83.5363637',
  },
  {
    label: 'Woippy, France',
    lat: 49.1515848,
    lon: 6.1519224,
    value: '49.1515848,6.1519224',
  },
  {
    label: 'Wolfhagen, Germany',
    lat: 51.3333355,
    lon: 9.1968854,
    value: '51.3333355,9.1968854',
  },
  {
    label: 'Wood Dale, Illinois, United States',
    lat: 41.9633625,
    lon: -87.9789562,
    value: '41.9633625,-87.9789562',
  },
  {
    label: 'Woodbury, Minnesota, United States',
    lat: 44.919896,
    lon: -92.9339449,
    value: '44.919896,-92.9339449',
  },
  {
    label: 'Woodland Hills, California, United States',
    lat: 34.1684364,
    lon: -118.6058382,
    value: '34.1684364,-118.6058382',
  },
  {
    label: 'Woodland Park, New Jersey, United States',
    lat: 40.8893288,
    lon: -74.1969657,
    value: '40.8893288,-74.1969657',
  },
  {
    label: 'Woodside, New York, United States',
    lat: 42.5217281,
    lon: -78.7650292,
    value: '42.5217281,-78.7650292',
  },
  {
    label: 'Worcester, Massachusetts, United States',
    lat: 42.2625621,
    lon: -71.8018877,
    value: '42.2625621,-71.8018877',
  },
  {
    label: 'Wunstorf, Germany',
    lat: 52.4257979,
    lon: 9.4294425,
    value: '52.4257979,9.4294425',
  },
  {
    label: 'Wuppertal, Germany',
    lat: 51.264018,
    lon: 7.1780374,
    value: '51.264018,7.1780374',
  },
  {
    label: 'Xiamen, China',
    lat: 36.2598076,
    lon: 102.5705794,
    value: '36.2598076,102.5705794',
  },
  {
    label: 'Yachiyo, Japan',
    lat: 35.722537,
    lon: 140.0995131,
    value: '35.722537,140.0995131',
  },
  {
    label: 'Yamato, Japan',
    lat: 28.329164,
    lon: 129.3423209,
    value: '28.329164,129.3423209',
  },
  {
    label: 'Yellville, Arkansas, United States',
    lat: 36.2261815,
    lon: -92.6848876,
    value: '36.2261815,-92.6848876',
  },
  {
    label: "Yeshwantpur, B'lore, Karnataka, India",
    lat: 13.01788785,
    lon: 77.5564686,
    value: '13.01788785,77.5564685973988',
  },
  {
    label: 'Yokohama, Japan',
    lat: 35.4443947,
    lon: 139.6367727,
    value: '35.4443947,139.6367727',
  },
  // {
  //   "label": "Yongin City, Korea, Republic of",
  //   "lat": "",
  //   "lon": "",
  //   "value": ","
  // },
  {
    label: 'York, Pennsylvania, United States',
    lat: 39.9624588,
    lon: -76.7277218,
    value: '39.9624588,-76.7277218',
  },
  {
    label: 'Yotsukaido, Japan',
    lat: 35.6696551,
    lon: 140.1679445,
    value: '35.6696551,140.1679445',
  },
  {
    label: 'Zagreb, Croatia',
    lat: 45.8130967,
    lon: 15.9772795,
    value: '45.8130967,15.9772795',
  },
  {
    label: 'Zakazik, Egypt',
    lat: 30.5853431,
    lon: 31.5035127,
    value: '30.5853431,31.5035127',
  },
  {
    label: 'Zapopan, Mexico',
    lat: 20.7211203,
    lon: -103.3913671,
    value: '20.7211203,-103.3913671',
  },
  {
    label: 'Zaragoza, Spain',
    lat: 41.6521342,
    lon: -0.8809428,
    value: '41.6521342,-0.8809428',
  },
  {
    label: 'Zgorzelec, Poland',
    lat: 51.15178695,
    lon: 15.01443297,
    value: '51.15178695,15.0144329719332',
  },
  {
    label: 'Zhongwei, China',
    lat: 37.4999947,
    lon: 105.1928783,
    value: '37.4999947,105.1928783',
  },
  {
    label: 'Zurich, Switzerland',
    lat: 47.3744489,
    lon: 8.5410422,
    value: '47.3744489,8.5410422',
  },
];

// Components
import React from 'react';
import Button from '@amzn/awsui-components-react/polaris/button';
import Link from '@amzn/awsui-components-react/polaris/link';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { ComplexSignupDialog } from '../complexSignup/signupDialog';
import { TimeModal } from './timeModal';
// Hooks
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useUser } from '../../hooks/user';
import { featureIsEnabled, useFeatures } from '../../hooks/features';
import {
  useCreateSignup,
  useDeleteSignup,
  useLatestSignupMutationState,
} from '../../hooks/shifts/shiftSignup';
// Types
import { Shift, Signup, Event, Attendance, AttendanceStatus } from '@amzn/red-velvet-api';
// Utils
import { externalLinks } from '../../data/externalLinks';
import { COMPLEX_SIGNUP_FEATURE_FLAG } from '../../utils/constants';
import { useAttendance, useTrackTime } from '../../hooks/attendance/attendanceMutation';

interface ActionLinkProps {
  event: Event;
  shift: Shift;
  signup?: Signup;
  attendance?: Attendance;
}

export function ShiftActions(props: ActionLinkProps) {
  const { event, shift, signup, attendance } = props;

  const features = useFeatures();
  const [searchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState(false);
  const shiftStatus = useLatestSignupMutationState(event.eventId, shift.shiftId, signup);
  const isComplexShiftSignup = requiresComplexSignup(event);

  if (shiftStatus.signup !== undefined && shift.complete) {
    return (
      <TrackPastData
        event={event}
        shift={shift}
        signup={shiftStatus.signup}
        attendance={attendance}
        startComplexSignup={() => setModalVisible(true)}
      />
    );
  }

  if (
    !featureIsEnabled(COMPLEX_SIGNUP_FEATURE_FLAG, features, searchParams) &&
    isComplexShiftSignup
  ) {
    return <RegisteredBenevityLink shift={shift} />;
  }
  return (
    <>
      <ToggleSignup
        event={event}
        shift={shift}
        signup={shiftStatus.signup}
        startComplexSignup={() => setModalVisible(true)}
      />
      <ComplexSignupDialog
        event={event}
        shift={shift}
        visible={modalVisible}
        onDismiss={() => setModalVisible(false)}
      />
    </>
  );
}

function requiresComplexSignup(event: Event) {
  return (
    event.allowGuests ||
    (event.questions && event.questions.length > 0) ||
    (event.waiver && event.waiver.acceptance !== 'none' && event.waiver.url) ||
    event.hasTShirt
  );
}

export function RegisteredBenevityLink({ shift }: { shift: Shift }) {
  const { t } = useTranslation();

  return (
    <a
      className="registerLink"
      href={
        externalLinks.benevity.signup +
        `${shift.eventId}?utm_source=${externalLinks.utm.source}&utm_medium=${'EventPage'}&utm_campaign=${externalLinks.utm.campaign}`
      }
      data-aci-analytics-name={`shifts_signupInBenevityDetails`}
      target="_blank"
      rel="noreferrer"
    >
      {t('eventDetails.shiftDisplay.signUp')}
    </a>
  );
}

type ActionInput = {
  event: Event;
  shift: Shift;
  signup: Signup | undefined;
  attendance?: Attendance;
  startComplexSignup: () => void;
  myActivityEnabled?: boolean;
};

export function TrackPastData({
  event,
  shift,
  signup,
  attendance,
  startComplexSignup,
}: ActionInput) {
  const { t } = useTranslation();
  const features = useFeatures();
  const [searchParams] = useSearchParams();

  if (featureIsEnabled('MyActivity', features, searchParams)) {
    return (
      <div>
        <SpaceBetween direction="horizontal" size="s">
          <TrackAttendance
            event={event}
            shift={shift}
            signup={signup}
            attendance={attendance}
            startComplexSignup={startComplexSignup}
          />
        </SpaceBetween>
      </div>
    );
  } else {
    return (
      <div>
        <SpaceBetween direction="horizontal" size="s">
          <ToggleSignup
            event={event}
            shift={shift}
            signup={signup}
            startComplexSignup={startComplexSignup}
          />
          <div className="link-spacing">
            <Link
              data-aci-analytics-name={`shifts_reportTime`}
              href={externalLinks.benevity.trackTime}
            >
              {t('eventDetails.shiftDisplay.trackTime')}
            </Link>
          </div>
        </SpaceBetween>
      </div>
    );
  }
}

export function TrackAttendance({ event, shift, signup }: ActionInput) {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const timeMutation = useTrackTime(shift.eventId, shift.shiftId);
  const user = useUser();
  const attendance = useAttendance(event.eventId, shift.shiftId, user.userId);

  return (
    <>
      <SpaceBetween direction="horizontal" size="s">
        {attendance.data?.attendanceStatus !== 'DID_NOT_ATTEND' ? (
          <Button
            fullWidth
            loading={timeMutation.isPending}
            data-testid="attendace.didNotAttendButton"
            data-aci-analytics-name="shifts_didNotAttend"
            onClick={() =>
              timeMutation.mutate({
                eventId: shift.eventId,
                shiftId: shift.shiftId,
                alias: user.userId,
                userId: user.employeeId,
                minutes: 0,
                attendanceStatus: AttendanceStatus.DID_NOT_ATTEND,
              })
            }
          >
            {t('eventDetails.shiftDisplay.didNotAttend')}
          </Button>
        ) : null}
        <Button
          fullWidth
          variant={attendance.data ? undefined : 'primary'}
          data-aci-analytics-name="shifts_attendanceDialog"
          onClick={() => setModalVisible(true)}
        >
          {attendance.data && attendance.data.attendanceStatus === 'ATTENDED'
            ? t('eventDetails.shiftDisplay.editTime')
            : t('eventDetails.shiftDisplay.trackTime')}
        </Button>
      </SpaceBetween>
      <TimeModal shift={shift} visible={modalVisible} onDismiss={() => setModalVisible(false)} />
    </>
  );
}

// Although this would be more readable as two separate components (one for delete and one for signup)
// this causes problems with the accessibility for Complex signups.  When they are one component, the
// Button that was focused when a complex signup dialog is opened is still there when the dialod is closed
// so the keyboard focus automatically returns to it.  If they are separate components, the focus is lost
// when the dialog is closed, which means keyboard users have to re-navigate through the whole page
export function ToggleSignup({ event, shift, signup, startComplexSignup }: ActionInput) {
  const { t } = useTranslation();
  const signupMutation = useCreateSignup(event.eventId, shift.shiftId);
  const deleteMutation = useDeleteSignup(event.eventId, shift.shiftId);
  const user = useUser();

  const isDelete = signup !== undefined;
  const isComplexShiftSignup = requiresComplexSignup(event);
  // TODO: Enforce metrics in integ test: https://issues.amazon.com/issues/cce-3970
  const signup_metric = isComplexShiftSignup ? 'complex_signup_dialog' : 'shifts_signup';

  return (
    <Button
      fullWidth
      loading={signupMutation.isPending || deleteMutation.isPending}
      variant={!isDelete ? 'primary' : undefined}
      // TODO: Fix this properly https://issues.amazon.com/issues/cce-4091
      data-testid={
        isComplexShiftSignup ? 'complexSignup.registerButton' : 'simpleSignup.registerButton'
      }
      data-aci-analytics-name={!isDelete ? signup_metric : `shifts_cancelDetails`}
      onClick={() => {
        if (isDelete) {
          deleteMutation.mutate({
            eventId: shift.eventId,
            shift,
            request: { signupId: signup.signupId },
          });
        } else {
          if (isComplexShiftSignup) {
            startComplexSignup();
          } else {
            signupMutation.mutate({
              eventId: shift.eventId,
              shift: shift,
              request: { shift: shift.shiftId, alias: user.userId },
            });
          }
        }
      }}
    >
      {!isDelete
        ? t('eventDetails.shiftDisplay.register')
        : !shift.complete
          ? t('eventDetails.shiftDisplay.cancel')
          : t('eventDetails.shiftDisplay.didNotAttend')}
    </Button>
  );
}

// GOLDEN PATH CODE
// This is a window observer that determines the size of horizontal
// carousel pages.  The carousel hooks indicate how many items will
// fit in a single row on the page based on their size, and automatically
// updates when the user resizes the browser window
import { useState, useEffect } from 'react';

function determineDisplayItemsCount(itemWidth: number) {
  // GOLDEN PATH NOTE
  // Test environments often have an undefined window, due to being run in headless mode
  // In those cases, we want to return 1, so that the carousel pagination can be tested properly
  if (typeof window === 'undefined') return 1;
  const windowWidth: number = window.innerWidth;

  return Math.floor(windowWidth / itemWidth) || 1;
}

export function useCarouselDisplayCount(itemWidth: number) {
  const [displayItemsCount, setDisplayItemsCount] = useState<number>(
    determineDisplayItemsCount(itemWidth)
  );

  useEffect(() => {
    // GOLDEN PATH NOTE
    // This function handles adjusting the number of items displayed in the carousel
    // We add the function to the window resize listener when this hook is called
    // and then remove it when the hook vanishes, so that the resize does not continue
    // to run when the carousel is not displayed
    function handleResize() {
      const count: number = determineDisplayItemsCount(itemWidth);
      if (count !== displayItemsCount) {
        setDisplayItemsCount(count);
      }
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [displayItemsCount, itemWidth]);

  return displayItemsCount;
}
